import React, { useRef, useEffect, useCallback } from "react";
import styled from "styled-components";

import { mobile as mobileSize } from "styles/media";
import colors from "styles/colors";
import text from "styles/text";
import media from "styles/media";

import { useAnimationFrame, useMedia } from "utils/Hooks";

type scrollObject = {
  speed: number;
  currentElements: [
    {
      class: string;
      el: {
        id: string;
        children: HTMLCollection;
      };
      progress: number;
    }
  ];
};

type props = {
  children: any;
  left: boolean;
  outlined?: boolean;
};

const Banner: React.FC<props> = ({ children, left, outlined }) => {
  const sideways = useRef(null);
  const isVisible = useRef(false);
  const time = useRef(0);

  const speedDivide = useMedia(1, 1, 2, 2);

  useEffect(() => {
    window.locomotiveScroll.on("scroll", (obj: scrollObject) => {
      if (!obj.currentElements) return;

      let objArray = Object.values(obj.currentElements);
      let sidewaysText = objArray.filter(
        (el) => el.class && el.class === "section-visible"
      )[0];

      if (sidewaysText) isVisible.current = true;
      else isVisible.current = false;
    });
  }, []);

  const animationCallback = useCallback(() => {
    time.current += 0.5 + Math.abs(window.speed);
    if (sideways.current) {
      const { height, width } = sideways.current.getBoundingClientRect();
      if (window.innerWidth <= mobileSize) {
        time.current %= width / 8;
      } else {
        time.current %= height / speedDivide;
      }
      sideways.current.style.transform = `translateX(${time.current}px)`;
    }
  }, [sideways, speedDivide]);

  useAnimationFrame(animationCallback);

  return (
    <Wrapper>
      <SidewaysText $left={left}>
        <DigText ref={sideways} outlined={outlined}>
          {children}
          {children}
          {children}
          {children}
        </DigText>
      </SidewaysText>
    </Wrapper>
  );
};

export default Banner;

const Wrapper = styled.div`
  position: relative;

  height: 36.944vw;

  ${media.tablet} {
    height: 50.391vw;
  }

  ${media.mobile} {
    height: 106.667vw;
  }
`;

const SidewaysText = styled.div<{ $left: boolean }>`
  position: relative;
  left: -100%;
  top: ${(props) => (props.$left ? "100%" : "-50%")};
  transform: rotate(${(props) => (props.$left ? "5" : "-5")}deg);

  height: 8.264vw;
  width: 1000vw;

  ${media.tablet} {
    height: 11.621vw;
    top: ${(props) => (props.$left ? "100%" : "-30%")};
  }

  ${media.mobile} {
    height: 16vw;
    width: 1064.067vw;
    left: -200%;
    top: ${(props) => (props.$left ? "75%" : "0%")};
  }
`;

const DigText = styled.h1<{ outlined?: boolean }>`
  ${text.desktop.jumboTicker}
  color: ${(props) => (props.outlined ? "transparent" : colors.offWhite)};
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: ${colors.offWhite};
  padding: 2vw 0;
  border-bottom: 1px solid ${colors.offWhite};
  ${(props) =>
    props.outlined &&
    `border-top: 1px solid ${colors.offWhite};
  background-color: ${colors.richBlack100};`}

  ${media.tablet} {
    ${text.tablet.jumboTicker}
  }

  ${media.mobile} {
    ${text.mobile.jumboTicker}
    padding-top: 3vw;
    font-size: 12vw;
  }
`;
