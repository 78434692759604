import React, { useRef } from "react";
import styled from "styled-components";
import gsap from "gsap";

import colors from "styles/colors";
import text from "styles/text";
import media from "styles/media";

import { useMedia } from "utils/Hooks";

type props = {
  children: any;
  width?: string;
  id?: string;
  onClick?: any;
  disabled?: boolean;
};

const SecondaryButton: React.FC<props> = ({
  children,
  id,
  onClick,
  width,
  disabled,
}) => {
  const whiteRef = useRef(null);
  const pinkRef = useRef(null);
  const blueRef = useRef(null);

  const pinkTransform = useMedia("0.278vw", "0.278vw", "0.391vw", "1.067vw");
  const blueTransform = useMedia(
    "-0.278vw",
    "-0.278vw",
    "-0.391vw",
    "-1.067vw"
  );

  const handleMouseEnter = () => {
    if (!disabled) {
      const tl = gsap.timeline({
        onComplete: () => {
          tl.kill();
        },
      });

      tl.to(whiteRef.current, {
        duration: 0.2,
        color: colors.offWhite,
        backgroundColor: colors.richBlack100,
      });

      tl.to(
        pinkRef.current,
        {
          duration: 0.2,
          x: pinkTransform,
          y: pinkTransform,
        },
        0
      );

      tl.to(
        blueRef.current,
        {
          duration: 0.2,
          x: blueTransform,
          y: blueTransform,
        },
        0
      );

      return () => {
        tl.kill();
      };
    }
  };

  const handleMouseLeave = () => {
    if (!disabled) {
      const tl = gsap.timeline({
        onComplete: () => {
          tl.kill();
        },
      });

      tl.to(
        whiteRef.current,
        {
          duration: 0.2,
          color: colors.richBlack100,
          backgroundColor: colors.offWhite,
        },
        0
      );

      tl.to(
        pinkRef.current,
        {
          duration: 0.2,
          x: "0vw",
          y: "0vw",
        },
        0
      );

      tl.to(
        blueRef.current,
        {
          duration: 0.2,
          x: "0vw",
          y: "0vw",
        },
        0
      );

      return () => {
        tl.kill();
      };
    }
  };

  const handleClick = () => {
    if (!disabled) {
      onClick();
    }
  };

  return (
    <Button
      id={id}
      onClick={handleClick}
      width={width}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <WhiteSpan disabled={disabled} width={width} ref={whiteRef}>
        {children}
      </WhiteSpan>
      <PinkSpan width={width} ref={pinkRef} />
      <BlueSpan width={width} ref={blueRef} />
    </Button>
  );
};

export default SecondaryButton;

const Button = styled.span<{ width?: string }>`
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 0px;

  height: 4.514vw;
  width: ${(props) => props.width || "14.653vw"};

  ${media.tablet} {
    height: 6.348vw;
  }

  ${media.mobile} {
    height: 17.333vw;
  }
`;

const Span = styled.span`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;

  height: 4.514vw;

  ${media.tablet} {
    height: 6.348vw;
  }

  ${media.mobile} {
    height: 17.333vw;
  }
`;

const PinkSpan = styled(Span)<{ width?: string }>`
  background-color: ${colors.razzmatazz100};
  width: ${(props) => props.width || "14.653vw"};
`;

const BlueSpan = styled(Span)<{ width?: string }>`
  background-color: ${colors.splash100};
  width: ${(props) => props.width || "14.653vw"};
`;

const WhiteSpan = styled.span<{ width?: string; disabled: boolean }>`
  width: ${(props) => props.width || "14.653vw"};
  z-index: 3;
  text-transform: uppercase;
  color: ${colors.richBlack100};
  position: relative;
  background-color: ${(props) =>
    props.disabled ? colors.richBlack80 : colors.offWhite};
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${text.desktop.buttonText}

  ${media.tablet} {
    ${text.tablet.buttonText}
  }

  ${media.mobile} {
    ${text.mobile.buttonText}
  }
`;
