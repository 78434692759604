import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import gsap from "gsap";
import { ScreenContext } from "App";

import colors from "styles/colors";
import text from "styles/text";

import media, { mobile } from "styles/media";

import { ReactComponent as DotSVG } from "assets/svg/redDot.svg";
import { ReactComponent as ArrowSVG } from "assets/svg/arrow.svg";

type image = {
  url: string;
  alt: string;
  name: string;
};

type nft = {
  title: string;
  link: string;
  state: {
    text: string;
    color: string;
  };
};

type props = {
  data: {
    title: string,
    mobileTitle: string,
    firstImage: image,
    secondImage: image,
    thirdImage?: image,
    nfts: nft[]
  }
  dropId: string,
}

const Drops: React.FC<props> = ({ data, dropId }) => {
  const drops = data.nfts.map((item: any, index: number) => {
    return <Drop key={index} item={item} index={index} dropId={dropId}/>
  });

  return (
    <Wrapper data-scroll-section>
      <Title>
        {window.innerWidth <= mobile ? data.mobileTitle : data.title}
      </Title>
      <SubTitle>all nft drops ↓</SubTitle>
      <DropsContainer>
        {drops}
      </DropsContainer>

      <LargeImage>
        <img src={data.firstImage.url} alt={data.firstImage.alt} />
        <p>{data.firstImage.name}</p>
      </LargeImage>

      <SecondaryImage shiftUp={!!data.thirdImage}>
        <img src={data.secondImage.url} alt={data.secondImage.alt} />
        <p>{data.secondImage.name}</p>
      </SecondaryImage>

      {data.thirdImage && (
        <TertiaryImage>
          <img src={data.thirdImage.url} alt={data.thirdImage.alt} />
          <p>{data.thirdImage.name}</p>
        </TertiaryImage>
      )}
    </Wrapper>
  );
};

export default Drops;

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;

  padding-top: 9.514vw;
  padding-left: 11.319vw;
  height: 56.944vw;

  ${media.tablet} {
    padding-top: 9.766vw;
    padding-left: 4.883vw;
    height: 67.285vw;
  }

  ${media.mobile} {
    padding-top: 149.867vw;
    padding-left: 6.667vw;
    height: auto;
    margin-top: 53.333vw;
  }
`;

const BlueBackground = styled.div`
  background-color: ${colors.splash100};
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 100%;
`;

const PinkBackground = styled.div`
  background-color: ${colors.razzmatazz100};
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  top: 100%;
`;

const WhiteBackground = styled.div`
  background-color: ${colors.offWhite};
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 100%;
`;

const Title = styled.h2`
  color: ${colors.offWhite};
  font-family: Sofia Pro;
  font-style: normal;
  font-weight: 700;
  line-height: 90%;
  letter-spacing: -0.06em;
  white-space: pre-wrap;
  position: relative;
  z-index: 2;
  margin-bottom: 3.472vw;
  font-size: 6.25vw;

  ${media.tablet} {
    margin-bottom: 4.883vw;
    ${text.tablet.headerH2}
  }

  ${media.mobile} {
    margin-bottom: 10.7vw;
    ${text.mobile.headerH2};
    font-size: 14.4vw;
  }
`;

const SubTitle = styled.span`
  color: ${colors.razzmatazz100};

  ${text.desktop.buttonText};
  margin-bottom: 0.625vw;

  ${media.tablet} {
    ${text.tablet.buttonText}
    margin-bottom: 0.879vw;
  }

  ${media.mobile} {
    ${text.mobile.buttonText}
    margin-bottom: 2.667vw;
  }
`;

const DropsContainer = styled.div`
  border-top: 2px solid ${colors.offWhite};
  display: flex;
  flex-direction: column;

  width: 45.972vw;

  ${media.tablet} {
    width: 51.465vw;
  }

  ${media.mobile} {
    width: 86.667vw;
  }
`;


const Drop: React.FC<{item: any, index: number, dropId: string}> = ({item, index, dropId}) => {

  const screen = useContext(ScreenContext)

  const [hover, setHover] = useState(false);

  const blueDivRef = useRef(null)
  const pinkDivRef = useRef(null)
  const whiteDivRef = useRef(null)
  const dropTitleRef = useRef(null)
  const auctionRef = useRef(null)
  const arrowRef = useRef(null)
  const viewDropRef = useRef(null)

  const handleScroll = (target: string) => {
    window.locomotiveScroll.scrollTo(target, { duration: 200 });
  };

  useEffect(() => {
    if (screen.fullWidth || screen.desktop) {
      const tl = gsap.timeline({});

      if (hover) {
        gsap.set(blueDivRef.current, { zIndex: -1 });
        gsap.set(pinkDivRef.current, { zIndex: 0 });
        tl.to(blueDivRef.current, {
          duration: 0.5,
          top: "0%",
          ease: "card",
        }, 0)
          .to(pinkDivRef.current, {
            duration: 0.42,
            top: "0%",
            ease: "card",
          }, 0.08)
          .to(whiteDivRef.current, {
            duration: 0.4,
            top: "0%",
            ease: "card",
          }, 0.1)
          .to(dropTitleRef.current, {
            duration: 0.5,
            color: colors.richBlack100,
            ease: "card",
            x: "8px",
          }, 0)
          .to(auctionRef.current, {
            duration: 0.5,
            color: colors.richBlack100,
            ease: "card",
          }, 0)
          .to(arrowRef.current, {
            duration: 0.5,
            color: colors.richBlack100,
            ease: "card",
          }, 0)
          .to(viewDropRef.current, {
            duration: 0.5,
            color: colors.richBlack100,
            ease: "card",
            x: "-8px",
          }, 0);
      } else {
        gsap.set(blueDivRef.current, { zIndex: 0 });
        gsap.set(pinkDivRef.current, { zIndex: -1 });
        tl.to( blueDivRef.current, {
            duration: 0.4,
            top: "100%",
            ease: "card",
          }, 0.1)
          .to(pinkDivRef.current, {
            duration: 0.42,
            top: "100%",
            ease: "card",
          }, 0.08)
          .to(whiteDivRef.current, {
            duration: 0.5,
            top: "100%",
            ease: "card",
          }, 0)
          .to(dropTitleRef.current, {
            duration: 0.5,
            color: colors.offWhite,
            ease: "card",
            x: "0",
          }, 0)
          .to(auctionRef.current, {
            duration: 0.5,
            color: colors.offWhite,
            ease: "card",
          }, 0)
          .to(arrowRef.current, {
            duration: 0.5,
            color: colors.offWhite,
            ease: "card",
          }, 0)
          .to(viewDropRef.current,{
            duration: 0.5,
            color: colors.offWhite,
            ease: "card",
            x: "0",
          }, 0);
      }
    }
  }, [hover, screen]);

  return (
    <DropWrapper
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => handleScroll(`#${dropId}-${index}`)}
    >
      <BlueBackground ref={blueDivRef} />
      <PinkBackground ref={pinkDivRef} />
      <WhiteBackground ref={whiteDivRef} />
      <NameStateWrapper>
        <DropTitle ref={dropTitleRef}>
          {item.title}
        </DropTitle>
        <AuctionState ref={auctionRef}>
          <Dot color={item.state.color} />
          {item.state.text}
        </AuctionState>
      </NameStateWrapper>
      <ViewNow ref={viewDropRef}>
        View Now <Arrow ref={arrowRef} />
      </ViewNow>
    </DropWrapper>
  );
}

const DropWrapper = styled.div`
  text-decoration: none;
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 0.5px solid ${colors.offWhite};
  position: relative;
  overflow: hidden;
  cursor: pointer;
  ${media.hover} {
    :hover {
    }
  }
  :last-of-type {
    border-bottom: none;
  }

  height: 3.333vw;

  ${media.tablet} {
    height: 7.813vw;
  }

  ${media.mobile} {
    height: 22.4vw;
    justify-content: space-between;
  }
`;

const DropTitle = styled.p`
  color: ${colors.offWhite};
  font-family: Sofia Pro;
  font-style: normal;
  font-weight: normal;
  line-height: 90%;
  letter-spacing: -0.02em;

  font-size: 1.042vw;
  width: 15.694vw;

  ${media.tablet} {
    width: 18.164vw;
    font-size: 1.465vw;
  }

  ${media.mobile} {
    font-size: 4vw;
    width: 40vw;
    margin-bottom: 2.667vw;
    font-weight: 800;
  }
`;

const NameStateWrapper = styled.div`
  display: flex;
  position: relative;
  z-index: 2;
  ${media.mobile} {
    flex-direction: column;
  }
`;

const AuctionState = styled.span`
  display: flex;
  align-items: center;
  color: ${colors.offWhite};
  font-family: Sofia Pro;
  font-style: normal;
  font-weight: normal;
  line-height: 90%;
  letter-spacing: -0.02em;

  font-size: 1.042vw;
  width: 23.889vw;

  ${media.tablet} {
    font-size: 1.465vw;
    width: 24.316vw;
  }

  ${media.mobile} {
    font-size: 4vw;
    width: fit-content;
  }
`;

const Dot = styled(DotSVG)<{ color?: string }>`
  width: 0.694vw;
  height: 0.694vw;
  margin-right: 0.556vw;

  circle {
    fill: ${(props) => props.color};
  }

  ${media.tablet} {
    width: 0.977vw;
    height: 0.977vw;
    margin-right: 0.781vw;
  }

  ${media.mobile} {
    width: 2.667vw;
    height: 2.667vw;
    margin-right: 2.133vw;
  }
`;

const Arrow = styled(ArrowSVG)`
  transform: rotate(90deg);

  width: 1.042vw;
  height: 1.042vw;
  margin-left: 0.903vw;

  ${media.tablet} {
    width: 1.465vw;
    height: 1.465vw;
    margin-left: 1.27vw;
  }

  ${media.mobile} {
    width: 4vw;
    height: 4vw;
    margin-left: 3.467vw;
  }

  path {
    stroke: ${colors.offWhite};
  }
`;

const ViewNow = styled.span`
  display: flex;
  align-items: center;
  color: ${colors.offWhite};
  font-family: Sofia Pro;
  font-style: normal;
  font-weight: 800;
  line-height: 90%;
  letter-spacing: -0.02em;
  cursor: pointer;
  position: relative;
  z-index: 2;
  font-size: 1.042vw;
  ${Arrow} {
    path {
      stroke: currentColor;
    }
  }
  ${media.tablet} {
    font-size: 1.465vw;
  }

  ${media.mobile} {
    font-size: 4vw;
  }
`;

const LargeImage = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  height: 56.944vw;

  ${media.tablet} {
    height: 67.285vw;
  }

  ${media.mobile} {
    height: 133.867vw;
  }

  img {
    height: 54.722vw;
    width: 22.431vw;
    margin-bottom: 0.694vw;

    ${media.tablet} {
      height: 64.16vw;
      width: 26.367vw;
      margin-bottom: 0.977vw;
    }

    ${media.mobile} {
      height: 125.333vw;
      width: 59.2vw;
      margin-bottom: 2.667vw;
    }
  }

  p {
    ${text.desktop.buttonText}
    color: ${colors.offWhite};
    text-transform: capitalize;


    ${media.tablet} {
      ${text.tablet.buttonText}
      text-transform: capitalize;
    }

    ${media.mobile} {
      ${text.mobile.buttonText}
      text-transform: capitalize;
    }
  }
`;

const SecondaryImage = styled.div<{shiftUp?: boolean}>`
  position: absolute;

  top: 3.681vw;
  right: 19.167vw;
  height: 24.444vw;

  ${media.tablet} {
    top: 5.176vw;
    right: 22.07vw;
    height: 26.953vw;
  }

  ${media.mobile} {
    height: 77.6vw;
    top: ${props => props.shiftUp ? "-6vw" : "13.333vw"};
    right: unset;
    left: 6.667vw;
  }

  img {
    height: 22.222vw;
    width: 14.653vw;
    margin-bottom: 0.694vw;

    ${media.tablet} {
      height: 23.828vw;
      width: 15.723vw;
      margin-bottom: 0.977vw;
    }

    ${media.mobile} {
      height: 69.067vw;
      width: 45.6vw;
      margin-bottom: 2.667vw;
    }
  }

  p {
    color: ${colors.offWhite};

    ${text.desktop.buttonText}
    text-transform: capitalize;

    ${media.tablet} {
      ${text.tablet.buttonText}
      text-transform: capitalize;
    }

    ${media.mobile} {
      ${text.mobile.buttonText}
      text-transform: capitalize;
    }
  }
`;

const TertiaryImage = styled.div`
  position: absolute;

  top: 29.792vw;
  right: 19.167vw;
  height: 17.431vw;

  ${media.tablet} {
    top: 34.473vw;
    right: 22.07vw;
    height: 24.512vw;
  }

  ${media.mobile} {
    height: 54.133vw;
    top: 76.933vw;
    right: unset;
    left: 11.467vw;
  }

  img {
    height: 15.208vw;
    width: 10.043vw;
    margin-bottom: 0.694vw;

    ${media.tablet} {
      height: 21.387vw;
      width: 14.123vw;
      margin-bottom: 0.977vw;
    }

    ${media.mobile} {
      height: 45.6vw;
      width: 40.8vw;
      margin-bottom: 2.667vw;
      object-fit: cover;
      object-position: top center;
    }
  }

  p {
    color: ${colors.offWhite};
    text-transform: capitalize;

    ${text.desktop.buttonText}

    ${media.tablet} {
      ${text.tablet.buttonText}
    }

    ${media.mobile} {
      ${text.mobile.buttonText}
    }
  }
`;
