import React, { useContext, useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { ScreenContext } from "App";
import { useNavigate } from 'utils/Hooks'
import gsap from "gsap";

import media from "styles/media";
import text from "styles/text";
import colors from "styles/colors";

import { ReactComponent as ArrowSVG } from "assets/images/symbols/arrow.svg";
import { ReactComponent as BitskiSVG } from "assets/svg/bitskiLogomarkBlue.svg";
import { ReactComponent as TikTokSVG } from "assets/svg/tiktok.svg";

type props = {
  children: any,
  path: string
}

const Link: React.FC<props> = ({ children, path }) => {
  const wrapperRef = useRef(null);
  const titleRef = useRef(null);
  const arrowRef = useRef(null);
  const whiteRef = useRef(null);
  const pinkRef = useRef(null);
  const blueRef = useRef(null);

  const navigate = useNavigate()

  const [animation, setAnimation] = useState(gsap.timeline());

  const screen = useContext(ScreenContext);

  const handleMouseEnter = () => {
    animation.play();
  };

  const handleMouseLeave = () => {
    animation.reverse();
  };

  const handleClick = (path: string) => {
    navigate(path)
  }

  useEffect(() => {
    if (screen.fullWidth || screen.desktop) {
      const tl = gsap.timeline({
        paused: true,
      });

      tl.fromTo(
        pinkRef.current,
        {
          height: "0%",
        },
        {
          duration: 0.3,
          height: "100%",
          ease: "circ.out",
        },
        0
      );
      tl.fromTo(
        blueRef.current,
        {
          height: "0%",
        },
        {
          duration: 0.3,
          height: "100%",
          ease: "circ.out",
        },
        0.05
      );
      tl.fromTo(
        whiteRef.current,
        {
          height: "0%",
        },
        {
          duration: 0.3,
          height: "100%",
          ease: "circ.out",
        },
        0.1
      );
      tl.fromTo(
        titleRef.current,
        {
          left: "0vw",
          color: colors.offWhite,
        },
        {
          duration: 0.3,
          left: "0.694vw",
          color: colors.richBlack100,
        },
        0
      );

      tl.fromTo(
        arrowRef.current,
        {
          right: "4.444vw",
        },
        {
          duration: 0.3,
          right: "5.139vw",
        },
        0
      );

      const paths = arrowRef.current.children;
      tl.fromTo(
        paths,
        {
          stroke: colors.offWhite,
        },
        {
          duration: 0.3,
          stroke: colors.richBlack100,
        },
        0
      );

      setAnimation(tl);

      return () => {
        tl.kill();
      };
    }
  }, [screen]);

  return (
    <LinkWrapper
      onClick={() => handleClick(path)}
      ref={wrapperRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <PinkDiv ref={pinkRef} />
      <BlueDiv ref={blueRef} />
      <WhiteDiv ref={whiteRef} />
      <LinkTitle ref={titleRef}>{children}</LinkTitle>
      <Arrow ref={arrowRef} />
    </LinkWrapper>
  );
};

const LinkWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  cursor: pointer;

  ${media.desktop} {
    padding-left: 3.472vw;
    padding-right: 3.472vw;
    padding-top: 1.389vw;
    padding-bottom: 1.389vw;
  }

  ${media.tablet} {
    padding-left: 4.883vw;
    padding-right: 4.883vw;
    padding-top: 1.953vw;
    padding-bottom: 1.953vw;
  }

  ${media.mobile} {
    padding-left: 6.667vw;
    padding-right: 6.667vw;
    padding-top: 5.333vw;
    padding-bottom: 5.333vw;
  }
`;

const Div = styled.div`
  width: 100%;
  height: 0%;
  position: absolute;
  bottom: 0;
  left: 0;
`;

const WhiteDiv = styled(Div)`
  background-color: ${colors.offWhite};
  z-index: 3;
`;

const PinkDiv = styled(Div)`
  background-color: ${colors.razzmatazz100};
  z-index: 1;
`;

const BlueDiv = styled(Div)`
  background-color: ${colors.splash100};
  z-index: 2;
`;

const LinkTitle = styled.span`
  color: ${colors.offWhite};
  text-transform: uppercase;
  position: relative;
  z-index: 3;

  ${media.desktop} {
    ${text.desktop.displayH3}
  }

  ${media.tablet} {
    ${text.tablet.displayH3}
  }

  ${media.mobile} {
    ${text.mobile.displayH3}
    font-size: 6.4vw;
  }
`;

const Arrow = styled(ArrowSVG)`
  position: absolute;
  height: auto;
  z-index: 3;

  ${media.desktop} {
    width: 1.389vw;
    top: 2.083vw;
    right: 4.444vw;
  }

  ${media.tablet} {
    width: 1.953vw;
    top: 2.93vw;
    right: 6.25vw;
  }

  ${media.mobile} {
    width: 5.333vw;
    right: 10.4vw;
    top: 5.867vw;
  }
`;

const Footer: React.FC = () => {
  const navigate = useNavigate()

  const handleClick = (path: string) => {
    navigate(path)
  }

  return (
    <Wrapper data-scroll-section>
      <LinkContainer>
        <Link path={"/"}>tiktok top moments</Link>
        <Link path={"/exhibit"}>visit the exhibit</Link>
        {/* <Link>the lot</Link> */}
      </LinkContainer>
      <Bottom>
        <Column1>
          <LogoContainer>
            <TikTok />
            <HR />
            <Bitski />
          </LogoContainer>
          <TextContainer>
            <Text>{"© TikTok 2021\nAll rights reserved."}</Text>
            <Text>{"© Bitski 2021\nAll rights reserved."}</Text>
          </TextContainer>
        </Column1>
        <Column>
          <DropsTitle>tiktok top moments nft drops</DropsTitle>
          <Drops>
            <Drop onClick={() => handleClick('/lil-nas-x')}>Rudy Willingham + Lil Nas X</Drop>
            <Drop onClick={() => handleClick('/curtis-roach')}>Curtis Roach + COIN ARTIST</Drop>
            <Drop onClick={() => handleClick('/fnmeka')}>FNMeka + RTFKT</Drop>
            <Drop onClick={() => handleClick('/gary-vee')}>Gary Vaynerchuk + Jess Marciante + x0r</Drop>
            <Drop onClick={() => handleClick('/bella-poarch')}>Bella Poarch + Grimes</Drop>
            <Drop onClick={() => handleClick('/brittany-broski')}>Brittany Broski + Grimes</Drop>
          </Drops>
        </Column>
      </Bottom>
    </Wrapper>
  );
};

export default Footer;

const Wrapper = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.desktop} {
    margin-bottom: 5.556vw;
  }

  ${media.tablet} {
    margin-bottom: 7.813vw;
  }

  ${media.mobile} {
    margin-bottom: 20vw;
  }
`;

const Bottom = styled.div`
  display: flex;

  ${media.desktop} {
    padding-left: 3.472vw;
    padding-right: 3.472vw;
    padding-bottom: 7.361vw;
  }

  ${media.tablet} {
    padding-left: 4.883vw;
    padding-right: 4.883vw;
    padding-bottom: 12.695vw;
  }

  ${media.mobile} {
    padding-left: 6.667vw;
    padding-right: 6.667vw;
    padding-bottom: 31.733vw;
    flex-direction: column;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${media.mobile} {
    order: 1;
    margin-bottom: 20vw;
  }
`;

const Column1 = styled(Column)`
  ${media.desktop} {
    margin-right: 7.639vw;
  }

  ${media.tablet} {
    margin-right: 10.742vw;
  }

  ${media.mobile} {
    order: 2;
  }
`;

const Drop = styled.span`
  color: ${colors.offWhite};
  cursor: pointer;
  font-weight: bold;

  ${media.desktop} {
    ${text.desktop.headerBodyCopy}
    font-weight: bold;
    margin-bottom: 0.694vw;
    width: 38.194vw;
  }

  ${media.tablet} {
    ${text.tablet.headerBodyCopy}
    font-weight: bold;
    margin-bottom: 0.977vw;
  }

  ${media.mobile} {
    ${text.mobile.headerBodyCopy}
    font-weight: bold;
    margin-bottom: 2.667vw;
  }
`;

const DropsTitle = styled.span`
  color: ${colors.offWhite};
  opacity: 0.25;
  text-transform: uppercase;

  ${media.desktop} {
    ${text.desktop.displayH5};
    font-weight: bold;
    margin-bottom: 1.736vw;
    height: 1.736vw;
  }

  ${media.tablet} {
    ${text.tablet.displayH5}
    font-weight: bold;
    margin-bottom: 2.441vw;
  }

  ${media.mobile} {
    ${text.mobile.displayH5}
    font-weight: bold;
    margin-bottom: 6.667vw;
  }
`;

const Drops = styled.div`
  display: flex;

  ${media.desktop} {
    width: 76.389vw;
    flex-wrap: wrap;
  }

  ${media.tablet} {
    flex-wrap: no-wrap;
    flex-direction: column;
  }

  ${media.mobile} {
    flex-direction: column;
    flex-wrap: no-wrap;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;

  ${media.desktop} {
    margin-bottom: 2.083vw;
  }

  ${media.tablet} {
    margin-bottom: 2.93vw;
  }

  ${media.mobile} {
    margin-bottom: 8vw;
  }
`;

const Bitski = styled(BitskiSVG)`
  height: auto;

  path {
    fill: ${colors.offWhite};
  }

  ${media.desktop} {
    width: 2.083vw;
  }

  ${media.tablet} {
    width: 2.832vw;
  }

  ${media.mobile} {
    width: 7.733vw;
  }
`;

const TikTok = styled(TikTokSVG)`
  height: auto;

  ${media.desktop} {
    width: 1.806vw;
  }

  ${media.tablet} {
    width: 2.539vw;
  }

  ${media.mobile} {
    width: 6.933vw;
  }
`;

const HR = styled.hr`
  background-color: ${colors.offWhite};
  opacity: 0.5;
  transform: rotate(90deg);
  height: 0.05px;

  ${media.desktop} {
    width: 2.083vw;
    margin-top: 1.389vw;
    margin-bottom: 1.389vw;
  }

  ${media.tablet} {
    width: 2.93vw;
    margin-top: 1.953vw;
    margin-bottom: 1.953vw;
  }

  ${media.mobile} {
    width: 8vw;
    margin-top: 5.333vw;
    margin-bottom: 5.333vw;
  }
`;

const TextContainer = styled.div`
  display: flex;

  ${media.desktop} {
    flex-direction: column;
  }

  ${media.tablet} {
    flex-direction: column;
  }
`;

const Text = styled.p`
  color: ${colors.offWhite};
  white-space: pre-wrap;
  opacity: 0.5;

  ${media.desktop} {
    ${text.desktop.headerBodyCopy2}
    width: 9.056vw;
    font-size: 0.972vw;
    margin-bottom: 2.083vw;
  }

  ${media.tablet} {
    ${text.tablet.headerBodyCopy2}
    font-size: 1.367vw;
    margin-bottom: 2.93vw;
  }

  ${media.mobile} {
    ${text.mobile.headerBodyCopy2}
    font-size: 3.733vw;
    opacity: 1;

    :first-child {
      margin-right: 14.667vw;
    }
  }
`;
