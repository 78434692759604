import { Canvas, useFrame, useThree } from "@react-three/fiber";
import React, {Suspense, useRef, useEffect, useState} from "react";
import styled from "styled-components";
import { useTexture } from "@react-three/drei";
import gsap from 'gsap'

import colors from "styles/colors";
import text from "styles/text";
import media, {desktop, tablet, mobile} from "styles/media";

import "materials/ImageColorSkewMaterial";

import PrimaryButton from 'components/PrimaryButton'
import TextAnimation from "components/TextAnimation";
import ImageAnimation from 'components/ImageAnimation'

import { useMedia } from "utils/Hooks";

type Props = {
  bigImage: {
    src: string;
    caption: string;
  };
  smallImage: {
    src: string;
    caption: string;
  };
  smallImage2?: {
    src: string;
    caption: string;
  };
  title: any;
  paragraph: string;
  link: string;
  id: string;
  left: boolean;
  
};

const Images: React.FC<{ img1: string, left: boolean, contRef: any, large?: boolean; }> = ({ img1, left, large }) => {
  const { viewport } = useThree();
  const tex = useTexture(img1);

  const material = useRef<any>();

  const getPosition = (contDim: number, contWidth: number, imgDim: number, margin: number) => {
    const pos = viewport.width * (((contDim / 2) - (imgDim / 2) - margin) / contWidth)
    return pos
  }

  const getSize = (contDim: number, imgDim: number) => {
    const size = viewport.width * (imgDim / contDim)
    return size
  }

  useFrame(() => {
    if (window.speed !== material.current.delta) material.current.delta = window.speed * 0.01
  });

  const useMedia = (fw: any, d: any, t: any, m: any) => {
    if (viewport.width > desktop) {
      return fw
    } else if (viewport.width <= desktop && viewport.width > tablet) {
      return d
    } else if (viewport.width <= tablet && viewport.width > mobile) {
      return t
    } else {
      return m
    }
  }

  const position = {
    x: useMedia(getPosition(1440, 1440, 323, 0), getPosition(1440, 1440, 323, 0), getPosition(1024, 1024, 270, 0), getPosition(375, 375, 222, 0)),
    y: useMedia(-getPosition(788, 1440, 788, 15), -getPosition(788, 1440, 788, 15), -getPosition(657, 1024, 657, 15), -getPosition((large ? 1132 : 1032), 375, (large ? 570 : 470), 32))
  }

  const sizeX = useMedia(getSize(1440, 323), getSize(1440, 323), getSize(1024, 270), getSize(375, 222))
  const sizeY = useMedia(getSize(1440, 788), getSize(1440, 788), getSize(1024, 657), getSize(375, (large ? 570 : 470)))

  return (
    <mesh position={[(left ? -position.x : position.x), position.y, 0]}>
      <planeGeometry
        args={[sizeX, sizeY, 32]}
      />
      <imageColorSkewMaterial map={tex} delta={0} ref={material} />
    </mesh>
  );
};

const CreatorsImage: React.FC<Props> = ({
  bigImage,
  smallImage,
  smallImage2,
  title,
  paragraph,
  link,
  id,
  left
}) => {

  const dataScrollSpeed = useMedia(2, 2, 0.75, 0.25)

  const containerRef = useRef(null)

  const [triggerTitle, setTriggerTitle] = useState(false)
  const [triggerOverline, setTriggerOverline] = useState(false)
  const [triggerBody, setTriggerBody] = useState(false)
  const [triggerButton, setTriggerButton] = useState(false)
  const [triggerFigure1, setTriggerFigure1] = useState(false)
  const [triggerFigure2, setTriggerFigure2] = useState(false)
  const [triggerFigure3, setTriggerFigure3] = useState(false)

  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        start: "top-=650 top",
        scroller: '.smooth-scroll',
      }
    })

    tl.call(setTriggerFigure1, [true], 0)
    tl.call(setTriggerFigure2, [true], 0)
    tl.call(setTriggerFigure3, [true], 0)
    tl.call(setTriggerOverline, [true], 0)
    tl.call(setTriggerTitle, [true], 0.5)
    tl.call(setTriggerBody, [true], 0.5)
    tl.call(setTriggerButton, [true], 1)

    return () => {
      tl.kill()
    }

  }, [])

  const figure3Height = useMedia('18.403vw', '18.403vw', '22.852vw', '79.6vw')
  const figure3Width = useMedia('12.15vw', '12.15vw', '13.574vw', '45.6vw')
  const mobileHeight = !!smallImage2 ? '300vw' : '275.2vw'

  return (
    <Wrapper data-scroll-section id={id} ref={containerRef} large={!!smallImage2}>
      <AnimationWrapper>
        <ImageAnimation
          height={useMedia('56.944vw', '56.944vw', '67.285vw', mobileHeight )}
          width={"100vw"}
          trigger={triggerFigure1}
        >
          <StyledCanvas orthographic dpr={1}>
            <Suspense fallback={<></>}>
              <Images contRef={containerRef} img1={bigImage.src} left={left} large={!!smallImage2}/>
            </Suspense>
          </StyledCanvas>
        </ImageAnimation>
      </AnimationWrapper>
      <DomContent>
        <Text $left={left}>
          <Column>
            <TextAnimation
              textArray={[
                () => <Overline>Live Auction</Overline>
              ]}
              className={id + '-overline'}
              height={useMedia('0.903vw', '0.903vw', '1.27vw', '3.467vw')}
              trigger={triggerOverline}
            />
          </Column>
          <Title>
            <TextAnimation
              textArray={title.map((item: any) => () => item)}
              className={id + '-title'}
              height={useMedia('6.944vw', '6.944vw', '6.836vw', '14.4vw')}
              trigger={triggerTitle}
            />
          </Title>
          <Body>
            <TextAnimation
              textArray={[
                () => paragraph
              ]}
              className={id + '-body'}
              height={useMedia('8.333vw', '8.333vw', '11.719vw', '36vw')}
              trigger={triggerBody}
            />
          </Body>
          <Column>
            <TextAnimation
              textArray={[
                () => <PrimaryButton onClick={() => false}>Enter Drop</PrimaryButton>
              ]}
              className={id + '-button'}
              height={useMedia('4.444vw', '4.444vw', '6.641vw', '16vw')}
              trigger={triggerButton}
            />
          </Column>
        </Text>
        <Figure1 $left={left} >
          <img src={bigImage.src} alt={bigImage.caption} />
          <Caption $left={left}>{bigImage.caption}</Caption>
        </Figure1>
        <Figure2
          data-scroll
          data-scroll-speed={dataScrollSpeed}
          data-scroll-target={`#${id}`}
          $left={left}
          $small={!!smallImage2}
        >
          <ImageAnimation
            height={useMedia('24.444vw', '24.444vw', '26.953vw', '77.6vw')}
            width={useMedia('14.65vw', '14.65vw', '15.723vw', '45.6vw')}
            trigger={triggerFigure2}
          >
            <img src={smallImage.src} alt={smallImage.caption} />
            <Caption $left={left}>{smallImage.caption}</Caption>
          </ImageAnimation>
        </Figure2>
        {smallImage2 && (
          <Figure3
            data-scroll
            data-scroll-speed={dataScrollSpeed}
            data-scroll-delay="0.09"
            data-scroll-target={`#${id}`}
            $left={left}
          >
            <ImageAnimation
              height={figure3Height}
              width={figure3Width}
              trigger={triggerFigure3}
            >
              <img src={smallImage2.src} alt={smallImage2.caption} />
              <Caption $left={left}>{smallImage2.caption}</Caption>
            </ImageAnimation>
          </Figure3>
        )}
      </DomContent>
    </Wrapper>
  );
};

export default CreatorsImage;

const StyledCanvas = styled(Canvas)`
  position: absolute !important;
  overflow: hidden;
  height: 100%;
  width: 100%;
  z-index: 1;
`

const Wrapper = styled.section<{large?: boolean}>`
  position: relative;
  height: 54.722vw;

  ${media.tablet} {
    height: 64.16vw;
  }

  ${media.mobile} {
    display: flex;
    flex-direction: column;
    height: ${props => props.large ? '300vw' : '275.2vw'};
  }
`;

const DomContent = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  ${media.mobile} {
    order: 0;
    position: relative;
    padding-left: 6.667vw;
    padding-right: 6.667vw;
  }
`;

const Text = styled.div<{$left: boolean}>`
  margin-top: 9.861vw;
  margin-left: ${props => props.$left ? '42.7vw' : '11.32vw'};
  box-sizing: border-box;

  a {
    text-decoration: none;
  }

  ${media.tablet} {
    margin-top: 12.695vw;
    margin-left: ${props => props.$left ? '45.801vw' : '4.883vw'};
  }

  ${media.mobile} {
    width: 88.5vw;
    margin-top: 0vw;
    margin-left: 0vw;
    margin-bottom: 155.199vw;
  }
`;

const Overline = styled.span`
  color: ${colors.razzmatazz100};
  ${text.desktop.displayH5}
  margin-bottom: 2.08vw;

  :before {
    content: "";
    width: 0.69vw;
    height: 0.69vw;
    background: ${colors.razzmatazz100};
    border-radius: 50%;
    display: inline-block;
    margin-right: 0.69vw;
  }

  ${media.tablet} {
    ${text.tablet.displayH5}

    :before {
      width: 0.98vw;
      height: 0.98vw;
      margin-right: 0.98vw;
    }
  }

  ${media.mobile} {
    ${text.mobile.displayH5}

    :before {
      width: 2.667vw;
      height: 2.667vw;
      margin-right: 2.667vw;
    }
  }
`;

const Title = styled.h2`
  display: flex;
  flex-direction: column;
  color: ${colors.offWhite};
  white-space: pre-line;

  ${text.desktop.headerH1}
  margin-top: 2.08vw;
  margin-bottom: 2.08vw;

  ${media.tablet} {
    ${text.tablet.headerH2}
    margin-top: 1.953vw;
    margin-bottom: 1.953vw;
  }

  ${media.mobile} {
    ${text.mobile.headerH2}
    font-size: 14.4vw;
    line-height: 100%;
    margin-top: 5.333vw;
    margin-bottom: 5.333vw;
  }
`;

const Body = styled.p`
  color: ${colors.offWhite};
  display: flex;
  flex-direction: column;

  ${text.desktop.headerBodyCopy}
  width: 30.347vw;
  margin-bottom: 5.28vw;

  ${media.tablet} {
    ${text.tablet.headerBodyCopy}
    margin-bottom: 5.86vw;
    width: 42.676vw;
  }

  ${media.mobile} {
    ${text.mobile.headerBodyCopy2}
    margin-bottom: 10.667vw;
    width: 78.933vw;
  }
`;

const Figure1 = styled.figure<{$left: boolean}>`
  position: absolute;
  margin: 0;
  top: 0;
  ${props => props.$left ? 'left: 0' : 'right: 0'};

  ${media.mobile} {
    width: 59.2vw;
    height: 133.867vw;
    top: unset;
    bottom: 0;
  }

  img {
    opacity: 0;

    width: 22.431vw;
    height: 54.722vw;
    margin-bottom: 0.694vw;

    ${media.tablet} {
      width: 26.367vw;
      height: 64.16vw;
      margin-bottom: 0.977vw;
    }

    ${media.mobile} {
      width: 100%;
      height: 125.333vw;
      margin-bottom: 2.667vw;
    }
  }
`;

const Figure2 = styled.figure<{$left: boolean, $small: boolean}>`
  position: absolute;
  margin: 0;
  top: 3.68vw;
  z-index: 3;
  ${props => props.$left ? 'left: 19.17vw' : 'right: 19.17vw'};

  ${media.tablet} {
    top: 5.176vw;
    ${props => props.$left ? 'left: 22.07vw' : 'right: 22.07vw'};
  }

  ${media.mobile} {
    top: 154.667vw;
    ${props => props.$left ? 'left: 52vw' : 'right: 52vw'};
  }

  img {
    // opacity: 0;
    width: 14.65vw;
    height: 22.22vw;
    margin-bottom: 0.69vw;
    object-fit: cover;

    ${media.tablet} {
      width: 15.723vw;
      height: 23.828vw;
      margin-bottom: 0.977vw;
    }

    ${media.mobile} {
      width: ${props => props.$small ? '41.333vw' : '45.6vw'};
      height: ${props => props.$small ? '49.067vw' : '69.067vw'};
      margin-bottom: 2.667vw;
    }
  }
`;

const Figure3 = styled.figure<{$left: boolean}>`
  position: absolute;
  margin: 0;
  z-index: 3;
  top: 33.65vw;
  ${props => props.$left ? 'left: 19.17vw' : 'right: 19.17vw'};

  ${media.tablet} {
    top: 36.426vw;
    ${props => props.$left ? 'left: 22.07vw' : 'right: 22.07vw'};
  }

  ${media.mobile} {
    top: 220vw;
    ${props => props.$left ? 'left: 47.733vw' : 'right: 47.733vw'};
  }

  img {
    /* opacity: 0; */
    object-fit: cover;
    object-position: top;

    width: 12.15vw;
    height: 18.403vw;
    margin-bottom: 0.69vw;

    ${media.tablet} {
      width: 12.988vw;
      height: 19.727vw;
      margin-bottom: 0.977vw;
    }

    ${media.mobile} {
      width: 46.667vw;
      height: 70.667vw;
      margin-bottom: 2.667vw;
    }
  }
`;

const Caption = styled.figcaption<{$left: boolean}>`
  text-transform: capitalize !important;
  color: ${colors.offWhite};
  width: 100%;
  text-align: ${props => props.$left ? 'right' : 'left'};

  ${text.desktop.buttonText}

  ${media.tablet} {
    ${text.tablet.buttonText}
  }

  ${media.mobile} {
    ${text.mobile.buttonText}
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const AnimationWrapper = styled.div`
  position: absolute;
`
