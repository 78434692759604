import {useRef} from 'react'
import styled from "styled-components";
import Hero from "../sections/home/Hero";
import Banners from "../sections/home/Banners";
import PhoneZoom from "sections/home/PhoneZoom";
import CreatorsImage from 'sections/home/CreatorsImage'
import FAQ from "sections/home/FAQ";

import lilNasXDesktop from "assets/images/lilNasX/home-desktop.jpg";
import lilNasXTablet from "assets/images/lilNasX/home-tablet.jpg";
import lilNasXMobile from "assets/images/lilNasX/home-mobile.jpg";

import rudy from "assets/images/rudyWillingham/full.jpg";

import bellaPoarchDesktop from "assets/images/bellaPoarch/home-desktop.jpg";
import bellaPoarchTablet from "assets/images/bellaPoarch/home-tablet.jpg";
import bellaPoarchMobile from "assets/images/bellaPoarch/home-mobile.jpg";

import garyVDesktop from "assets/images/garyV/home-desktop.jpg";
import garyVTablet from "assets/images/garyV/home-tablet.jpg";
import garyVMobile from "assets/images/garyV/home-mobile.jpg";

import jess from "assets/images/jessM/full.jpg";
import x0r from "assets/images/x0r/full.jpg";

import curtisRoachDesktop from "assets/images/curtisRoach/home-desktop.jpg";
import curtisRoachTablet from "assets/images/curtisRoach/home-tablet.jpg";
import curtisRoachMobile from "assets/images/curtisRoach/home-mobile.jpg";

import coin from "assets/images/coinArtist/full.jpg";
import rtfkt from "assets/images/rtfkt/full.jpg";

import fnmekaDesktop from "assets/images/fnmeka/home-desktop.jpg";
import fnmekaTablet from "assets/images/fnmeka/home-tablet.jpg";
import fnmekaMobile from "assets/images/fnmeka/home-mobile.jpg";

import brittanyBroskiDesktop from "assets/images/brittanyBroski/home-desktop.jpg";
import brittanyBroskiTablet from "assets/images/brittanyBroski/home-tablet.jpg";
import brittanyBroskiMobile from "assets/images/brittanyBroski/home-mobile.jpg";

import grimes1 from "assets/images/grimes1/full.jpg";
import grimes2 from 'assets/images/grimes2/full.jpg'

import media from 'styles/media'
import colors from 'styles/colors'

import { useMedia, useEllipseGlow } from 'utils/Hooks'

const Home: React.FC<{}> = () => {
  const glow1Ref = useRef<HTMLCanvasElement>(null)
  const glow2Ref = useRef<HTMLCanvasElement>(null)
  const glow3Ref = useRef<HTMLCanvasElement>(null)
  const glow4Ref = useRef<HTMLCanvasElement>(null)
  const glow5Ref = useRef<HTMLCanvasElement>(null)
  const glow6Ref = useRef<HTMLCanvasElement>(null)
  const glow7Ref = useRef<HTMLCanvasElement>(null)

  useEllipseGlow(
    glow1Ref,
    glow1Ref,
    0.5,
    0.5,
    0.1355,
    0.3785,
    Math.PI * -0.722,
    colors.razzmatazz100,
    0.6
  )

  useEllipseGlow(
    glow2Ref,
    glow2Ref,
    0.5,
    0.5,
    0.1355,
    0.3785,
    Math.PI * -0.862,
    colors.splash100,
    0.5
  )

  useEllipseGlow(
    glow3Ref,
    glow3Ref,
    0.5,
    0.5,
    0.1355,
    0.3785,
    Math.PI * -0.6,
    colors.splash100,
    0.5
  )

  useEllipseGlow(
    glow4Ref,
    glow4Ref,
    0.5,
    0.5,
    0.1355,
    0.3785,
    Math.PI * -0.862,
    colors.razzmatazz100,
    0.5
  )

  useEllipseGlow(
    glow5Ref,
    glow5Ref,
    0.5,
    0.5,
    0.1355,
    0.3785,
    Math.PI * -0.862,
    colors.razzmatazz100,
    0.5
  )

  useEllipseGlow(
    glow6Ref,
    glow6Ref,
    0.5,
    0.5,
    0.1355,
    0.3785,
    Math.PI * -0.862,
    colors.splash100,
    0.5
  )

  useEllipseGlow(
    glow7Ref,
    glow7Ref,
    0.5,
    0.5,
    0.1355,
    0.3785,
    Math.PI * -0.862,
    colors.razzmatazz100,
    0.5
  )

  const lilNasXTitle = useMedia(
    ['Lil Nas X &', 'Rudy Willingham'],
    ['Lil Nas X &', 'Rudy Willingham'],
    ['Rudy Willingham', '& Lil Nas X'],
    ['Rudy', 'Willingham', '& Lil Nas X'],
  )

  const fnmekaTitle = useMedia(
    [`FNMeka &`, `RTFKT`],
    [`FNMeka &`, `RTFKT`],
    [`FNMeka &`, `RTFKT`],
    [`FNMeka &`, `RTFKT`],
  )

  const bellaPoarchTitle = useMedia(
    [`Bella Poarch &`, `Bobby Hundreds`], 
    [`Bella Poarch &`, `Bobby Hundreds`], 
    [`Bella Poarch &`, `Bobby Hundreds`], 
    [`Bella Poarch`, `& Bobby`, `Hundreds`]
  )

  const curtisRoachTitle = useMedia(
    [`Curtis Roach &`, `Coin Artist`],
    [`Curtis Roach &`, `Coin Artist`],
    [`Curtis Roach &`, `Coin Artist`],
    [`Curtis Roach`, `& Coin Artist`]
  )

  const garyVTitle = useMedia(
    [`Gary Vaynerchuk,`, `Jess Marciante &`, `x0r`],
    [`Gary Vaynerchuk,`, `Jess Marciante &`, `x0r`],
    [`Gary Vaynerchuk,`, `Jess Marciante &`, `x0r`],
    [`Gary`, `Vaynerchuk,`, `Jess Marciante`, `& x0r`]
  )

  const brittanyBroskiTitle = useMedia(
    [`Brittany Broski`, `& Grimes`],
    [`Brittany Broski`, `& Grimes`],
    [`Brittany Broski`, `& Grimes`],
    [`Brittany Broski`, `& Grimes`],
  )

  return (
    <>
      <Hero />
      <Banners />
      <NasWrapper>
        <Glow1 data-scroll-section ref={glow1Ref}/>
        <CreatorsImage
          bigImage={{ src: useMedia(lilNasXDesktop, lilNasXDesktop, lilNasXTablet, lilNasXMobile), caption: "@LilNasX" }}
          smallImage={{ src: rudy, caption: "@RudyWillingham" }}
          title={lilNasXTitle}
          paragraph="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed viverra ipsum nunc aliquet bibendum enim facilisis gravida neque."
          link="/"
          id="nas"
          left={false}
        />
      </NasWrapper>
      <DefaultCreatorWrapper>
        <Glow2 data-scroll-section ref={glow2Ref}/>
        <Glow3 data-scroll-section ref={glow3Ref}/>
        <CreatorsImage
          bigImage={{ src: useMedia(fnmekaDesktop, fnmekaDesktop, fnmekaTablet, fnmekaMobile), caption: "@FNMeka" }}
          smallImage={{ src: rtfkt, caption: "@RTFKT" }}
          title={fnmekaTitle}
          paragraph="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed viverra ipsum nunc aliquet bibendum enim facilisis gravida neque."
          link="/"
          id="fnMeka"
          left={true}
        />
      </DefaultCreatorWrapper>
      <DefaultCreatorWrapper>
        <Glow4 data-scroll-section ref={glow4Ref}/>
        <CreatorsImage
          bigImage={{ src: useMedia(bellaPoarchDesktop, bellaPoarchDesktop, bellaPoarchTablet, bellaPoarchMobile), caption: "@BellaPoarch" }}
          smallImage={{ src: grimes1, caption: "@Grimes" }}
          title={bellaPoarchTitle}
          paragraph="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed viverra ipsum nunc aliquet bibendum enim facilisis gravida neque."
          link="/"
          id="bella"
          left={false}
        />
      </DefaultCreatorWrapper>
      <DefaultCreatorWrapper>
        <Glow5 data-scroll-section ref={glow5Ref}/>
        <CreatorsImage
          bigImage={{ src: useMedia(curtisRoachDesktop, curtisRoachDesktop, curtisRoachTablet, curtisRoachMobile), caption: "@CurtisRoach" }}
          smallImage={{ src: coin, caption: "@CoinArtist" }}
          title={curtisRoachTitle}
          paragraph="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed viverra ipsum nunc aliquet bibendum enim facilisis gravida neque."
          link="/"
          id="curtis"
          left={true}
        />
      </DefaultCreatorWrapper>
      <DefaultCreatorWrapper>
        <Glow6 data-scroll-section ref={glow6Ref}/>
        <CreatorsImage
          bigImage={{ src: useMedia(garyVDesktop, garyVDesktop, garyVTablet, garyVMobile), caption: "@GaryVaynerchuk" }}
          smallImage={{ src: jess, caption: "@JessMarciante" }}
          smallImage2={{ src: x0r, caption: "@x0r" }}
          title={garyVTitle}
          paragraph="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed viverra ipsum nunc aliquet bibendum enim facilisis gravida neque."
          link="/"
          id="gary"
          left={false}
        />
      </DefaultCreatorWrapper>
      <DefaultCreatorWrapper>
        <Glow7 data-scroll-section ref={glow7Ref}/>
        <CreatorsImage
          bigImage={{ src: useMedia(brittanyBroskiDesktop, brittanyBroskiDesktop, brittanyBroskiTablet, brittanyBroskiMobile), caption: "@BrittanyBroski" }}
          smallImage={{ src: grimes2, caption: "@Grimes" }}
          title={brittanyBroskiTitle}
          paragraph="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed viverra ipsum nunc aliquet bibendum enim facilisis gravida neque."
          link="/"
          id="brittany"
          left={true}
        />
      </DefaultCreatorWrapper>
      <PhoneZoom />
      <FAQ />
    </>
  );
};

const NasWrapper = styled.div`
  position: relative;
  margin-top: 17.4vw;

  ${media.tablet} {
    margin-top: 14.648vw;
  }

  ${media.mobile} {
    margin-top: 26.667vw;
  }
`;

const DefaultCreatorWrapper = styled.div`
  position: relative;
  margin-top: 20.83vw;

  ${media.tablet} {
    margin-top: 29.297vw;
  }

  ${media.mobile} {
    margin-top: 53.333vw; 
  }
`;

const Glow = styled.canvas`
  position: absolute;
  width: 52.292vw;
  height: 52.292vw;
  filter: blur(50px);
`

const Glow1 = styled(Glow)`
  top: -30vw;
  left: -40vw;
`

const Glow2 = styled(Glow)`
  top: -21.431vw;
  right: -33.167vw;
`

const Glow3 = styled(Glow)`
  top: 45.569vw;
  left: -45vw;
`

const Glow4 = styled(Glow)`
  top: 0vw;
  left: -34vw;
`

const Glow5 = styled(Glow)`
  top: 0vw;
  right: -34vw;
`

const Glow6 = styled(Glow)`
  top: -21vw;
  left: -30vw;
`

const Glow7 = styled(Glow)`
  top: 0vw;
  right: -34vw;
`

export default Home;
