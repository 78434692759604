import React, {useEffect, useRef} from 'react'
import styled from 'styled-components'
import gsap from 'gsap'

type props = {
  children: any,
  height: string,
  width: string,
  trigger: boolean
}

const ImageAnimation: React.FC<props> = ({children, height, width, trigger}) => {
  const wrapperRef = useRef(null)

  useEffect(() => {
    if (trigger) {
      const tl = gsap.timeline()
      tl.to(wrapperRef.current, {
        duration: 1,
        ease: "circ.inOut",
        height
      })
  
      return () => {
        tl.kill()
      }
    }
  }, [trigger, height])

  return (
    <Wrapper $height={height} $width={width}>
      <ContentWrapper ref={wrapperRef}>
        <Content $height={height} $width={width}>
          {children}
        </Content>
      </ContentWrapper>
    </Wrapper>
  )
}

export default ImageAnimation

const Wrapper = styled.div<{$height: string, $width: string}>`
  height: ${props => props.$height};
  width: ${props => props.$width};
  position: relative;
  
  overflow: hidden;
`

const Content = styled.div<{$height: string, $width: string}>`
  height: ${props => props.$height};
  width: ${props => props.$width};
  position: absolute;
  top: 0;
  overflow: hidden;
`

const ContentWrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  height: 0vw;
`
