import React from "react";
import styled from "styled-components";

import colors from "styles/colors";
import media from "styles/media";

import CreatorsBanners from "components/CreatorsBanners";
import Glows from "assets/images/joinMetaGlows.png";

type props = {
  data: {
    banners: any;
    title: [];
  };
};

const JoinMeta: React.FC<props> = ({ data }) => {
  const title = data.title.map((item: any, index: number) => {
    return <Title key={index}>{item}</Title>;
  });

  return (
    <Wrapper data-scroll-section>
      <CreatorsBanners banners={data.banners} />
      <Creators>{title}</Creators>
    </Wrapper>
  );
};

export default JoinMeta;

const Wrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 10vw;
  padding-bottom: 10vw;

  background: url(${Glows}) center bottom/75% no-repeat;

  ${media.tablet} {
    padding-top: 10vw;
    padding-bottom: 10vw;
  }

  ${media.mobile} {
    padding-top: 40vw;
  }
`;

const Creators = styled.div`
  position: relative;
`;

const Title = styled.h2`
  color: ${colors.offWhite};
  font-family: Sofia Pro;
  font-style: normal;
  font-weight: 700;
  line-height: 90%;
  letter-spacing: -0.06em;
  text-align: center;

  font-size: 13.889vw;
  width: 100vw;

  ${media.tablet} {
    font-size: 14.648vw;
    width: 79.516vw;
  }

  ${media.mobile} {
    font-size: 15.5vw;
    line-height: 100%;
    font-weight: 700;
    width: 86.667vw;
  }

  img {
    object-fit: cover;
    object-position: center center;

    width: 14.236vw;
    height: 8.889vw;
    border-radius: 6.042vw;
    margin-right: 0.778vw;
    margin-left: 0.778vw;

    ${media.tablet} {
      width: 11.719vw;
      height: 7.324vw;
      border-radius: 11.914vw;
      margin-right: 0vw;
      margin-left: 0vw;
    }

    ${media.mobile} {
      width: 15.467vw;
      height: 9.867vw;
      border-radius: 32.533vw;
      margin-right: 0vw;
      margin-left: 0vw;
    }
  }
`;
