import React, { useRef, useContext } from "react";
import styled, { keyframes } from "styled-components";
import gsap from 'gsap'
import { ScreenContext } from "App";

import colors from 'styles/colors'
import media from 'styles/media'
import text from 'styles/text'

import CreatorCard from "components/CreatorCard";
import LiveAuction from 'components/LiveAuction'

import {ReactComponent as TriangleSVG} from 'assets/images/symbols/triangle.svg'
import {ReactComponent as BitskiSVG} from 'assets/svg/bitskiLogomarkRed.svg'
import {ReactComponent as ArrowSVG} from 'assets/images/symbols/arrow.svg'

import Cursor from 'components/EnterDropCursor'

const Hero: React.FC<{}> = () => {
  const screen = useContext(ScreenContext)

  const wrapperRef = useRef<any>(null)

  const mouseEnter = () => {
    if (screen.desktop || screen.fullWidth) {
      const cursor = document.getElementById('enter-drop-cursor')

      gsap.set(cursor, {
        opacity: 1
      })

      wrapperRef!.current!.addEventListener("mousemove", mouseMoveHandler)
    }
  }

  const mouseLeave = () => {
    if (screen.desktop || screen.fullWidth) {
      const cursor = document.getElementById('enter-drop-cursor')

      gsap.set(cursor, {
        opacity: 0
      })
    }
  }

  const mouseMoveHandler = (e: any) => {
    const cursor = document.getElementById('enter-drop-cursor')

    gsap.set(cursor, {
      x: e.clientX - (window.innerWidth * 0.08),
      y: e.clientY - (window.innerWidth * 0.19) + (window.locomotiveScroll.scroll.instance.scroll.y)
    })
  }

  return (
    <Wrapper data-scroll-section id="home-hero">
      <Ticker data-scroll data-scroll-repeat data-scroll-target="#home-hero">
        <TickerContent>

          <Bitski/>
          <TickerText $color={colors.razzmatazz100}>Powered By Bitski</TickerText>

          <BlueTriangle />
          <TickerText>Rudy Willingham + Lil Nas X</TickerText>

          <BlueTriangle />
          <TickerText>FNMeka + RTFKT</TickerText>

          <BlueTriangle />
          <TickerText>Bella Poarch + Bobby Hundreds</TickerText>

          <BlueTriangle />
          <TickerText>Gary Vaynerchuck + Jess Marciante + x0r</TickerText>

          <BlueTriangle />
          <TickerText>Curtis Roach + Coin Artist</TickerText>

          <BlueTriangle />
          <TickerText>Brittany Broski + Grimes</TickerText>

          <Arrow/>
          <TickerText $color={colors.splash100}>Visit the Museum of the Moving Image</TickerText>

          <Bitski/>
          <TickerText $color={colors.razzmatazz100}>Powered By Bitski</TickerText>

          <BlueTriangle />
          <TickerText>Rudy Willingham + Lil Nas X</TickerText>

          <BlueTriangle />
          <TickerText>FNMeka + RTFKT</TickerText>

          <BlueTriangle />
          <TickerText>Bella Poarch + Bobby Hundreds</TickerText>

          <BlueTriangle />
          <TickerText>Gary Vaynerchuck + Jess Marciante + x0r</TickerText>

          <BlueTriangle />
          <TickerText>Curtis Roach + Coin Artist</TickerText>

          <BlueTriangle />
          <TickerText>Brittany Broski + Grimes</TickerText>

          <Arrow/>
          <TickerText $color={colors.splash100}>Visit the Museum of the Moving Image</TickerText>
        </TickerContent>
      </Ticker>
      <TopSection>
        <Span>Choose your drop ↓</Span>
        {(!screen.fullWidth && !screen.desktop) && <AuctionSection>
          {screen.tablet && <LiveAuction/>}
          <Triangle/>
        </AuctionSection>}
      </TopSection>
      <ItemContainer ref={wrapperRef} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
        {(screen.desktop || screen.fullWidth) && <Cursor id="enter-drop-cursor"/>}
        <CreatorCard scrollDuration={5} id="lil-nas-x">
          {(screen.desktop || screen.fullWidth) && <>lil nas x + rudy willingham</>}
          {(screen.tablet || screen.mobile) && "lil nas x\nrudy willingham"}
        </CreatorCard>
        <CreatorCard scrollDuration={3.5} id="fnmeka">
          {(screen.desktop || screen.fullWidth) && <>fnmeka + rtfkt</>}
          {(screen.tablet || screen.mobile) && "fnmeka\nrtfkt"}
        </CreatorCard>
        <CreatorCard scrollDuration={6} id="bella-poarch">
          {(screen.desktop || screen.fullWidth) && <>bella poarch + bobby hundreds</>}
          {(screen.tablet || screen.mobile) && "bella poarch\nbobby hundreds"}
        </CreatorCard>
        <CreatorCard scrollDuration={8.5} id="gary-vee">
          {(screen.desktop || screen.fullWidth) && <>gary vaynerchuk + jess marciante + x0r</>}
          {(screen.tablet || screen.mobile) && "gary vaynerchuk,\njess marciante & x0r"}
        </CreatorCard>
        <CreatorCard scrollDuration={4.85} id="curtis-roach">
          {(screen.desktop || screen.fullWidth) && <>curtis roach + coin artist</>}
          {(screen.tablet || screen.mobile) && "curtis roach\ncoin artist"}
        </CreatorCard>
        <CreatorCard scrollDuration={4.7} id="brittany-broski">
          {(screen.desktop || screen.fullWidth) && <>brittany broski + grimes </>}
          {(screen.tablet || screen.mobile) && "brittany broski\nGrimes"}
        </CreatorCard>
      </ItemContainer>
    </Wrapper>
  );
};

export default Hero;

const Wrapper = styled.section`
  background-color: ${colors.richBlack100};
  display: flex;
  flex-direction: column;

  ${media.desktop} {
    padding-top: 5.694vw;
    padding-left: 3.472vw;
    padding-right: 3.472vw;
  }

  ${media.tablet} {
    padding-top: 9.859vw;
    padding-left: 4.883vw;
    padding-right: 4.883vw;
  }

  ${media.mobile} {
    padding-top: 17.667vw;
    padding-left: 6.667vw;
    padding-right: 6.667vw;
  }
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${media.desktop} {
    margin-bottom: 1.042vw;
  }

  ${media.tablet} {
    margin-bottom: 1.270vw;
  }

  ${media.mobile} {
    margin-bottom: 3.733vw;
  }
`

const Ticker = styled.div`
  position: relative;
  z-index: 20;
  transition: opacity 0.25s ease;
  overflow: hidden;

  margin-bottom: 3.056vw;

  ${media.tablet} {
    margin-bottom: 5.859vw;
  }

  ${media.mobile} {
    margin-bottom: 10.667vw;
  }
`

const ticker = keyframes`
  from { transform: translateX(0) }
  to { transform: translateX(-50%)}
`

const TickerContent = styled.div`
  width: 220.98vw;
  display: flex;
  align-items: center;
  padding: 0.7vw 0;
  animation: ${ticker} 60s linear infinite;

  ${media.tablet} {
    width: 331.2vw;
  }

  ${media.mobile} {
    width: 898.4vw;
  }
`

const TickerText = styled.p<{$color?: string}>`
  ${text.desktop.tickerText}
  line-height: 1;
  color: ${props => props.$color || colors.offWhite};
  margin: 0.2vw 1.4vw 0 0.7vw;

  ${media.tablet} {
    ${text.tablet.tickerText}
    margin-right: 2.93vw;
    margin-left: 0.977vw;
  }

  ${media.mobile} {
    ${text.mobile.tickerText}
    margin-right: 8vw;
    margin-left: 2.667vw;
  }
`

const BlueTriangle = styled(TriangleSVG)`
  width: 1.319vw;
  height: auto;

  ${media.tablet} {
    width: 1.855vw;
  }

  ${media.mobile} {
    width: 5.067vw;
  }
`

const Bitski = styled(BitskiSVG)`
  height: auto;
  width: 1.042vw;
  path {
    fill: ${colors.razzmatazz100};
  }

  ${media.tablet} {
    width: 1.465vw;
  }

  ${media.mobile} {
    width: 3.779vw;
  }
`

const AuctionSection = styled.div`
  display: flex;
  align-items: center;
`

const Span = styled.span`
  color: ${colors.razzmatazz100};

  ${media.desktop} {
    ${text.desktop.buttonText}
  }

  ${media.tablet} {
    ${text.tablet.buttonText}
  }

  ${media.mobile} {
    ${text.mobile.buttonText}
  }
`

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: none;

  ${media.tablet} {
    border-bottom: 1px solid ${colors.offWhite};
  }

  ${media.mobile} {
    border-bottom: 1px solid ${colors.offWhite};
  }
`

const Triangle = styled(TriangleSVG)`
  height: auto;

  ${media.tablet} {
    width: 1.855vw;
    margin-left: 2.344vw;
  }

  ${media.mobile} {
    width: 5.067vw;
  }
`

const Arrow = styled(ArrowSVG)`
  height: auto;
  width: 1.042vw;

  path {
    stroke: ${colors.splash100};
  }

  ${media.tablet} {
    width: 1.221vw;
  }

  ${media.mobile} {
    width: 3.333vw;
  }
`
