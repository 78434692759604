import React from 'react'
import styled from 'styled-components'

import colors from 'styles/colors'
import text from 'styles/text'
import media from 'styles/media'

import { useMedia } from 'utils/Hooks'

type props = {
  auctionData: any
}

const About: React.FC<props> = ({auctionData}) => {

  const collaborators = auctionData.collaborators.map((item: any, index: number) => {
    return (
      <Collaborator key={index}>
        <Img src={item.image} alt={item.name}/>
        <Col>
          <Name>{item.name}</Name>
          <Type>{item.title}</Type>
        </Col>
      </Collaborator>
    )
  })

  return (
    <Wrapper data-scroll-section>
      <Column1 marginRight={useMedia('8.889vw', '8.889vw', '5.859vw', '')}>
        <Title>About this NFT</Title>
        <Text>{auctionData.description}</Text>
        <SubText>*Purchase includes a high-resolution animation of the NFT.</SubText>
      </Column1>
      <Column2>
        <Title>Collaborators</Title>
        {collaborators}
      </Column2>
    </Wrapper>
  )
}

export default About

const Wrapper = styled.section`
  display: flex;
  align-items: flex-start;

  padding-top: 10.417vw;
  padding-left: 11.319vw;
  padding-bottom: 10.417vw;

  ${media.tablet} {
    padding-left: 4.883vw;
    padding-top: 11.523vw;
    padding-bottom: 14.648vw;
  }

  ${media.mobile} {
    padding-top: 10vw;
    padding-left: 6.667vw;
    padding-right: 6.667vw;
    flex-direction: column;
  }
`

const Column = styled.div<{marginRight?: string}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: ${props => props.marginRight || '0px'};
`

const Column1 = styled(Column)`
  ${media.mobile} {
    order: 2;
  }
`

const Column2 = styled(Column)`
  ${media.mobile} {
    order: 1;
    margin-bottom: 16vw;
  }
`

const Title = styled.span`
  color: ${colors.offWhite};
  text-transform: uppercase;

  ${text.desktop.displayH4}
  margin-bottom: 1.042vw;

  ${media.tablet} {
    ${text.tablet.displayH4}
    margin-bottom: 1.465vw;
  }

  ${media.mobile} {
    ${text.mobile.displayH4}
    margin-bottom: 4vw;
  }
`

const Text = styled.p`
  color: ${colors.offWhite};

  ${text.desktop.headerBodyCopy}
  width: 53.75vw;
  margin-bottom: 2.778vw;

  ${media.tablet} {
    ${text.tablet.headerBodyCopy}
    width: 51.367vw;
    margin-bottom: 3.906vw;
  }

  ${media.mobile} {
    ${text.mobile.headerBodyCopy}
    width: 86.667vw;
    margin-bottom: 4vw;
  }
`

const SubText = styled.p`
  color: ${colors.richBlack40};

  ${text.desktop.tickerText}

  ${media.tablet} {
    ${text.tablet.tickerText}
  }

  ${media.mobile} {
    ${text.mobile.tickerText}
  }
`

const Collaborator = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 1.736vw;

  ${media.tablet} {
    margin-bottom: 2.441vw;
  }

  ${media.mobile} {
    margin-bottom: 6.667vw;
  }
`

const Img = styled.img`
  border-radius: 50%;
  object-fit: cover;
  object-position: top center;

  width: 3.333vw;
  height: 3.333vw;
  margin-right: 1.042vw;

  ${media.tablet} {
    width: 4.688vw;
    height: 4.688vw;
    margin-right: 1.465vw;
  }

  ${media.mobile} {
    width: 12.8vw;
    height: 12.8vw;
    margin-right: 4vw;
  }
`

const Name = styled.p`
  color: ${colors.offWhite};
  text-transform: uppercase;

  ${text.desktop.buttonText}
  margin-bottom: 0.278vw;

  ${media.tablet} {
    ${text.tablet.buttonText}
    margin-bottom: 0.391vw;
  }

  ${media.mobile} {
    ${text.mobile.buttonText}
    margin-bottom: 1.067vw;
  }
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
`

const Type = styled.p`
  color: ${colors.richBlack40};
  ${text.desktop.headerBodyCopy2}
  text-transform: capitalize;

  ${media.tablet} {
    ${text.tablet.headerBodyCopy2}
    text-transform: capitalize;
  }

  ${media.mobile} {
    ${text.mobile.headerBodyCopy2}
    text-transform: capitalize;
  }
`
