import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import gsap from "gsap";

import colors from "styles/colors";
import media from "styles/media";

import nft from "assets/nfts/brittanyBroskiNFT.mp4";

type props = {
  uri?: string;
  mimeType?: string;
  right?: boolean;
  eventId?: string
};

const NFTPlayer: React.FC<props> = ({ uri, mimeType, right, eventId }) => {
  const [muted, setMuted] = useState(true);
  const [fullscreen, setFullscreen] = useState(false);

  const playerRef = useRef(null);
  const wrapperRef = useRef(null);
  const expand1Ref = useRef(null);
  const expand2Ref = useRef(null);
  const expand3Ref = useRef(null);
  const expand4Ref = useRef(null);
  const soundRef = useRef(null);
  const replayRef = useRef(null);

  useEffect(() => {
    window.addEventListener(`playerInView-${eventId}-enter`, handleInView)
    window.addEventListener(`playerInView-${eventId}-exit`, handleOutView)

    return () => {
      window.removeEventListener(`playerInView-${eventId}-enter`, handleInView)
      window.removeEventListener(`playerInView-${eventId}-exit`, handleOutView)
    }
  }, [eventId])

  useEffect(() => {
    if (playerRef.current) {
      // playerRef.current.play();
    }
  }, [playerRef]);

  const toggleMute = () => {
    setMuted(!muted);
  };

  const handleRestart = () => {
    playerRef.current.currentTime = 0;
    playerRef.current.play();
  };

  const checkIfFullscreen = () => {
    if (!document.fullscreenElement) {
      setFullscreen(false);
      document.removeEventListener("fullscreenchange", checkIfFullscreen);
    }
  };

  const handleFullscreen = () => {
    // @ts-ignore
    if (fullscreen) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        // @ts-ignore
      } else if (document.webkitExitFullscreen) {
        // @ts-ignore
        document.webkitExitFullscreen();
        // @ts-ignore
      } else if (document.msExitFullscreen) {
        // @ts-ignore
        document.msExitFullscreen();
      }
      document.removeEventListener("fullscreenchange", checkIfFullscreen);
    } else {
      gsap.set(
        [
          expand1Ref.current,
          expand2Ref.current,
          expand3Ref.current,
          expand4Ref.current,
        ],
        {
          duration: 1,
          transform: "rotate(180deg)",
        }
      );

      let elem = wrapperRef.current;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitEnterFullscreen) {
        elem.webkitEnterFullscreen()
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      } else {
        // @ts-ignore
        document.getElementById('nft-video').webkitEnterFullscreen()
      }
      setTimeout(() => {
        document.addEventListener("fullscreenchange", checkIfFullscreen);
      }, 100);
    }
    setFullscreen(!fullscreen);
  };

  const expandMouseEnter = () => {
    const tl = gsap.timeline({
      onComplete: () => {
        tl.kill();
      },
    });

    tl.to(
      expand1Ref.current,
      {
        duration: 0.3,
        transform: "translate(5%, -5%)",
      },
      0
    );
    tl.to(
      expand2Ref.current,
      {
        duration: 0.3,
        transform: "translate(-5%, -5%)",
      },
      0
    );
    tl.to(
      expand3Ref.current,
      {
        duration: 0.3,
        transform: "translate(-5%, 5%)",
      },
      0
    );
    tl.to(
      expand4Ref.current,
      {
        duration: 0.3,
        transform: "translate(5%, 5%)",
      },
      0
    );
    tl.to([expand1Ref.current, expand2Ref.current, expand3Ref.current, expand4Ref.current], {
      duration: 0.3,
      transform: `translate(0%, 0%)`,
    }, 0.3);

    return () => {
      tl.kill();
    };
  };

  const expandSVG = (ref: any) => {
    gsap.to(ref.current, {
      duration: 0.3,
      scale: 1.2,
      onComplete: () => {
        gsap.to(ref.current, {
          duration: 0.3,
          scale: 1
        })
      }
    })
  }

  const handleInView = () => {
    playerRef.current.play()
  }
  
  const handleOutView = () => {
    playerRef.current.pause()
  }

  return (
    <Wrapper ref={wrapperRef} data-scroll data-scroll-repeat data-scroll-call={`playerInView-${eventId}`}>
      {/* {(mimeType && mimeType.includes('image')) && <img style={{objectFit: 'cover'}} src={uri} alt={'nft'}/>}
      {(mimeType && mimeType.includes('video')) && <Video muted={muted} autoPlay ref={playerRef} controls={false} loop playsInline >
        <source src={uri} type={mimeType} />
        no support
      </Video>} */}
      <Video loop playsInline autoPlay muted={muted} ref={playerRef} controls={false} id="nft-video">
        <source src={nft} type={"video/mp4"} />
        no support
      </Video>
      <Controls right={right}>
        <Svg onMouseEnter={() => expandSVG(soundRef)} ref={soundRef} width="24" height="24" viewBox="0 0 24 24" onClick={toggleMute}>
          {muted ? <><path d="M4 8.77778H3V9.77778V14.2222V15.2222H4H7.60813L10.3206 17.7338L12 19.2888V17V7V4.71123L10.3206 6.26624L7.60813 8.77778H4Z" stroke={colors.offWhite} strokeWidth="2"/>
          <path d="M15 8L22.5 16M22.5 8L15 16" stroke={colors.offWhite} strokeWidth="2"/></>
          :
          <><path d="M4 8.77778H3V9.77778V14.2222V15.2222H4H7.60813L10.3206 17.7338L12 19.2888V17V7V4.71123L10.3206 6.26624L7.60813 8.77778H4Z" stroke={colors.offWhite} stroke-width="2"/>
          <path d="M18.07 4.92999C19.9447 6.80527 20.9979 9.34835 20.9979 12C20.9979 14.6516 19.9447 17.1947 18.07 19.07M15.54 8.45999C16.4774 9.39763 17.004 10.6692 17.004 11.995C17.004 13.3208 16.4774 14.5924 15.54 15.53" stroke={colors.offWhite} stroke-width="2" stroke-linecap="square"/></>
        }
        </Svg>
        <Svg onMouseEnter={() => expandSVG(replayRef)} ref={replayRef} width="24" height="24" viewBox="0 0 24 24" onClick={handleRestart}>
          <path
            d="M5.63651 7.3033C2.10098 10.8388 2.10098 16.4957 5.63651 20.0312C9.17205 23.5668 14.8289 23.5668 18.3644 20.0312C22.0593 16.3364 21.9 10.8388 18.3644 7.3033C16.051 4.9899 13.2402 4.40099 10.2327 4.82843M10.2327 4.82843L13.0611 2M10.2327 4.82843L13.0611 7.65685"
            stroke={colors.offWhite}
            strokeWidth="2"
            strokeLinecap="square"
          />
          <path
            d="M10 17V12L14 14.5L10 17Z"
            stroke={colors.offWhite}
            strokeWidth="1.5"
          />
        </Svg>
        {!fullscreen && (
          <Svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            onClick={handleFullscreen}
            onMouseEnter={expandMouseEnter}
          >
            <Path
              ref={expand1Ref}
              d="M 16 3.5 H 20.5 V 8"
              stroke={colors.offWhite}
            />
            <Path
              ref={expand2Ref}
              d="M 8 3.5 H 3.5 V 8"
              stroke={colors.offWhite}
            />
            <Path
              ref={expand3Ref}
              d="M 3.5 16 V 20.5 H 8 "
              stroke={colors.offWhite}
            />
            <Path
              ref={expand4Ref}
              d="M 16 20.5 H 20.5 V 16"
              stroke={colors.offWhite}
            />
          </Svg>
        )}
        {fullscreen && (
          <Svg
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
            onClick={handleFullscreen}
            onMouseEnter={expandMouseEnter}
          >
            <Path
              ref={expand1Ref}
              d="M 16 3.5 V 8 H 20.5"
              stroke={colors.offWhite}
            />
            <Path
              ref={expand2Ref}
              d="M 8 3.5 V 8 H 3.5"
              stroke={colors.offWhite}
            />
            <Path
              ref={expand3Ref}
              d="M 3.5 16 H 8 V 20.5"
              stroke={colors.offWhite}
            />
            <Path
              ref={expand4Ref}
              d="M 16 20.5 V 16 H 20.5"
              stroke={colors.offWhite}
            />
          </Svg>
        )}
      </Controls>
    </Wrapper>
  );
};

export default NFTPlayer;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  color: white;
  width: 100%;
  height: 100%;
`;

const Video = styled.video`
  color: white;
  width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const Controls = styled.span<{ right?: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: ${(props) => (props.right ? "column" : "flex")};
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${colors.offWhite};

  left: ${(props) => (props.right ? "unset" : "50%")};
  right: ${(props) => (props.right ? "4.883vw" : "unset")};
  transform: ${(props) =>
    props.right ? "translateY(50%)" : "translateX(-50%)"};
  width: ${(props) => (props.right ? "3.472vw" : "11.25vw")};
  height: ${(props) => (props.right ? "11.25vw" : "3.472vw")};
  bottom: ${(props) => (props.right ? "50%" : "1.042vw")};
  border-radius: 3.472vw;
  padding-left: 1.389vw;
  padding-right: 1.389vw;
  padding-top: 0.903vw;
  padding-bottom: 0.903vw;

  ${media.tablet} {
    height: ${(props) => (props.right ? "17.774vw" : "6.249vw")};
    width: ${(props) => (props.right ? "6.249vw" : "17.774vw")};
    padding: 1.953vw;
    border-radius: 4.883vw;
  }

  ${media.mobile} {
    height: 13.333vw;
    width: 45.867vw;
    padding: 3.467vw 5.333vw;
    border-radius: 13.333vw;
    bottom: 2.667vw;
  }
`;

const Svg = styled.svg`
  height: auto;
  cursor: pointer;
  width: 1.667vw;
  path {
    fill: none;
  }

  ${media.tablet} {
    width: 2.344vw;
  }

  ${media.mobile} {
    width: 6.4vw;
  }
`;

const Path = styled.path`
  transform-origin: 12px 12px !important;
  stroke-width: 0.139vw;
  ${media.tablet} {
    stroke-width: 0.3vw;
  }
  ${media.mobile} {
    stroke-width: 0.5vw;
  }
`;
