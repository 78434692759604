import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { BigNumber } from '@ethersproject/bignumber'
import { formatUnits } from '@ethersproject/units'

import colors from 'styles/colors'
import media from 'styles/media'

import CrownSVG from 'assets/svg/crown.svg'
import CrownMobileSVG from 'assets/svg/crown-mobile.svg'

type bidProps = {
  bidder: string,
  username: string,
  createdAt: string
  amount: string,
  ethPrice: number
}

const Bid: React.FC<bidProps> = ({username, bidder, createdAt, amount, ethPrice}) => {

  const [usd, setUsd] = useState("")
  const [eth] = useState(parseFloat(formatUnits(BigNumber.from(amount), "ether")).toFixed(3))

  useEffect(() => {
    if (ethPrice) {

      let bidAmount = BigNumber.from(amount)
      let ethPriceBN = BigNumber.from(Math.floor(ethPrice))
      let usd = bidAmount.mul(ethPriceBN)
      let usdString = formatUnits(usd, "ether")

      setUsd(parseFloat(usdString).toFixed(2))
    }
  }, [ethPrice, amount])

  const determineTime = (): string => {
    const currentTime = new Date()
    const bidTime = new Date(createdAt)
    const diffTime = currentTime.getTime() - bidTime.getTime()

    if (diffTime < 1000) {
      return `${diffTime}ms`
    } else if (diffTime / (1000) < 60) {
      return `${Math.floor(diffTime / (1000))}s`
    } else if (diffTime / (1000*60) < 60) {
      return `${Math.floor(diffTime / (1000*60))}m`
    } else if (diffTime / (1000*60*60) < 24) {
      return `${Math.floor(diffTime / (1000*60*60))}hr`
    } else {
      return `${Math.floor(diffTime / (1000*60*60*24))}d`
    }
  }

  const time = determineTime()
  const avatar = ""

  return (
    <BidWrapper>
      {avatar && <Avatar src={avatar} alt={username || bidder}/>}
      {!avatar && <DefaultAvatar/>}
      <Row>
        <Column>
          <Name>{username || bidder}</Name>
          <Time>{time}&nbsp;ago</Time>
        </Column>
        <Column2>
          <ETHAmount>{eth} {'ETH //'}</ETHAmount>
          <USDAmount>&nbsp;{usd}&nbsp;USD</USDAmount>
        </Column2>
      </Row>
    </BidWrapper>
  )
}

export default Bid

const BidWrapper = styled.div`
  display: flex;
  align-items: center;
  border-top: 0.5px solid ${colors.offWhite};
  
  height: 3.125vw;
  width: 35.417vw;

  ${media.tablet} {
    height: 4.395vw;
    width: 49.805vw;
  }

  ${media.mobile} {
    height: 22.667vw;
    width: 100%;
  }

  :first-of-type {
    border-top: none;
    position: relative;

    ::before {
      content: url(${CrownSVG});
      position: absolute;

      left: -2vw;
      width: 1.181vw;
      height: 0.903vw;

      ${media.tablet} {
        height: 1.27vw;
        width: 1.66vw;
        left: -2.5vw;
        top: 1.2vw;
      }

      ${media.mobile} {
        content: url(${CrownMobileSVG});
        width: 5.333vw;
        height: 5.333vw;
        left: 0vw;
        top: 2.667vw;
      }
    }

    p {
      font-weight: 900;
      line-height: 100%;
    }
  }
`

const Avatar = styled.img`
  border-radius: 50%;
  border: 0.5px solid ${colors.offWhite};
  background-color: ${colors.razzmatazz100};

  width: 1.667vw;
  height: 1.667vw;
  margin-right: 0.556vw;

  ${media.tablet} {
    width: 2.344vw;
    height: 2.344vw;
    margin-right: 0.781vw;
  }

  ${media.mobile} {
    width: 12vw;
    height: 12vw;
    margin-right: 3.2vw;
  }
`

const DefaultAvatar = styled.span`
  border-radius: 50%;
  border: 0.5px solid ${colors.offWhite};
  background-color: ${colors.splash100};
  box-sizing: border-box;

  width: 100%;
  height: 1.667vw;
  margin-right: 0.556vw;

  ${media.tablet} {
    width: 100%;
    height: 2.344vw;
    margin-right: 0.781vw;
  }

  ${media.mobile} {
    width: 12vw;
    height: 12vw;
    margin-right: 3.2vw;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 71.467vw;
`

const Column = styled.div`
  display: flex;

  ${media.mobile} {
    flex-direction: column;
  }
`

const Column2 = styled(Column)`
  flex-wrap: wrap;
  width: 12.153vw;
  justify-content: flex-end;

  ${media.tablet} {
    width: 17.09vw;
  }

  ${media.mobile} {
    width: 33.867vw;
  }
`

const Font = `
  font-family: Sofia Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 1.042vw;
  line-height: 90%;
  letter-spacing: -0.02em;

  ${media.tablet} {
    font-size: 1.465vw;
  }

  ${media.mobile} {
    font-size: 4vw;
  }
`

const Name = styled.p`
  color: ${colors.offWhite};
  text-overflow: ellipsis;
  overflow: hidden;
  ${Font}

  width: 8.819vw;
  margin-right: 5.556vw;

  ${media.tablet} {
    width: 12.402vw;
    margin-right: 7.813vw;
  }

  ${media.mobile} {
    width: 33.867vw;
    margin-right: 0vw;
    margin-bottom: 2.667vw;
  }
`

const Time = styled.p`
  ${Font}
  color: ${colors.offWhite};

  width: 3.889vw;
  margin-right: 2.778vw;
  
  ${media.tablet} {
    width: 5.273vw;
    margin-right: 4.102vw;
  }

  ${media.mobile} {
    width: 33.867vw;
    margin-right: 0vw;
    font-weight: normal !important;
  }
`

const ETHAmount = styled.p`
  ${Font}
  color: ${colors.offWhite};
  text-align: right;

  ${media.mobile} {
    margin-bottom: 2.667vw;
  }
`

const USDAmount = styled.p`
  ${Font}
  color: ${colors.offWhite};
  text-align: right;


  ${media.mobile} {
    font-weight: normal !important;
  }
`
