import React, {useRef, useEffect} from 'react'
import styled from 'styled-components'
import gsap from 'gsap'

import media from 'styles/media'
import text from 'styles/text'
import colors from 'styles/colors'

import { ReactComponent as circleSVG } from 'assets/images/symbols/circle.svg'

const LiveAuction: React.FC = () => {
  const circleRef = useRef(null)
  const circle2Ref = useRef(null)

  useEffect(() => {
    const circTl = gsap.timeline({
      repeat: -1,
    })

    circTl.to(circleRef.current, {
      duration: 2,
      left: "0%",
      ease: 'none'
    }, 0)
    circTl.to(circle2Ref.current, {
      duration: 2,
      left: "-200%",
      ease: 'none'
    }, 0)

    return () => {
      circTl.kill()
    }
  }, [])

  return (
    <Auction>
      <CircleContainer>
        <Circle ref={circleRef}/>
        <Circle2 ref={circle2Ref}/>
      </CircleContainer>
      <P>Live Auction</P>
    </Auction>
  )
}

export default LiveAuction


const Auction = styled.span`
  display: flex;
  align-items: center;

  ${media.desktop} {
    height: 0.903vw;
    
  }
`

const CircleContainer = styled.div`
  overflow: hidden;
  position: relative;
  
  ${media.desktop} {
    height: 0.694vw;
    width: 0.694vw;
    margin-right: 0.694vw;
  }

  ${media.tablet} {
    height: 1.270vw;
    width: 1.270vw;
    margin-right: 0.977vw;
  }
`

const CircleStyles = `
  position: absolute;
  height: auto;
  top: 0;
  margin: 0vw;

  ${media.desktop} {
    width: 0.694vw;
  }

  ${media.tablet} {
    width: 1.270vw; 
  }
`

const Circle = styled(circleSVG)`
  ${CircleStyles}
  left: 200%;
`

const Circle2 = styled(circleSVG)`
  ${CircleStyles}
  left: 0;
`

const P = styled.p`
  color: ${colors.razzmatazz100};
  margin: 0;
  font-weight: 400;

  ${media.desktop} {
    ${text.desktop.displayH5}
  }

  ${media.tablet} {
    ${text.tablet.displayH5}
  }
`
