import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { ScreenContext } from 'App'

import media from 'styles/media'

import NFTPlayer from 'components/NFTPlayer'
import BidsContainer from './BidsContainer'
import Auction from './Auction'

type props = {
  productId: number
  wrapperId: string
}

const Hero: React.FC<props> = ({productId, wrapperId}) => {
  const screen = useContext(ScreenContext)

  const [productData, setProductData] = useState(null)
  const [ethPrice, setEthPrice] = useState(0)

  return (
    <Wrapper data-scroll-section id={wrapperId + "-0"}>
      <Left>
        <NFTPlayer eventId={wrapperId + "-0"} right={screen.tablet} uri={''} mimeType={"image/jpg"}/>
      </Left>
      <Right>
        <Auction
          productData={productData}
          ethPrice={ethPrice}
        />
        <BidsContainer
          productData={productData}
          ethPrice={ethPrice}
        />
      </Right>
    </Wrapper>
  );
};

export default Hero;

const Wrapper = styled.section`
  display: flex;
  align-items: center;
  height: auto;

  padding-top: 6.042vw;

  ${media.tablet} {
    flex-direction: column;
  }

  ${media.mobile} {
    flex-direction: column;
  }
`;

const Left = styled.div`
  width: 48.681vw;
  height: 48.681vw;

  ${media.tablet} {
    width: 100vw;
    height: 100vw;
  }

  ${media.mobile} {
    width: 100vw;
    height: 100vw;
  }
`;

const Right = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  width: 51.319vw;
  height: 100%;
  padding-left: 7.917vw;
  padding-right: 7.917vw;
  padding-top: 2.083vw;

  ${media.tablet} {
    width: 100vw;
    height: 39.746vw;
    padding-top: 4.883vw;
    padding-left: 4.883vw;
    padding-right: 0vw;
    flex-direction: row;
  }

  ${media.mobile} {
    flex-direction: column;
    width: 100vw;
    height: auto;
    padding-top: 13.333vw;
    padding-left: 6.667vw;
    padding-right: 6.667vw;
  }
`;


