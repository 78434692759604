import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import gsap from "gsap";
import colors from "styles/colors";
import media from "styles/media";
import text from "styles/text";
import PrimaryButton from "components/PrimaryButton";
import tikTok from "assets/images/exhibit/tikTokLogo.svg";
import sarah from "assets/images/exhibit/sarahUllman.png";
import radical from "assets/images/exhibit/radicalMedia.png";
import TextAnimation from "components/TextAnimation";
import { useMedia } from "../../utils/Hooks";

const Museum: React.FC<{}> = () => {
  const [triggerBody, setTriggerBody] = useState(false);
  const [triggerTitle, setTriggerTitle] = useState(false);
  const [triggerButton, setTriggerButton] = useState(false);
  const [rowTriggers, setRowTriggers] = useState([false, false, false]);

  const [triggerContact, setTriggerContact] = useState(false);
  const id = "museum_facts";
  const containerRef = useRef(null);

  const info = [
    {
      text: "Organized by guest curator and exhibition producer Sarah Ullman",
      image: sarah,
      alt: "Sarah Ullman",
      width: useMedia("8.3vw", "8.3vw", "8.79vw", "14.4vw"),
      height: useMedia("5.6vw", "5.6vw", "5.86vw", "14.4vw"),
      rowHeight: useMedia("9vw", "9vw", "6.836vw", "14.4vw"),
    },
    {
      text: "Exhibition identity designed by",
      image: radical,
      alt: "Radical Media",
      width: useMedia("16.9vw", "16.9vw", "15.82vw", "14.4vw"),
      height: useMedia("2.1vw", "2.1vw", "1.95vw", "14.4vw"),
      rowHeight: useMedia("6.5vw", "6.5vw", "6.836vw", "14.4vw"),
    },
    {
      text: "Presented in association with",
      image: tikTok,
      alt: "tiktok",
      width: useMedia("12.9vw", "12.9vw", "14.06vw", "14.4vw"),
      height: useMedia("3.1vw", "3.1vw", "3.42vw", "14.4vw"),
      rowHeight: useMedia("6.5vw", "6.5vw", "6.836vw", "14.4vw"),
    },
  ];

  const content = info.map((item, i) => {
    return (
      <Row1
        key={`museum_content${i}`}
        imageWidth={item.width}
        imageHeight={item.height}
        className="museum_first-step"
      >
        <TextAnimation
          textArray={[
            () => (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p>{item.text}</p> <img src={item.image} alt={item.alt} />
              </div>
            ),
          ]}
          className={`${id}row-${i}`}
          height={item.rowHeight}
          trigger={rowTriggers[i]}
        />
      </Row1>
    );
  });

  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        start: "top-=650 top",
        scroller: ".smooth-scroll",
      },
    });
    tl.to(".museum_first-step", { stagger: 0.1, opacity: 1, duration: 0.5 }, 0);

    tl.call(setTriggerTitle, [true], 0.2);
    tl.call(setTriggerBody, [true], 0.5);
    tl.call(setTriggerContact, [true], 0.7);
    tl.call(setRowTriggers, [[true, false, false]], 0.7);
    tl.call(setRowTriggers, [[true, true, false]], 0.9);
    tl.call(setRowTriggers, [[true, true, true]], 1.1);
    tl.call(setTriggerButton, [true], 1.3);

    return () => {
      tl.kill();
    };
  }, []);

  return (
    <Wrapper data-scroll data-scroll-section ref={containerRef}>
      <Column className="museum_first-step">
        <Title>
          <Title>
            <TextAnimation
              textArray={[() => "The Museum of the Moving Image"]}
              className={id + "-title"}
              height={useMedia("9.7vw", "9.7vw", "9.38vw", "14.4vw")}
              trigger={triggerTitle}
            />
          </Title>
        </Title>
        <Body>
          <TextAnimation
            textArray={[
              () =>
                `Museum of the Moving Image advances the understanding, enjoyment, and appreciation of the art, history, technique, and technology of film, television, and digital media by presenting exhibitions, education programs, significant moving-image works, and interpretive programs, and collecting and preserving moving-image related artifacts.`,
            ]}
            className={id + "-body"}
            height={useMedia("12.1vw", "12.1vw", "21.09vw", "16vw")}
            trigger={triggerBody}
          />
        </Body>
        <Row>
          <TextAnimation
            textArray={[
              () => (
                <ContactWrapper>
                  <Contact
                    href="https://www.google.com/maps/place/Museum+of+the+Moving+Image/@40.7563494,-73.9261383,17z/data=!3m1!4b1!4m5!3m4!1s0x89c25f2550f21f03:0x58db958504446c43!8m2!3d40.7563454!4d-73.9239496"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    36-01 35 Ave, Astoria, NY 11106 ↗
                  </Contact>{" "}
                  <Contact>718.777.6888 ↗</Contact>{" "}
                </ContactWrapper>
              ),
            ]}
            className={id + "-contact"}
            height={useMedia("1.7vw", "1.7vw", "5.76vw", "16vw")}
            trigger={triggerContact}
          />
        </Row>
        <ButtonWrapper>
          <TextAnimation
            textArray={[
              () => (
                <PrimaryButton onClick={() => false}>Get Tickets</PrimaryButton>
              ),
            ]}
            className={id + "-button"}
            height={useMedia("4.444vw", "4.444vw", "5.86vw", "16vw")}
            trigger={triggerButton}
          />
        </ButtonWrapper>
      </Column>
      <Column className="museum_first-step">{content}</Column>
    </Wrapper>
  );
};

export default Museum;

const Wrapper = styled.section`
  background-color: ${colors.richBlack100};
  margin-bottom: 6.9vw;
  margin-top: 20.8vw;
  display: flex;
  justify-content: space-between;
  ${media.desktop} {
    padding-top: 7.8vw;
    padding-left: 3.5vw;
    padding-right: 3.5vw;
  }

  ${media.tablet} {
    padding-top: 9.9vw;
    padding-left: 4.88vw;
    padding-right: 4.88vw;
  }

  ${media.mobile} {
    padding-top: 17.7vw;
    padding-left: 6.7vw;
    padding-right: 6.7vw;
  }
`;

const Row = styled.div`
  display: flex;
  height: 1.7vw;
  margin-bottom: 5.2vw;
  ${media.tablet} {
    height: 8.69vw;
    margin-bottom: 4.88vw;
  }
  ${media.mobile} {
  }
  ${media.fullWidth} {
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 44vw;
  padding: 1.8vw 0 0 0;
  opacity: 0;
  :first-of-type {
    padding-top: 2.6vw;
  }

  border-top: 1px solid ${colors.offWhite};
  ${media.tablet} {
    width: 44.43vw;
    padding-top: 2.44vw;
    :first-of-type {
      padding-top: 3.71vw;
      ${Row} {
        margin-bottom: 2.86vw;
      }
    }
  }
  ${media.mobile} {
  }
  ${media.fullWidth} {
  }
`;

const ContactWrapper = styled.div`
  display: flex;
  height: 1.7vw;
  margin-bottom: 5.2vw;
  ${media.tablet} {
    height: 5.86vw;
    margin-bottom: 4.88vw;
    flex-direction: column;
  }
  ${media.mobile} {
  }
  ${media.fullWidth} {
  }
`;

const Row1 = styled(Row)<{ imageWidth: string; imageHeight: string }>`
  justify-content: space-between;
  margin-bottom: 3.5vw;
  padding-top: 1.8vw;
  border-top: 1px solid ${colors.offWhite};
  opacity: 0;
  height: auto;
  p {
    width: 21.5vw;
    ${text.desktop.headerH4};
    color: ${colors.richBlack80};
    transition: 0.4s;
    letter-spacing: -0.06em;
  }

  img {
    width: ${(props) => props.imageWidth};
    height: ${(props) => props.imageHeight};
  }
  span {
    display: flex;
  }

  ${media.hover} {
    :hover p {
      color: ${colors.offWhite};
      transition: 0.4s;
    }
  }
  :first-of-type {
    border: none;
    padding-top: 0;
  }

  ${media.tablet} {
    p {
      width: 22.5vw;
      ${text.tablet.headerH4};
      font-size: 2.34vw;

      letter-spacing: -0.06em;
    }
  }
  ${media.mobile} {
  }
  ${media.fullWidth} {
  }
`;

const Title = styled.div`
  ${text.desktop.headerH2};
  width: 41.7vw;
  color: ${colors.offWhite};
  span {
    display: block;
  }
  ${media.tablet} {
    ${text.desktop.headerH3};
    font-size: 4.69vw;
    width: 36.13vw;
  }
  ${media.mobile} {
  }
  ${media.fullWidth} {
  }
`;

const Body = styled.div`
  ${text.desktop.headerBodyCopy};
  color: ${colors.offWhite};
  width: 38.2vw;
  margin: 1.7vw 0;
  position: relative;
  height: 12.1vw;
  span {
    display: block;
  }
  ${media.tablet} {
    ${text.tablet.headerBodyCopy2};
    font-size: 1.56vw;
    width: 36.13vw;
    height: 21.09vw;
  }
  ${media.mobile} {
  }
  ${media.fullWidth} {
  }
`;

const Contact = styled.a`
  text-decoration: none;
  ${text.desktop.buttonText};
  color: ${colors.razzmatazz100};
  /* span {
    display: block;
  } */
  :first-of-type {
    margin-right: 4.2vw;
  }

  ${media.tablet} {
    ${text.tablet.buttonText};
    height: 5.76vw;
    :first-of-type {
      margin-right: 0;
      margin-bottom: 1.95vw;
    }
  }
  ${media.mobile} {
  }
  ${media.fullWidth} {
  }
`;

const ButtonWrapper = styled.div`
  width: 20.6vw;
  height: 4.2vw;
  span {
    display: block;
  }
  ${media.tablet} {
    width: 28.91vw;
    height: 5.86vw;
  }
  ${media.mobile} {
  }
  ${media.fullWidth} {
  }
`;
