import React from 'react'

import Hero from 'sections/drop/hero/Hero'
import NFTView from 'sections/drop/NFTView'
import BannerSection from 'sections/drop/BannerSection'
import About from 'sections/drop/About'
import Drops from 'sections/drop/Drops'
import CollaboratorCarousel from 'sections/drop/CollaboratorCarousel'
import JoinMeta from 'sections/drop/JoinMeta'


type props = {
  data: any
}

const Drop: React.FC<props> = ({data}) => {

  const drops = data.drops.nfts.map((item: any, index: number) => {

    const left = index % 2 === 0

    if (index > 0) {
      return (
        <React.Fragment key={index}>
          <BannerSection
            key={index + 'bannersection'}
            left={left}
            text={item.bannerText}
          />
          <NFTView
            wrapperId={`${data.id}-${index}`}
            key={index + 'nftview'}
            left={left}
            title={item.title}
            price={item.price}
            description={item.description}
            uri={item.uri}
            mimeType={item.mimeType}
            link={item.link}
            state={item.state}
          />
        </React.Fragment>
      )
    } else return null
  })

  return (
    <>
      <Hero
        wrapperId={data.id}
        productId={data.auction.productId}
      />
      <About auctionData={data.auction}/>
      <Drops data={data.drops} dropId={data.id}/>
      <JoinMeta data={data.meta}/>
      {drops}
      <CollaboratorCarousel />
    </>
  )
}

export default Drop

