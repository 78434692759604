import React, { useContext, useMemo, useState } from 'react'
import { ScreenContext } from 'App'
import styled from 'styled-components'

import Bid from './Bid'

import media from 'styles/media'
import colors from 'styles/colors'
import text from 'styles/text'

import { useMedia } from 'utils/Hooks'

interface props {
  productData: any
  ethPrice: number
}

const BidsContainer: React.FC<props> = ({productData, ethPrice}) => {

  const screen = useContext(ScreenContext)

  const [open, setOpen] = useState(false)

  const handleSeeMore = () => {
    setOpen(!open)
  }

  const bidMouseEnter = () => {
    window.locomotiveScroll.stop();
  };

  const bidMouseLeave = () => {
    window.locomotiveScroll.start();
  };

  const bidEls = useMemo(() => {
    return productData?.product.auctions[0].info.bids.map((bid: any) => {

      return <Bid
        key={bid.transactionHash}
        username={bid.username}
        bidder={bid.bidder}
        createdAt={bid.createdAt}
        amount={bid.amount}
        ethPrice={ethPrice}
      />
    })
  }, [productData, ethPrice])

  const timeWidth = useMedia("6.667vw", "6.667vw", "3.906vw", "");

  return (
    <>
      <BidContainer onMouseLeave={bidMouseLeave} onMouseEnter={bidMouseEnter}>
        <BidHeader>
          <HeaderTitle
            $width={useMedia("16.597vw", "16.597vw", "23.34vw", "")}
          >
            user:
          </HeaderTitle>
          {!screen.mobile && (
            <HeaderTitle $width={timeWidth}>Time:</HeaderTitle>
          )}
          <HeaderTitle
            $width={useMedia("12.153vw", "12.153vw", "22.559vw", "")}
            $textAlign="right"
          >
            Bid Amount:
          </HeaderTitle>
        </BidHeader>
        <Bids open={open}>
          {(!bidEls || !bidEls.length) && <NoBids>NO CURRENT BIDS</NoBids>}
          {bidEls}
        </Bids>
        {!screen.mobile && <BidsGradient />}
      </BidContainer>
      {screen.mobile && bidEls && bidEls.length > 3 && <SeeMore onClick={handleSeeMore}>
        {open ? "See Less" : "See More"}
      </SeeMore>}
    </>
  )
}

export default BidsContainer

const BidContainer = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;
  ${media.mobile} {
    z-index: -1;
  }
`;

const BidHeader = styled.div`
  display: flex;
  align-items: flex-start;
  border-bottom: 2px solid ${colors.offWhite};

  height: 1.458vw;

  ${media.tablet} {
    height: 2.051vw;
    width: 49.805vw;
  }

  ${media.mobile} {
    height: 5.6vw;
    width: 100%;
    justify-content: space-between;
  }
`;

const HeaderTitle = styled.p<{ $width: string; $textAlign?: string }>`
  color: ${colors.richBlack40};
  text-transform: uppercase;
  font-family: proxima-nova;
  font-style: normal;
  font-weight: bold;
  line-height: 90%;
  letter-spacing: 0.05em;
  width: ${(props) => props.$width};
  text-align: ${(props) => props.$textAlign || "left"};

  font-size: 0.833vw;

  ${media.tablet} {
    font-size: 1.172vw;
  }

  ${media.mobile} {
    font-size: 3.2vw;
  }
`;

const Bids = styled.div<{ open: boolean }>`
  overflow: auto;
  overflow-x: hidden;
  position: relative;
  box-sizing: border-box;

  height: 15.764vw;
  padding-left: 2.569vw;
  width: 40.2vw;
  left: -2.569vw;

  ${media.tablet} {
    width: 55.273vw;
    height: 30.762vw;
    padding-left: 2.637vw;
    overflow: auto;
  }

  ${media.mobile} {
    width: 86.667vw;
    height: ${(props) => (props.open ? "202vw" : "113.3vw")};
    padding-left: 0vw;
    left: unset;
    overflow: hidden;
    z-index: -1;
  }

  ::-webkit-scrollbar {
    position: absolute;
    left: 100%;
    width: 0.347vw;
  }

  ::-webkit-scrollbar-track {
    background: ${colors.richBlack80};
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.offWhite};
  }
`;

const BidsGradient = styled.div`
  position: absolute;
  pointer-events: none;
  bottom: 0;
  right: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) -31.4%, #000000 100%);
  width: 35.5vw;
  height: 3.2vw;
  ${media.tablet} {
    width: 56vw;
    height: 6vw;
  }

  ${media.mobile} {
    display: none;
  }
`;

const SeeMore = styled.button`
  ${media.mobile} {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    border: none;
    outline: none;
    padding: 5.9vw 22.4vw;
    color: ${colors.offWhite};
    z-index: 10;
  }
`;

const NoBids = styled.p`
  color: ${colors.offWhite};
  align-self: center;
  text-align: center;
  justify-self: center;

  ${text.desktop.displayH4}
  margin-top: 6.806vw;

  ${media.tablet} {
    ${text.tablet.displayH4}
    margin-top: 15.039vw;
  }

  ${media.mobile} {
    ${text.mobile.displayH4}
    margin-top: 30.667vw;
  }
`
