import React, { useState, useRef, useEffect, useContext } from 'react'
import { ScreenContext } from 'App'
import styled from 'styled-components'
import gsap from 'gsap'

import { AuthenticationStatus, AuthenticationErrorCode  } from 'bitski'

import { ethers, providers } from 'ethers'
import { parseEther } from '@ethersproject/units'
import { AuctionHouse } from '@zoralabs/zdk'
import { utils } from 'ethers'

import colors from 'styles/colors'
import text from 'styles/text'
import media from 'styles/media'

import SecondaryButton from 'components/SecondaryButton'

import { ReactComponent as DotSVG} from 'assets/svg/redDot.svg'
import { ReactComponent as StopSVG} from 'assets/svg/stop.svg'
import { ReactComponent as ETHSVG} from 'assets/svg/ETH_icon.svg'

import { useMedia } from 'utils/Hooks'

interface props {
  productData: any
  ethPrice: number
}

const Auction: React.FC<props> = ({productData, ethPrice}) => {

  const [expired, setExpired] = useState(false)
  const [usdAmount, setUsdAmount] = useState('0.00')
  const [auctionText, setAuctionText] = useState('')
  const [seconds, setSeconds] = useState(0)
  const [error, setError] = useState("")
  const [bidAmount, setBidAmount] = useState('')

  const inputRowRef = useRef(null)
  const submitButtonRef = useRef(null)
  const bitskiButtonRef = useRef(null)
  const metamaskButtonRef = useRef(null)
  const usdRef = useRef(null)
  const secondsInterval = useRef(null)
  const countdown = useRef(0)

  const screen = useContext(ScreenContext)

  useEffect(() => {
    return () => {
      if (secondsInterval.current) {
        clearInterval(secondsInterval.current)
      }
    }
  }, [])




  useEffect(() => {
    if (seconds) {
      countdown.current = seconds
      secondsInterval.current = setInterval(() => {
        countdown.current -= 1

        let h = padNumber(Math.floor(countdown.current / (3600)));
        let m = padNumber(Math.floor(countdown.current % 3600 / 60));
        let s = padNumber(Math.floor(countdown.current % 3600 % 60));

        let text = `${h}:${m}:${s} ${screen.mobile ? '' : ' remaining'}`
        setAuctionText(text)
      }, 1000)
    }
  }, [seconds, screen.mobile])

  const padNumber = (num: number): string => {
    if (num < 10) {
      return `0${num}`
    } else {
      return num.toString()
    }
  }

  const handleInputChange = (e: any) => {
    let { value } = e.target

    setBidAmount(value)
    setError("")
    setUsdAmount((Number(value) * ethPrice).toFixed(2))
  }

  return (
    <NFTInfo>
      <AuctionState>
        {expired && <Stop/>}
        {!expired && <Dot/>}
        Live Auction // {auctionText}
      </AuctionState>
      <Title>LOREM IPSUM</Title>
      <ButtonRow>
        <InputRow ref={inputRowRef}>
          <ETHContainer>
            <ETH />
          </ETHContainer>
          <Input type="number" step="any" value={bidAmount} placeholder="0.00 ETH" onChange={handleInputChange}/>
        </InputRow>
        <SubmitButtonWrapper ref={submitButtonRef}>
          <SecondaryButton disabled={expired} width={useMedia('', '', '100%', '100%')} >submit bid</SecondaryButton>
        </SubmitButtonWrapper>
        <ButtonWrapper ref={bitskiButtonRef}>
          <SecondaryButton width={useMedia('17.014vw', '17.014vw', '100%', '100%')} >bid with Bitski</SecondaryButton>
        </ButtonWrapper>
        <ButtonWrapper ref={metamaskButtonRef}>
          <SecondaryButton width={useMedia('17.014vw', '17.014vw', '100%', '100%')} >bid with metamask</SecondaryButton>
        </ButtonWrapper>
      </ButtonRow>
      {!error && <USD ref={usdRef}>${usdAmount} USD</USD>}
      {error && <Error>{error}</Error>}
    </NFTInfo>
  )
}

export default Auction

const NFTInfo = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  ${media.tablet} {
    width: 32.91vw;
    margin-right: 6.9vw;
  }

  ${media.mobile} {
    width: 86.667vw;
  }
`;

const AuctionState = styled.span`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: ${colors.razzmatazz100};

  ${text.desktop.displayH5}
  margin-bottom: 1.389vw;

  ${media.tablet} {
    ${text.tablet.displayH5}
    margin-bottom: 1.953vw;
    font-size: 1.3vw;
  }

  ${media.mobile} {
    ${text.mobile.displayH5}
    margin-bottom: 5.333vw;
    font-size: 4vw;
  }
`;

const AuctionSymbol = `
  height: auto;

  width: 0.694vw;
  margin-right: 0.694vw;

  ${media.tablet} {
    width: 0.977vw;
    margin-right: 0.977vw;
  }

  ${media.mobile} {
    width: 2.667vw;
    margin-right: 2.667vw;
  }
`

const Dot = styled(DotSVG)`
  ${AuctionSymbol}
`;

const Stop = styled(StopSVG)`
  ${AuctionSymbol}
`

const Title = styled.h1`
  color: ${colors.offWhite};

  ${text.desktop.headerH2};
  margin-bottom: 4.167vw;

  ${media.tablet} {
    ${text.tablet.headerH3};
    margin-bottom: 3.906vw;
  }

  ${media.mobile} {
    ${text.mobile.headerH3}
    margin-bottom: 8vw;
    font-size: 14.4vw;
  }
`;

const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 6.806vw;

  ${media.tablet} {
    flex-direction: column;
    width: 100%;
    margin-bottom: 0vw;
    #bitski-button {
      width: 100%;
    }
  }

  ${media.mobile} {
    flex-direction: column;
    width: 100%;
    margin-bottom: 13.333vw;
    #bitski-button {
      width: 100%;
    }
  }
`;

const InputRow = styled.span`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border: 1px solid ${colors.razzmatazz100};
  border-radius: 0px;
  outline: none;

  height: 4.514vw;
  width: 19.722vw;
  background-color: #26070d;

  ${media.tablet} {
    width: 100%;
    margin-bottom: 5.664vw;
    height: 6.348vw;
  }

  ${media.mobile} {
    width: 100%;
    margin-bottom: 11.467vw;
    height: 17.333vw;
  }
`;

const ETHContainer = styled.span`
  height: 100%;

  width: 3.611vw;
  padding-top: 0.764vw;
  padding-bottom: 0.764vw;
  padding-left: 0.694vw;
  padding-right: 0.694vw;

  ${media.tablet} {
    width: 5.078vw;
    padding-top: 1.172vw;
    padding-bottom: 1.074vw;
    padding-left: 0.977vw;
    padding-right: 0.977vw;
  }

  ${media.mobile} {
    width: 13.867vw;
    padding-top: 3.2vw;
    padding-bottom: 2.933vw;
    padding-left: 2.667vw;
    padding-right: 2.667vw;
  }
`;

const ETH = styled(ETHSVG)`
  height: auto;

  width: 2.222vw;

  ${media.tablet} {
    width: 3.125vw;
  }

  ${media.mobile} {
    width: 8.533vw;
  }
`;

const Input = styled.input`
  background: transparent;
  border: none;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-left: 1px solid ${colors.razzmatazz100};
  color: ${colors.offWhite};
  outline: none;
  border-radius: 0px;

  ::placeholder {
    color: ${colors.offWhite};
  }
  
  -moz-appearance: textfield;
  ::-webkit-inner-spin-button, ::-webkit-outer-spin-button{
    -webkit-appearance: none;
    margin: 0;
  }

  padding-top: 0.4vw;

  ${media.tablet} {
    padding-top: 0.7vw;
  }
  ${media.mobile} {
    padding-top: 2vw;
  }

  ${text.desktop.headerH4}
  padding-left: 1.736vw;
  padding-right: 1.736vw;

  ${media.tablet} {
    ${text.tablet.headerH4}
    padding-left: 2.441vw;
    padding-right: 2.441vw;
  }

  ${media.mobile} {
    ${text.mobile.headerH4}
    padding-left: 6.667vw;
    padding-right: 6.667vw;
  }
`;

const USD = styled.p`
  position: absolute;

  width: fit-content;
  color: ${colors.offWhite};
  left: 25%;
  transform: translateX(-50%);

  ${text.desktop.displayH5}
  bottom: 4vw;

  ${media.tablet} {
    ${text.tablet.displayH5}
    bottom: 14vw;
    left: 50%;
  }

  ${media.mobile} {
    ${text.mobile.displayH5}
    bottom: 35vw;
    left: 50%;
  }
`;

const ButtonWrapper = styled.div`
  opacity: 0;
  display: none;

  ${media.tablet} {
    width: 100%;
    margin-bottom: 2.93vw;
  }

  ${media.mobile} {
    width: 100%;
    margin-bottom: 5.333vw;
  }
`

const SubmitButtonWrapper = styled(ButtonWrapper)`
  opacity: 1;
  display: flex;

  ${media.tablet} {
    margin-bottom: 0vw;
  }

  ${media.mobile} {
    margin-bottom: 0vw;
  }
`

const Error = styled.p`
  position: absolute;
  text-align: center;
  color: ${colors.razzmatazz100};
  text-transform: uppercase;
  width: fit-content;
  ${text.desktop.displayH5}

  left: 50%;
  transform: translateX(-50%);

  bottom: 4vw;

  ${media.tablet} {
    ${text.tablet.displayH5}
    font-size: 1.4vw;
    bottom: 14vw;
  }

  ${media.mobile} {
    ${text.mobile.displayH5}
    font-size: 3.7vw;
    bottom: 35vw;
  }
`
