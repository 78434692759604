import React, { useRef } from "react";
import styled from "styled-components";
import { CustomEase } from "gsap/CustomEase";

import text from "styles/text";
import colors from "styles/colors";
import media from "styles/media";

CustomEase.create("card", "M0,0 C0.5,0 0,1 1,1 ");

type banner = {
  text: string;
  color: string;
  angle: string;
  zIndex: number;
  top: string;
};

type Props = {
  banners: banner[];
};

const CreatorsBanners: React.FC<Props> = ({ banners }) => {
  const bannerWrapper = useRef<HTMLDivElement>(null);

  const bannerEls = banners.map((banner, i) => {
    const { text, color, angle, zIndex, top } = banner;
    let textArray = [];

    for (let i = 0; i < 30; i++) {
      textArray.push(
        <Text key={`cbannertext_${i}`}>
          <p>{text}</p> <p className="cb_dash">{`//`}</p>
        </Text>
      );
    }

    const mySpeed = 2 + i * 2;

    return (
      <Banner
        data-scroll-offset="top bottom"
        key={i}
        angle={angle}
        bGColor={color}
        zIndex={zIndex}
        top={top}
      >
        <span
          data-scroll-direction="horizontal"
          data-scroll-target={`#direction_banners`}
          data-scroll
          data-scroll-speed={`${mySpeed}`}
          className="banner__text"
        >
          {textArray}
        </span>
      </Banner>
    );
  });

  return (
    <Wrapper id={`direction_banners`} data-scroll-container ref={bannerWrapper}>
      {bannerEls}
    </Wrapper>
  );
};

export default CreatorsBanners;

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  height: 69.4vw;
  ${media.tablet} {
    height: 55vw;
    min-height: 55vw !important;
  }

  ${media.mobile} {
    height: 77.3vw;
    min-height: 77.3vw !important;
  }
`;

const Text = styled.h2`
  line-height: 4.9vw;
  white-space: pre;
  position: relative;
  display: flex;

  div {
    display: flex;

    .cb_dash {
      margin: 0 20px;
    }
  }
`;

const Banner = styled.div<{
  angle: string;
  bGColor: string;
  zIndex: number;
  top: string;
}>`
  ${text.desktop.headerH2};
  color: ${(props) =>
    props.bGColor === colors.richBlack100
      ? colors.offWhite
      : colors.richBlack100};
  background: ${(props) => props.bGColor};
  z-index: ${(props) => props.zIndex};
  top: ${(props) => props.top};
  left: -25vw;
  transform: rotate(${(props) => props.angle}deg);
  position: absolute;
  transform-origin: 50%;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 1.7vw 0;
  width: 150vw;
  height: 8.3vw;

  ${media.tablet} {
    left: -50vw;
    height: 11.621vw;
    width: 208.398vw;
    padding: 2.441vw;
  }

  ${media.mobile} {
    left: -250vw;
    height: 16vw;
    width: 569.067vw;
    padding: 8vw 0vw;
  }

  span {
    position: relative;
    display: flex;
    font-family: NeueBit;
    font-style: normal;
    font-weight: bold;
    line-height: 100%;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 4.861vw;
    margin-left: -150vw;
    ${media.tablet} {
      font-size: 5vw;
    }

    ${media.mobile} {
      font-size: 8vw;
    }
  }
`;
