import React, { useRef, useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { useNavigate } from "utils/Hooks";

import gsap from 'gsap'
import { ScreenContext } from 'App'

import media from 'styles/media'
import text from 'styles/text'
import colors from 'styles/colors'

import { ReactComponent as triangleSVG } from 'assets/images/symbols/triangle.svg'

import Arrow from 'components/Arrow'
import LiveAuction from 'components/LiveAuction'


type props = {
  scrollDuration: number
  id: string
}

const CreatorCard: React.FC<props> = ({children, scrollDuration, id}) => {
  const screen = useContext(ScreenContext)

  const navigate = useNavigate()

  const triangleRef = useRef(null)
  const triangle2Ref = useRef(null)
  const textRef = useRef(null)
  const container2Ref = useRef(null)
  const blueRef = useRef(null)
  const pinkRef = useRef(null)

  const [arrowEnter, setArrowEnter] = useState(false)
  const [sideScroll, setSideScroll] = useState(false)
  const [scrollTl, setScrollTl] = useState(gsap.timeline())

  const handleMouseEnter = (e: any) => {
    setArrowEnter(true)
    setSideScroll(false)

    const tl = gsap.timeline({
      onComplete: () => {
        setSideScroll(true)
      }
    })

    tl.to(triangleRef.current, {
      duration: 0.5,
      top: "-10%",
      ease: "card"
    }, 0)
    tl.to(textRef.current, {
      duration: 0.5,
      top: "-10%",
      left: "-1%",
      ease: "card"
    }, 0)

    tl.to(blueRef.current, {
      duration: 0.5,
      top: "0%",
      ease: "card"
    }, 0)
    tl.to(pinkRef.current, {
      duration: 0.42,
      top: "0%",
      ease: "card"
    }, 0.08)
    tl.to(container2Ref.current, {
      duration: 0.4,
      top: "0%",
      ease: "card"
    }, 0.1)

  }

  const handleMouseLeave = (e: any) => {
    setArrowEnter(false)
    setSideScroll(false)

    const tl = gsap.timeline()

    tl.to(triangleRef.current, {
      duration: 0.5,
      top: "0%",
      ease: 'card'
    }, 0)
    tl.to(textRef.current, {
      duration: 0.5,
      top: "0%",
      left: "0%",
      ease: "card"
    }, 0)

    tl.to(container2Ref.current, {
      duration: 0.5,
      top: "100%",
      ease: "card"
    }, 0)
    tl.to(pinkRef.current, {
      duration: 0.48,
      top: "100%",
      ease: "card"
    }, 0.02)
    tl.to(blueRef.current, {
      duration: 0.46,
      top: "100%",
      ease: "card"
    }, 0.04)

    gsap.set(triangle2Ref.current, {
      visibility: 'visible',
      x: 0
    })

    return () => {
      tl.kill()
    }
  }

  useEffect(() => {
    if (sideScroll) {
      scrollTl.play()
    } else {
      scrollTl.restart()
      scrollTl.pause()
    }
  }, [sideScroll, scrollTl])

  useEffect(() => {
    const tl = gsap.timeline({
      repeat: -1,
      paused: true
    })

    tl.fromTo(`.side-text-${id}`, {
      x: 0
    }, {
      duration: scrollDuration ,
      x: "-100%",
      ease: "none"
    }, 0)
    tl.to(triangle2Ref.current, {
      x: "-200%",
      duration: 0.45,
      ease: "none",
      onComplete: () => {
        gsap.set(triangle2Ref.current, {
          visibility: 'hidden'
        })
      }
    }, 0)

    setScrollTl(tl)

    return () => {
      tl.kill()
    }
  }, [scrollDuration, id])

  const handleClick = () => {
    navigate(`/${id}`)
  }

  return (
    <Wrapper onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleClick}>
      <Container1>
        <TextWrapper>
          {(screen.desktop || screen.fullWidth) && <Triangle ref={triangleRef}/>}
          <Text1 ref={textRef}>
            {children}
          </Text1>
        </TextWrapper>
        <ArrowContainer>
          <Arrow
            backgroundColor={colors.richBlack100}
            arrowColor={colors.offWhite}
          />
        </ArrowContainer>
      </Container1>

      {(screen.desktop || screen.fullWidth) && <>
        <BlueBackground ref={blueRef}/>
        <PinkBackground ref={pinkRef}/>
        <Container2 ref={container2Ref}>
          <TextWrapper>
            <Triangle2 ref={triangle2Ref}/>
            <Text2 className={`side-text-${id}`}>
              {children} +&nbsp;
            </Text2>
            <Text2 className={`side-text-${id}`}>
              {children} +&nbsp;
            </Text2>
            <Text2 className={`side-text-${id}`}>
              {children} +&nbsp;
            </Text2>
            <Text2 className={`side-text-${id}`}>
              {children} +&nbsp;
            </Text2>
            <Text2 className={`side-text-${id}`}>
              {children} +&nbsp;
            </Text2>
          </TextWrapper>
          <AuctionContainer>
            <LiveAuction/>
          </AuctionContainer>
          <ArrowContainer>
            <Arrow
              backgroundColor={colors.offWhite}
              arrowColor={colors.richBlack100}
              mouseEnter={arrowEnter}
            />
          </ArrowContainer>
        </Container2>
      </>}
    </Wrapper>
  )
}

export default CreatorCard

const Wrapper = styled.div`
  width: 100%;
  border-top: 1px solid ${colors.offWhite};
  position: relative;

  ${media.desktop} {
    overflow: hidden;
    height: 6.597vw;
  }
`

const Container1 = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  ${media.desktop} {
    height: 6.597vw;
    padding-left: 1.042vw;
    position: absolute;
    top: 0;
    left: 0;
  }

  ${media.tablet} {
    padding-top: 2.344vw;
    padding-bottom: 2.344vw;
    padding-left: 1.465vw;
  }

  ${media.mobile} {
    padding-top: 3.733vw;
    padding-bottom: 3.733vw;
  }
`

const Triangle = styled(triangleSVG)`
  position: relative;
  height: auto;

  ${media.desktop} {
    width: 2.083vw;
    margin-right: 0.694vw;
    left:
  }
`

const Triangle2 = styled(triangleSVG)`
  position: relative;
  height: auto;

  ${media.desktop} {
    width: 2.083vw;
    margin-right: 0.694vw;
    left: 2.083vw;
  }
`

const Text1 = styled.span`
  position: relative;
  color: ${colors.offWhite};
  display: flex;
  align-items: center;
  width: 100%;

  ${media.desktop} {
    ${text.desktop.displayH3}
  }

  ${media.tablet} {
    ${text.tablet.displayH2}
    white-space: pre-wrap;
  }

  ${media.mobile} {
    white-space: pre-wrap;
    font-family: Proxima Nova, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 6.400vw;
    line-height: 100%;
    letter-spacing: -0.03em;
    text-transform: uppercase;
  }
`

const Text2 = styled.span`
  color: ${colors.richBlack100};
  display: flex;
  align-items: center;
  position: relative;
  width: fit-content;

  svg {
    path {
      fill: ${colors.richBlack100};
    }
  }

  ${media.desktop} {
    ${text.desktop.displayH3}
    height: 3.472vw;
    left: 2.083vw;
  }

`

const BlueBackground = styled.div`
  background-color: ${colors.splash100};
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 100%;
`

const PinkBackground = styled.div`
  background-color: ${colors.razzmatazz100};
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 100%;
`

const Container2 = styled.div`
  background-color: ${colors.offWhite};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  top: 100%;
  width: 100%;
  z-index: 3;

  ${media.desktop} {
    height: 6.597vw;
    padding-top: 0.694vw;
  }
`

const ArrowContainer = styled.div`
  position: absolute;

  ${media.desktop} {
    right: 0;
    top: 0;
  }

  ${media.tablet} {
    top: 2.344vw;
    right: 1.465vw;
  }

  ${media.mobile} {
    right: 0;
    top: 4.667vw;
  }
`

const TextWrapper = styled.div`
  display: flex;
  align-items: center;

  ${media.desktop} {
    width: 500%;
    overflow: hidden;
    position: absolute;
  }
`

const AuctionContainer = styled.div`
  position: absolute;
  left: 1.042vw;
  bottom: 0.694vw;
`
