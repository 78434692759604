import { useContext, useEffect, useRef } from "react"
import { useRoundRectGlow, useMedia } from "utils/Hooks"
import styled, { keyframes } from "styled-components"
import { ScreenContext } from "App"

import Colors from "styles/colors"
import text from "styles/text"
import Media from "styles/media"

import {ReactComponent as PhoneSVG} from '../../assets/svg/TTiPhone.svg'
import PhoneZoomContent from "./PhoneZoomContent"

type scrollObject = {
  speed: number;
  currentElements: [{
    class: string;
    el: {
      id: string;
      children: HTMLCollection;
    }
    progress: number;
  }]
}

const phoneScrollConstants = {
  d: {
    expConstant: 8625,
    scale: 70
  },
  t: {
    expConstant: 9250,
    scale: 75,
  },

  m: {
    expConstant: 6750,
    scale: 55
  }
}

const glowData = {
  desktop: {
    width: 0.236,
    height: 0.561,
    borderRadius: 0.056,
    blue: { offsetX: -0.099, offsetY: -0.025 },
    red: { offsetX: 0.085, offsetY: 0.045 }
  },
  tablet: {
    width: 0.332,
    height: 0.789,
    borderRadius: 0.078,
    blue: { offsetX: -0.139, offsetY: -0.040 },
    red: { offsetX: 0.120, offsetY: 0.055 }
  },
  mobile: {
    width: 0.907,
    height: 2.155,
    borderRadius: 0.213,
    blue: { offsetX: -0.3, offsetY: -0.3 },
    red: { offsetX: 0.3, offsetY: 0.3 }
  }
}

const PhoneZoom: React.FC<{}> = () => {
  const sideways = useRef(null)
  const phoneScale = useRef(1)
  const isVisible = useRef(false)

  const glowWrapper = useRef<HTMLDivElement>(null)
  const redGlow = useRef<HTMLCanvasElement>(null)
  const blueGlow = useRef<HTMLCanvasElement>(null)

  const zoomData = useMedia(phoneScrollConstants.d, phoneScrollConstants.d, phoneScrollConstants.t, phoneScrollConstants.m)

  useEffect(() => {
    window.locomotiveScroll.on('scroll', (obj: scrollObject) => {
      if (!obj.currentElements) return;

      let objArray = Object.values(obj.currentElements)
      let sidewaysText = objArray.filter(el => el.class && el.class === 'section-visible')[0]

      if (sidewaysText) isVisible.current = true
      else isVisible.current = false

      let stickyContainer = objArray.filter(element => element.el.id && element.el.id === 'phoneStickyContent')[0]

      if (stickyContainer) {
        let progress = stickyContainer.progress
        let phone = Array.from(stickyContainer.el.children as HTMLCollectionOf<HTMLElement>).filter(child => child.tagName === 'svg')[0]
        let scale = 1

        /* !! DO NOT DELETE !!
        this function is only needed to recompute expConstant if the total scale or the cutoff point (0.2) changes. ask jackson about it.
        const expConstant = (totalScale - 1) / Math.pow(firstCutoff, 3)
        */

        if (progress <  0.2) {
          scale = parseFloat((zoomData.expConstant * Math.pow(progress, 3) + 1).toFixed(3))
        } else if (progress > 0.8) {
          let inverseProgress = 1 - progress
          scale = parseFloat((zoomData.expConstant * Math.pow(inverseProgress, 3) + 1).toFixed(3))
        } else {
          scale = zoomData.scale
        }

        if (scale !== phoneScale.current) {
          phone.style.transform = `scale(${scale})`
          phoneScale.current = scale
        }
      }
    })
  }, [zoomData])

  const screen = useContext(ScreenContext)

  let currentGlow = screen.fullWidth || screen.desktop ? glowData.desktop : screen.tablet ? glowData.tablet : glowData.mobile

  useRoundRectGlow(
    glowWrapper,
    blueGlow,
    currentGlow.blue.offsetX,
    currentGlow.blue.offsetY,
    currentGlow.width,
    currentGlow.height,
    currentGlow.borderRadius,
    Colors.splash100,
    0.3
  )

  useRoundRectGlow(
    glowWrapper,
    redGlow,
    currentGlow.red.offsetX,
    currentGlow.red.offsetY,
    currentGlow.width,
    currentGlow.height,
    currentGlow.borderRadius,
    Colors.razzmatazz100,
    0.3
  )

  return <Wrapper data-scroll-section data-scroll data-scroll-class="section-visible" data-scroll-repeat>
    <StickyWrapper id="homepagePhoneSticky">
      <StickyContent ref={glowWrapper} data-scroll data-scroll-sticky data-scroll-target="#homepagePhoneSticky" id="phoneStickyContent">
        <Glow ref={redGlow}/>
        <Glow ref={blueGlow}/>
        <SidewaysText>
          <DigText ref={sideways}>POWERED BY BITSKI // TIKTOK TOP MOMENTS // POWERED BY BITSKI // TIKTOK TOP MOMENTS // POWERED BY BITSKI // TIKTOK TOP MOMENTS // </DigText>
        </SidewaysText>
        <Phone />
      </StickyContent>
      <PhoneZoomContent data-scroll-section/>
    </StickyWrapper>
  </Wrapper>
}

const Wrapper = styled.section`
  padding: 18.6vw 0 15vw;
  position: relative;
  overflow: hidden;
  ${Media.tablet} {
    padding-top: 0;
  }
  ${Media.mobile} {
    margin-top: 75vw;
    overflow: visible;
  }
`

const StickyWrapper = styled.div``

const sidewaysAnimation = keyframes`
  from {
    transform: translateX(33%);
  }
  to {
    transform: translateX(0%);
  }
`

const SidewaysText = styled.div`
  position: absolute;
  left: -174.5vw;
  width: 357vw;
  transform: rotate(-90deg);
  ${Media.tablet} {
    width: 486vw;
    left: -237.5vw;
  }
  ${Media.mobile} {
    width: 1163vw;
    transform: rotate(0);
    left: -300%;
    top: 0;
  }
`

const DigText = styled.h1`
  ${text.desktop.jumboTicker}
  color: ${Colors.offWhite};
  padding: 2vw 0;
  border-bottom: 1px solid ${Colors.offWhite};
  animation: ${sidewaysAnimation} 20s linear infinite;
  ${Media.tablet} {
    ${text.tablet.jumboTicker}
  }

  ${Media.mobile} {
    ${text.mobile.jumboTicker}
    border-top: 1px solid ${Colors.offWhite};
    position: relative;
    top: -30vw;
    /* background-color: ${Colors.richBlack100}; */
  }
`

const StickyContent = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  ${Media.mobile} {
    height: 90vh;
  }
`

const Glow = styled.canvas`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  filter: blur(8vw);
  ${Media.tablet} {
    filter: blur(12vw);
  }
  ${Media.mobile} {
    height: 120%;
    top: -10%;
    filter: blur(22vw);
  }
`

const Phone = styled(PhoneSVG)`
  width: 23.6vw;
  height: auto;
  margin: 0 auto;
  display: block;
  position: relative;
  transform-origin: 45.88% 41.72%;
  ${Media.tablet} {
    width: 33.2vw;
  }
  ${Media.mobile} {
    width: 70vw;
  }
`

export default PhoneZoom
