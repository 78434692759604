const Colors = {
  razzmatazz100: "#FE2C55",
  razzmatazz80: "#FE5677",
  razzmatazz60: "#FE8099",
  razzmatazz40: "#FFABBB",
  razzmatazz20: "#FFD5DD",
  splash100: "#25F4EE",
  splash80: "#51F6F1",
  splash60: "#7CF8F5",
  splash40: "#A8FBF8",
  splash20: "#D3FDFC",
  richBlack100: "#000",
  richBlack80: "#3B3B3B",
  richBlack60: "#6C6C6C",
  richBlack40: "#9D9D9D",
  richBlack20: "#CECECE",
  offWhite: "#F5F5F5",
};

export default Colors
