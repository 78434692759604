import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import styled from "styled-components";
import gsap from 'gsap'
import { ScreenContext } from 'App'
import { useNavigate, useMedia } from 'utils/Hooks'

import { AuthenticationStatus, AuthenticationErrorCode  } from 'bitski'

import media from 'styles/media'
import text from 'styles/text'
import colors from 'styles/colors'

import {ReactComponent as BitskiSVG} from 'assets/svg/bitskiLogomarkBlue.svg'
import {ReactComponent as TikTokSVG} from 'assets/svg/tiktok.svg'
import {ReactComponent as DotSVG} from 'assets/svg/redDot.svg'
import GaryVJPG from 'assets/images/garyV/full.jpg'
import JessJPG from 'assets/images/jessM/full.jpg'
import X0RJPG from 'assets/images/x0r/full.jpg'
import BellaJPG from 'assets/images/bellaPoarch/full.jpg'
import BrittanyJPG from 'assets/images/brittanyBroski/full.jpg'
import CoinJPG from 'assets/images/coinArtist/full.jpg'
import FnmekaJPG from 'assets/images/fnmeka/full.jpg'
import Grimes1JPG from 'assets/images/grimes1/full.jpg'
import Grimes2JPG from 'assets/images/grimes2/full.jpg'
import LilNasJPG from 'assets/images/lilNasX/full.jpg'
import CurtisJPG from 'assets/images/curtisRoach/full.jpg'
import RtfktJPG from 'assets/images/rtfkt/full.jpg'
import RudyJPG from 'assets/images/rudyWillingham/full.jpg'

import {ReactComponent as ArrowSVG} from 'assets/images/symbols/arrow.svg'
import {ReactComponent as RtfktSVG} from 'assets/images/symbols/rtfkt.svg'

const Header: React.FC<{}> = () => {
  const screen = useContext(ScreenContext);
  const navigate = useNavigate()

  const [connected, setConnected] = useState(false)
  const [bitskiText, setBitskiText] = useState("unconnected / bitski wallet")

  const [open, setOpen] = useState(false);
  const [activeDrop, setActiveDrop] = useState(null);
  const activeRefs = useRef(null);
  const animation = useRef(false);
  const tlActive = useRef(false);

  const wrapperRef = useRef(null);
  const line1Ref = useRef(null);
  const line2Ref = useRef(null);
  const contentRef = useRef(null);
  const dropsRef = useRef(null);
  const linksRef = useRef(null);

  const drop1Ref = useRef(null);
  const drop2Ref = useRef(null);
  const drop3Ref = useRef(null);
  const drop4Ref = useRef(null);
  const drop5Ref = useRef(null);
  const drop6Ref = useRef(null);

  const garyRef = useRef(null);
  const jessRef = useRef(null);
  const michaelRef = useRef(null);
  const bellaRef = useRef(null);
  const bobbyRef = useRef(null);
  const curtisRef = useRef(null);
  const brittanyRef = useRef(null);
  const coinRef = useRef(null);
  const fnmekaRef = useRef(null);
  const grimesRef = useRef(null);
  const lilNasRef = useRef(null);
  const rtfktRef = useRef(null);
  const rudyRef = useRef(null);

  const contentHeight = useMedia("calc(100vh - 6.042vw)", "calc(100vh - 6.042vw)", "calc(100vh - 8.496vw)", "calc(100vh - 16vw)")

  const openMenu = () => {
    window.locomotiveScroll.stop();

    const tl = gsap.timeline({
      onStart: () => {
        tlActive.current = true;
      },
      onComplete: () => {
        tlActive.current = false;
      },
    });
    tl.set(contentRef.current, {
      display: "flex",
      height: "0vh"
    }, 0);
    tl.fromTo(line1Ref.current, {
      rotate: 0,
      y: "0px",
    }, {
      duration: 0.5,
      rotate: 45,
      y: "5px",
    }, 0)
      
    tl.fromTo(line2Ref.current, {
          rotate: 0,
        }, {
          duration: 0.5,
          rotate: -45,
          y: "0%",
        }, 0)
      .to(contentRef.current, {
        duration: 0.8,
        height: contentHeight
      }, 0)
      .fromTo([dropsRef.current, linksRef.current], {
        display: "flex",
      }, {
        duration: 0.1,
        opacity: 1,
      }, 0);

    return () => {
      tl.kill()
    }
  };

  const closeMenu = () => {
    const tl = gsap.timeline({
      onStart: () => {
        tlActive.current = true;
      },
      onComplete: () => {
        tlActive.current = false;
      },
    });
    
    tl.to(line1Ref.current, {
      rotate: 0,
      y: "0px",
    }, 0)
      
    tl.to(line2Ref.current, {
          rotate: 0,
        }, 0)
      .to(contentRef.current, {
        duration: 0.8,
        height: "0vh"
      }, 0)
      .to([dropsRef.current, linksRef.current], {
        display: "flex",
        opacity: 0
      }, 0)
      .set(contentRef.current, {
        display: "none"
      }, 0.8)

    window.locomotiveScroll.start();
  };

  const menuToggle = () => {
    if (open) {
      setOpen(false);
      closeMenu();
      removeMouseMove();
    } else {
      setOpen(true);
      openMenu();
      addMouseMove();
    }
  };

  const reset = () => {
    let refs = [
      drop1Ref.current,
      drop2Ref.current,
      drop3Ref.current,
      drop4Ref.current,
      drop5Ref.current,
      drop6Ref.current,
    ];

    gsap.to(refs, {
      opacity: 1,
      duration: 0.3,
    });
  };

  useEffect(() => {
    let refs = [drop1Ref, drop2Ref, drop3Ref, drop4Ref, drop5Ref, drop6Ref];
    reset();

    if (activeDrop) {
      refs.splice(refs.indexOf(activeDrop), 1);
      let currents = refs.map((item) => item.current);
      gsap.to(currents, {
        opacity: 0.1,
        duration: 0.3,
      });
    }
  }, [activeDrop]);

  useEffect(() => {
    let text;
    if (connected) {
      text = screen.mobile ? 'wallet' : 'bitski wallet'
    } else {
      text = screen.mobile ? "wallet" : "unconnected / bitski wallet"
    }

    setBitskiText(text)
  }, [connected, screen.mobile])

  const drop1 = [rudyRef.current, lilNasRef.current];
  const drop2 = [curtisRef.current, coinRef.current];
  const drop3 = [bellaRef.current, bobbyRef.current];
  const drop4 = [fnmekaRef.current, rtfktRef.current];
  const drop5 = [garyRef.current, jessRef.current, michaelRef.current];
  const drop6 = [brittanyRef.current, grimesRef.current];

  const handleMouseMove = (e: MouseEvent) => {
    if (!animation.current && activeRefs.current) {
      let normalizedX = -e.clientX / window.innerWidth + 0.5;
      let normalizedY = -e.clientY / window.innerHeight + 0.5;
      gsap.to(activeRefs.current, {
        stagger: 0.2,
        x: normalizedX * 40,
        y: normalizedY * 40,
      });
    }
  };

  const addMouseMove = () => {
    window.addEventListener("mousemove", handleMouseMove);
  };

  const removeMouseMove = () => {
    window.removeEventListener("mousemove", handleMouseMove);
  };

  const imageAnimation = (refs: any, left: boolean) => {
    if (screen.fullWidth || screen.desktop) {
      gsap.fromTo(refs, {
        display: "flex",
        x: left ? "-70%" : "70%",
        y: "40%",
      }, {
        duration: 0.3,
        rotate: 0,
        opacity: 1,
        x: "0%",
        y: "0%",
        ease: "circ.out",
        onStart: () => {
          animation.current = true;
        },
        onComplete: () => {
          animation.current = false;
        },
      }
      );
    }
  };

  const resetAnimation = (refs: any, left: boolean) => {
    gsap.fromTo(refs, {
      rotate: 0,
      opacity: 1,
      x: "0%",
      y: "0%",
    }, {
      rotate: left ? 10 : -10,
      opacity: 0,
      duration: 0.3,
      x: left ? "-70%" : "70%",
      y: "40%",
      ease: "circ.out",
      onStart: () => {
        animation.current = true;
      },
      onComplete: () => {
        animation.current = false;
        gsap.set(refs, {
          display: "none",
        });
      },
    }
    );
  };

  const handleMouseEnter = (drop: any) => {
    switch (drop) {
      case 1:
        setActiveDrop(drop1Ref);
        imageAnimation(drop1, false);
        activeRefs.current = drop1;
        break;

      case 2:
        setActiveDrop(drop2Ref);
        imageAnimation(drop2, true);
        activeRefs.current = drop2;

        break;

      case 3:
        setActiveDrop(drop3Ref);
        imageAnimation(drop3, false);
        activeRefs.current = drop3;

        break;

      case 4:
        setActiveDrop(drop4Ref);
        imageAnimation(drop4, true);
        activeRefs.current = drop4;

        break;

      case 5:
        setActiveDrop(drop5Ref);
        imageAnimation(drop5, false);
        activeRefs.current = drop5;

        break;

      case 6:
        setActiveDrop(drop6Ref);
        imageAnimation(drop6, true);
        activeRefs.current = drop6;

        break;
    }
  };

  const handleMouseLeave = (drop: any) => {
    activeRefs.current = null;
    switch (drop) {
      case 1:
        resetAnimation(drop1, false);
        break;

      case 2:
        resetAnimation(drop2, true);
        break;

      case 3:
        resetAnimation(drop3, false);
        break;

      case 4:
        resetAnimation(drop4, true);
        break;

      case 5:
        resetAnimation(drop5, false);
        break;

      case 6:
        resetAnimation(drop6, true);
        break;
    }
  };

  const hideHeader = useCallback(() => {
    gsap.to(wrapperRef.current, {
      duration: 0.5,
      y: "-100%",
    });
  }, [wrapperRef]);

  const showHeader = useCallback(() => {
    gsap.to(wrapperRef.current, {
      duration: 0.5,
      y: "0%",
    });
  }, [wrapperRef]);

  useEffect(() => {
    window.locomotiveScroll.on("scroll", (e: any) => {
      if (e.direction === "down") {
        hideHeader();
      } else if (e.direction === "up") {
        showHeader();
      }
    });
  }, [hideHeader, showHeader]);

  const svgMouseEnter = () => {
    if (!open) {
      gsap.to(line1Ref.current, {
        duration: 0.5,
        y: "-2px",
      });
      gsap.to(line2Ref.current, {
        duration: 0.5,
        y: "2px",
      });
    }
  };

  const svgMouseLeave = () => {
    if (!open && !tlActive.current) {
      gsap.to([line1Ref.current, line2Ref.current], {
        duration: 0.5,
        y: "0px",
      });
    }
  };

  const connectBitski = () => {
    if (window.bitski.authStatus === AuthenticationStatus.NotConnected) {
      window.bitski.signIn()
        .then(() => {
          setConnected(true)
        })
        .catch((error: any) => {
          if (error.code === AuthenticationErrorCode.UserCancelled) {
            console.error('user canceled')
          } else {
            console.error({error})
          }
        })
    } else {
      setConnected(true)
      window.open('https://wallet.bitski.com', '_blank')
    }
  }

  const handleClick = (path: string) => {
    setTimeout(menuToggle, 1000)
    navigate(path)
  }

  return (
    <Wrapper ref={wrapperRef}>
      <Top>
        <TikTok />
        <HR />
        {screen.mobile && <Bitski />}
        <Title>
          Tiktok top moments
          <BottomTitle>
            <Bitski /> Powered by Bitski
          </BottomTitle>
        </Title>
        <SignInButton onClick={connectBitski}>{!connected && <Dot/>}{bitskiText}<Arrow/></SignInButton>
        <Symbol onMouseEnter={svgMouseEnter} onMouseLeave={svgMouseLeave} onClick={menuToggle} >
          <Line ref={line1Ref}/>
          <Line2 ref={line2Ref}/>
        </Symbol>
      </Top>
      <Content ref={contentRef}>
        {(screen.fullWidth || screen.desktop) && (
          <>
            <LilNas src={LilNasJPG} ref={lilNasRef} />
            <Rudy src={RudyJPG} ref={rudyRef} />

            <Fnmeka src={FnmekaJPG} ref={fnmekaRef} />
            <Rtfkt src={RtfktJPG} ref={rtfktRef} />

            <Bella src={BellaJPG} ref={bellaRef} />
            <Grimes1 src={Grimes1JPG} ref={bobbyRef} />

            <Curtis src={CurtisJPG} ref={curtisRef} />
            <Coin src={CoinJPG} ref={coinRef} />

            <GaryV src={GaryVJPG} ref={garyRef} />
            <Jess src={JessJPG} ref={jessRef} />
            <X0R src={X0RJPG} ref={michaelRef} />

            <Brittany src={BrittanyJPG} ref={brittanyRef} />
            <Grimes src={Grimes2JPG} ref={grimesRef} />
          </>
        )}
        <Links ref={linksRef}>
          <LargeLink onClick={() => handleClick("/")}>HOME</LargeLink>
          <LargeLink onClick={() => handleClick("/exhibit")}>EXHIBIT</LargeLink>
        </Links>
        <Drops ref={dropsRef} onMouseLeave={() => setActiveDrop(null)}>
          <Drop
            className="header-drop"
            ref={drop1Ref}
            onMouseEnter={() => handleMouseEnter(1)}
            onMouseLeave={() => handleMouseLeave(1)}
            onClick={() => handleClick("/lil-nas-x")}

          >
            <DropTitle>Rudy Willingham</DropTitle>
            <DropTitle>Lil Nas x</DropTitle>
            <Arrow />
          </Drop>

          <Drop
            className="header-drop"
            ref={drop4Ref}
            onMouseEnter={() => handleMouseEnter(4)}
            onMouseLeave={() => handleMouseLeave(4)}
            onClick={() => handleClick("/fnmeka")}

          >
            <DropTitle>FNMEKA +</DropTitle>
            <DropTitle>
              rtkft <RtfktSymbol />
            </DropTitle>
            <Arrow />
          </Drop>

          <Drop
            className="header-drop"
            ref={drop3Ref}
            onMouseEnter={() => handleMouseEnter(3)}
            onMouseLeave={() => handleMouseLeave(3)}
            onClick={() => handleClick("/bella-poarch")}

          >
            <DropTitle>Bella Poarch +</DropTitle>
            <DropTitle>grimes</DropTitle>
            <Arrow />
          </Drop>

          <Drop
            className="header-drop"
            ref={drop2Ref}
            onClick={() => handleClick("/curtis-roach")}
            onMouseEnter={() => handleMouseEnter(2)}
            onMouseLeave={() => handleMouseLeave(2)}
          >
            <DropTitle>curtis roach +</DropTitle>
            <DropTitle>coin artist</DropTitle>
            <Arrow />
          </Drop>

          <Drop
            className="header-drop"
            ref={drop5Ref}
            onMouseEnter={() => handleMouseEnter(5)}
            onMouseLeave={() => handleMouseLeave(5)}
            onClick={() => handleClick("/gary-vee")}

          >
            <DropTitle>
              {screen.fullWidth || screen.desktop
                ? "gary vaynerchuk  + Jess"
                : "gary vaynerchuk  +"}
            </DropTitle>
            <DropTitle>
              {screen.fullWidth || screen.desktop
                ? "marciante & x0r"
                : "Jess Marciante & x0r"}
            </DropTitle>
            <Arrow />
          </Drop>

          <Drop
            className="header-drop"
            ref={drop6Ref}
            onMouseEnter={() => handleMouseEnter(6)}
            onMouseLeave={() => handleMouseLeave(6)}
            onClick={() => handleClick("/brittany-broski")}

          >
            <DropTitle>brittany broski +</DropTitle>
            <DropTitle>grimes</DropTitle>
            <Arrow />
          </Drop>
        </Drops>
      </Content>
    </Wrapper>
  );
};

export default Header;

const Wrapper = styled.header`
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  background-color: ${colors.richBlack100};
  /* TEMPORARY */
  #bitski-button {
    margin-left: auto;
    margin-right: 10%;
    background: black;
    color: white;
    border: none;
    border-bottom: 1px solid white;
    ${media.tablet} {
      margin-right: 20%;
    }
    ${media.mobile} {
      margin-right: 20%;
    }
  }

`;

const Dot = styled(DotSVG)`
  height: auto;

  width: 0.694vw;
  margin-right: 0.347vw;

  circle {
    fill: ${colors.razzmatazz100};
  }

  ${media.tablet} {
    width: 0.977vw;
    margin-right: 0.488vw;
  }

  ${media.mobile} {
    display: none;
  }
`

const Content = styled.div`
  background-color: ${colors.richBlack100};
  box-sizing: border-box;
  height: 0vh;
  display: none;

  padding-left: 3.472vw;
  padding-right: 3.472vw;
  padding-top: 2.361vw;

  ${media.tablet} {
    flex-direction: column;
    padding-left: 4.883vw;
    padding-right: 4.883vw;
    padding-top: 3.906vw;
    padding-bottom: 2.93vw;
  }

  ${media.mobile} {
    flex-direction: column;
    padding-left: 6.667vw;
    padding-right: 6.667vw;
    padding-top: 4vw;
    padding-bottom: 17.867vw;
  }
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  z-index: 3;

  ${media.desktop} {
    height: 6.042vw;
    padding-left: 3.472vw;
    padding-top: 1.944vw;
    padding-bottom: 1.944vw;
  }

  ${media.tablet} {
    height: 8.496vw;
    padding-left: 4.883vw;
    padding-top: 2.637vw;
    padding-bottom: 2.637vw;
  }

  ${media.mobile} {
    height: 16vw;
    padding-left: 6.667vw;
    padding-top: 5.333vw;
    padding-bottom: 5.333vw;
  }
`;

const Bitski = styled(BitskiSVG)`
  height: auto;
  position: relative;

  path {
    fill: ${colors.offWhite};
  }

  width: 0.599vw;
  margin-right: 0.382vw;
  top: 0.15vw;

  ${media.tablet} {
    top: 0.2vw;
    width: 0.977vw;
    margin-right: 0.488vw;
  }

  ${media.mobile} {
    width: 5.333vw;
  }
`;

const TikTok = styled(TikTokSVG)`
  height: auto;

  path {
    fill: ${colors.offWhite};
  }

  ${media.desktop} {
    width: 2.014vw;
  }

  ${media.tablet} {
    width: 2.539vw;
  }

  ${media.mobile} {
    width: 4.8vw;
  }
`;

const HR = styled.hr`
  color: ${colors.offWhite};
  transform: rotate(90deg);
  outline: none;
  margin: 0;

  width: 2.083vw;
  margin-top: 1.389vw;
  margin-bottom: 1.389vw;

  ${media.tablet} {
    width: 2.93vw;
    margin-top: 1.953vw;
    margin-bottom: 1.953vw;
  }

  ${media.mobile} {
    width: 5.333vw;
    margin-top: 4vw;
    margin-bottom: 4vw;
  }
`;

const Title = styled.span`
  color: ${colors.offWhite};
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${media.desktop} {
    ${text.desktop.displayH4};
    font-size: 1.25vw;
    line-height: 100%;
  }

  ${media.tablet} {
    ${text.tablet.displayH4}
    line-height: 100%;
  }

  ${media.mobile} {
    display: none;
  }
`;

const BottomTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: none;
  ${text.desktop.tickerText}

  ${media.tablet} {
    ${text.tablet.tickerText}
  }
`;

const Symbol = styled.div`
  position: absolute;
  cursor: pointer;

  top: 1.944vw;
  right: 3.472vw;
  height: 2.431vw;
  width: 3.472vw;

  ${media.tablet} {
    right: 4.883vw;
    top: 2.246vw;
    width: 4.883vw;
    height: 3.418vw;
  }

  ${media.mobile} {
    right: 6.667vw;
    width: 6.667vw;
    height: 6.667vw;
    top: 4.8vw;
  }
`;

const Line = styled.hr`
  position: absolute;
  margin: 0px;
  height: 1px;
  background-color: ${colors.offWhite};
  outline: none;
  border: none;

  width: 3.472vw;
  top: calc(50% - 0.174vw);

  ${media.tablet} {
    width: 4.883vw;
    top: calc(50% - 0.244vw);
  }

  ${media.mobile} {
    width: 6.667vw;
    top: calc(50% - 0.667vw);
  }
`;

const Line2 = styled(Line)`
  top: calc(50% + 0.174vw);

  ${media.tablet} {
    top: calc(50% + 0.244vw);
  }

  ${media.mobile} {
    top: calc(50% + 0.667vw);
  }
`;

const Links = styled.div`
  display: none;
  opacity: 0;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 7.292vw;

  ${media.tablet} {
    margin-right: 0vw;
    margin-bottom: 2.93vw;
  }

  ${media.mobile} {
    margin-right: 0vw;
    margin-bottom: 6.667vw;
  }
`;

const LargeLink = styled.span`
  cursor: pointer;
  color: transparent;
  ${text.desktop.displayH1}
  -webkit-text-stroke-color: ${colors.offWhite};
  -webkit-text-stroke-width: 1px;
  text-decoration: none;
  margin-bottom: 1.736vw;
  transition: 500ms;

  :hover {
    color: ${colors.offWhite};
  }

  ${media.tablet} {
    ${text.tablet.displayH1}
    font-size: 8.789vw;
    margin-bottom: 1.953vw;
  }

  ${media.mobile} {
    ${text.mobile.displayH2}
    font-size: 16vw;
    margin-bottom: 1.333vw;
  }
`;

const Drops = styled.div`
  display: none;
  opacity: 0;
  flex-direction: column;
  align-items: center;

  width: 45.972vw;

  ${media.tablet} {
    width: 100%;
  }

  ${media.mobile} {
    width: 100%;
  }
`;

const Drop = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-top: 1px solid ${colors.offWhite};
  cursor: pointer;

  padding-top: 1.042vw;
  padding-bottom: 0.694vw;

  ${media.fullWidth} {
    padding-top: 10px;
  }

  ${media.tablet} {
    padding-left: 1.465vw;
    padding-top: 2.344vw;
    padding-bottom: 2.344vw;
  }

  ${media.mobile} {
    padding-top: 3.733vw;
    padding-bottom: 3.733vw;
  }
`;

const DropTitle = styled.span`
  color: ${colors.offWhite};
  ${text.desktop.headerH3}
  text-transform: uppercase;
  display: flex;
  align-items: center;

  ${media.fullWidth} {
    ${text.fullWidth.displayH3}
  }

  ${media.tablet} {
    ${text.tablet.displayH2}
  }

  ${media.mobile} {
    ${text.mobile.displayH2}
    font-size: 6.4vw;
  }
`;

const RtfktSymbol = styled(RtfktSVG)`
  width: auto;

  height: 2.083vw;
  margin-left: 0.347vw;

  ${media.tablet} {
    height: 4.883vw;
    margin-left: 0.488vw;
  }

  ${media.mobile} {
    height: 4.3vw;
    margin-left: 0.488vw;
  }
`;

const Arrow = styled(ArrowSVG)`
  height: auto;
  position: absolute;

  width: 1.389vw;
  top: 1.25vw;
  right: 0.972vw;

  ${media.tablet} {
    top: 2.344vw;
    right: 1.465vw;
    width: 2.441vw;
  }

  ${media.mobile} {
    top: 3.733vw;
    right: 0vw;
    width: 5.867vw;
  }
`;

const LargeImage = styled.img`
  position: absolute;
  z-index: 1;
  width: 22.639vw;
  height: 36.319vw;
  transform: rotate(-10deg);
  display: none;
  opacity: 0;
  transform-origin: 100% 100%;
  object-fit: cover;
`;

const SmallImage = styled.img`
  transform: rotate(-10deg);
  display: none;
  opacity: 0;
  transform-origin: 100% 100%;
  position: absolute;
  z-index: 1;
  width: 9.653vw;
  height: 14.722vw;
  object-fit: cover;
`;

const LilNas = styled(LargeImage)`
  top: 3.472vw;
  left: 61.528vw;
`;

const Rudy = styled(SmallImage)`
  top: 5.903vw;
  left: 53.958vw;
`;

const Bella = styled(LargeImage)`
  top: 10.417vw;
  left: 46.875vw;
`;

const Grimes1 = styled(SmallImage)`
  top: 12.847vw;
  left: 67.431vw;
`;

const Curtis = styled(LargeImage)`
  top: 13.889vw;
  left: 60.764vw;
`;

const Coin = styled(SmallImage)`
  top: 16.319vw;
  left: 81.319vw;
`;

const Fnmeka = styled(LargeImage)`
  top: 6.944vw;
  left: 57.292vw;
`;

const Rtfkt = styled(SmallImage)`
  top: 9.375vw;
  left: 77.847vw;
`;

const GaryV = styled(LargeImage)`
  top: 20.486vw;
  left: 55.208vw;
`;

const Jess = styled(SmallImage)`
  top: 17.361vw;
  left: 75.764vw;
`;

const X0R = styled(SmallImage)`
  top: 34.722vw;
  left: 75.764vw;
`;

const Brittany = styled(LargeImage)`
  top: 20.833vw;
  left: 62.847vw;
`;

const Grimes = styled(SmallImage)`
  top: 23.264vw;
  left: 83.403vw;
`

const SignInButton = styled.button`
  position: absolute;
  outline: none;
  border: 1px solid ${colors.offWhite};
  background-color: transparent;
  color: ${colors.offWhite};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  width: fit-content;

  height: 2.431vw;
  border-radius: 2.986vw;
  padding-left: 1.042vw;
  padding-right: 1.042vw;
  ${text.desktop.tickerText}
  right: 10.417vw;

  ${media.tablet} {
    ${text.tablet.tickerText}
    height: 3.418vw;
    border-radius: 4.199vw;
    padding-left: 1.465vw;
    padding-right: 1.465vw;
    padding-top: 0.781vw;
    padding-bottom: 0.781vw;
    right: 12.207vw;
  }

  ${media.mobile} {
    ${text.mobile.tickerText}
    height: 8vw;
    border-radius: 11.467vw;
    padding-left: 2.4vw;
    padding-right: 2.4vw;
    padding-top: 1.6vw;
    padding-bottom: 1.6vw;
    right: 20vw;
    font-size: 4.8vw;
  }

  ${Arrow} {
    position: static;

    width: 0.833vw;
    height: 0.833vw;
    margin-left: 0.347vw;

    ${media.tablet} {
      width: 0.977vw;
      height: 0.977vw;
      margin-left: 0.488vw;
    }

    ${media.mobile} {
      width: 2.533vw;
      height: 2.533vw;
      margin-left: 1.6vw;
    }

    path {
      stroke: ${colors.offWhite};
    }
  }
`
