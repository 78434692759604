import { useRef, useEffect, useContext, useMemo } from "react";
import styled from "styled-components";
import Hero from "../sections/exhibit/Hero";
import Header from "components/Header";
import MuseumImage from "sections/exhibit/CreatorsImage";
import Museum from "sections/exhibit/Museum";
import Footer from "components/Footer";
import media from "styles/media";
import gsap from "gsap";
import november from "assets/images/exhibit/november.png";
import duets from "assets/images/exhibit/duets.jpg";
import redDot from "assets/svg/redDot.svg";
import blueTriangle from "assets/svg/blueTriangle.svg";
import whiteDiamonds from "assets/svg/whiteDiamonds.svg";
import { ScreenContext } from "App";

import topGlow from "assets/images/movingImageGlows.png";
import bottomGlow from "assets/images/movingImageGlowsBottom.png";

const Exhibit: React.FC<{}> = () => {
  const redDotRef = useRef<HTMLImageElement>(null);
  const blueTriangleRef = useRef<HTMLImageElement>(null);
  const whiteDiamondsRef = useRef<HTMLImageElement>(null);

  const screen = useContext(ScreenContext);

  const currentMediaSize = useMemo(
    () =>
      screen.fullWidth || screen.desktop
        ? "desktop"
        : screen.tablet
        ? "tablet"
        : "mobile",
    [screen]
  );

  const handleMouseMove = (e: MouseEvent) => {
    if (window.innerWidth > 1024) {
      let normalizedX = -e.clientX / window.innerWidth + 0.5;
      let normalizedY = -e.clientY / window.innerHeight + 0.5;
      gsap.to(
        [redDotRef.current, blueTriangleRef.current, whiteDiamondsRef.current],
        { x: normalizedX * 60, y: normalizedY * 60 }
      );
    }
  };

  const shapeData = {
    desktop: [
      {
        circle: { left: "94.1vw", top: "16.7vw", angle: "0deg" },
        triangle: { top: "53.4vw", left: "86.3vw", angle: "-137deg" },
        diamonds: { top: "59vw", left: "33.8vw", angle: "47deg" },
      },
    ],

    tablet: [
      {
        circle: { top: "8.89vw", left: "90.33vw", angle: "0deg" },
        triangle: { top: "57.13vw", left: "77.44vw", angle: "-137deg" },
        diamonds: { top: "53.32vw", left: "14.45vw", angle: "48deg" },
      },
    ],
    mobile: [
      {
        circle: { top: "118.4vw", left: "-1.8vw", angle: "0deg" },
        triangle: { top: "188.3vw", left: "86.9vw", angle: "-139deg" },
        diamonds: { top: "60.8vw", left: "84vw", angle: "47deg" },
      },
    ],
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, []);

  return (
    <>
      <Header data-scroll-section />
      <TopGlow data-scroll-section />
      {/* <BottomGlow data-scroll-section /> */}
      <Hero />
      {/* <BannerSection data-scroll left={false} text={"VISIT THE EXHIBIT"} /> */}

      {/* <DefaultCreatorWrapper id="museum_1">
        <MuseumImage
          bigImage={{ src: november, caption: "" }}
          smallImage={{ src: "", caption: "" }}
          title={["through", "november"]}
          paragraph={`This exhibition explores the evolution of six influential TikTok videos and their remixes and remakes by users around the world.`}
          link="/"
          id="november"
          left={false}
          big={true}
        />
      </DefaultCreatorWrapper> */}

      {/* <DefaultCreatorWrapper>
        <ShapesContainer data-scroll-section>
          <Shape data={shapeData[currentMediaSize][0].circle}>
            <RedDot ref={redDotRef} src={redDot} />
          </Shape>
          <Shape data={shapeData[currentMediaSize][0].triangle}>
            <BlueTriangle ref={blueTriangleRef} src={blueTriangle} />
          </Shape>
          <Shape data={shapeData[currentMediaSize][0].diamonds}>
            <WhiteDiamonds ref={whiteDiamondsRef} src={whiteDiamonds} />
          </Shape>
        </ShapesContainer>

        <MuseumImage
          bigImage={{ src: duets, caption: "" }}
          smallImage={{ src: "", caption: "" }}
          title={["Infinite Duets:", "Co-creating", "On Tiktok"]}
          paragraph={`Creative catch,” a term coined by TikTok user @Absofacto, is a game of sequential creative collaboration: I make a video and then you edit, add, Duet, Stitch, or otherwise make it your own. On TikTok, millions of users play creative catch every day, an ever-expanding Duet made possible by the entertainment platform’s massive scale, creative tools, and its interest-based recommendation system.`}
          par2={`This exhibition explores the evolution of six influential TikTok videos and their remixes and remakes by users around the world. In addition to tracing the process of this creative collaboration, it explores co-creation in the context of new economic models, often called the “creator economy."`}
          link="/"
          id="duets"
          left={true}
          big={false}
        />
      </DefaultCreatorWrapper> */}
      <Museum />
      <Footer />
    </>
  );
};

const DefaultCreatorWrapper = styled.div`
  position: relative;
  margin-top: 20.8vw;

  ${media.tablet} {
    margin-top: 29.3vw;
  }

  ${media.mobile} {
    margin-top: 53.333vw;
  }
`;

const ShapesContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* border: 1px solid green; */
`;

const Shape = styled.div<{
  data: { top: string; left: string; angle: string };
}>`
  position: absolute;
  z-index: 20;
  top: ${(props) => props.data.top};
  left: ${(props) => props.data.left};
  transform: rotate(${(props) => props.data.angle});
  transition: all 400ms ease-out 300ms;
  ${media.mobile} {
    transition-delay: 0;
  }
`;

const ShapeImage = styled.img`
  height: auto;
  transition: all 50ms ease-in-out;
`;

const RedDot = styled(ShapeImage)`
  ${media.tablet} {
    width: 2.3vw;
  }
  ${media.mobile} {
    width: 3.5vw;
  }
`;
const BlueTriangle = styled(ShapeImage)`
  ${media.tablet} {
    width: 2.7vw;
  }
  ${media.mobile} {
    width: 4vw;
  }
`;
const WhiteDiamonds = styled(ShapeImage)`
  ${media.tablet} {
    width: 6.7vw;
  }
  ${media.mobile} {
    width: 9.7vw;
  }
`;
const TopGlow = styled.div`
  background: url(${topGlow}) center/contain no-repeat;
  height: 95vw;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  ${media.tablet} {
    height: 125vw;
    background-position: top;
    background-size: 135%;
  }
`;

const BottomGlow = styled.div`
  background: url(${bottomGlow}) center/contain no-repeat;
  height: 95vw;
  width: 100%;
  position: absolute;
  top: 185vw;
  right: -6vw;

  ${media.tablet} {
    right: 0;
    top: 170.53vw;
  }
`;

export default Exhibit;
