import { createContext, useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import styled from "styled-components";

import Scroll from "./utils/Scroll";
import { Bitski } from "bitski";

import Home from "./pages/Home";
import Drop from "./pages/Drop";
import Callback from "./pages/Callback";
import FourOhFour from "./pages/FourOhFour";
import Exhibit from "pages/Exhibit";

import Preloader from "./components/Preloader";
import FirstPreloader from "./components/FirstPreloader";
import RouteTransition from "components/RouteTransition";
import Header from "./components/Header";
import Footer from "./components/Footer";
import CookieBanner from "./components/CookieBanner";

import gsap from "gsap";
import { CustomEase } from "gsap/CustomEase";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

import { desktop, tablet, mobile } from "styles/media";
import colors from "styles/colors";

import { useMedia } from "utils/Hooks";

import lilNasXCollaborator from "assets/images/lilNasX/collaborator.jpg";
import lilNasXDesktop from "assets/images/lilNasX/product-desktop.jpg";
import lilNasXTablet from "assets/images/lilNasX/product-tablet.jpg";
import lilNasXMobile from "assets/images/lilNasX/product-mobile.jpg";
import lilNasXMeta from "assets/images/lilNasX/meta.jpg";

import rudyWillinghamCollaborator from "assets/images/rudyWillingham/collaborator.jpg";
import rudyWillinghamDesktop from "assets/images/rudyWillingham/full.jpg";
import rudyWillinghamMeta from "assets/images/rudyWillingham/meta.jpg";

import fnmekaCollaborator from "assets/images/fnmeka/collaborator.jpg";
import fnmekaDesktop from "assets/images/fnmeka/product-desktop.jpg";
import fnmekaTablet from "assets/images/fnmeka/product-tablet.jpg";
import fnmekaMobile from "assets/images/fnmeka/product-mobile.jpg";
import fnmekaMeta from "assets/images/fnmeka/meta.jpg";

import rtfktCollaborator from "assets/images/rtfkt/collaborator.jpg";
import rtfktImage from "assets/images/rtfkt/full.jpg";
import rtfktMeta from "assets/images/rtfkt/meta.jpg";

import bellaPoarchCollaborator from "assets/images/bellaPoarch/collaborator.jpg";
import bellaPoarchDesktop from "assets/images/bellaPoarch/product-desktop.jpg";
import bellaPoarchTablet from "assets/images/bellaPoarch/product-tablet.jpg";
import bellaPoarchMobile from "assets/images/bellaPoarch/product-mobile.jpg";
import bellaPoarchMeta from "assets/images/bellaPoarch/meta.jpg";

import grimes1Collaborator from "assets/images/grimes1/collaborator.jpg";
import grimes1Image from "assets/images/grimes1/full.jpg";
import grimes1Meta from "assets/images/grimes1/meta.jpg";

import curtisRoachCollaborator from "assets/images/curtisRoach/collaborator.jpg";
import curtisRoachDesktop from "assets/images/curtisRoach/product-desktop.jpg";
import curtisRoachTablet from "assets/images/curtisRoach/product-tablet.jpg";
import curtisRoachMobile from "assets/images/curtisRoach/product-mobile.jpg";
import curtisRoachMeta from "assets/images/curtisRoach/meta.jpg";

import coinArtistCollaborator from "assets/images/coinArtist/collaborator.jpg";
import coinArtistImage from "assets/images/coinArtist/full.jpg";
import coinArtistMeta from "assets/images/coinArtist/meta.jpg";

import garyVCollaborator from "assets/images/garyV/collaborator.jpg";
import garyVDesktop from "assets/images/garyV/product-desktop.jpg";
import garyVTablet from "assets/images/garyV/product-tablet.jpg";
import garyVMobile from "assets/images/garyV/product-mobile.jpg";
import garyVMeta from "assets/images/garyV/meta.jpg";

import jessMCollaborator from "assets/images/jessM/collaborator.jpg";
import jessMImage from "assets/images/jessM/full.jpg";
import jessMMeta from "assets/images/jessM/meta.jpg";

import x0rCollaborator from "assets/images/x0r/collaborator.jpg";
import x0rImage from "assets/images/x0r/full.jpg";
import x0rMeta from "assets/images/x0r/meta.jpg";

import brittanyBroskiCollaborator from "assets/images/brittanyBroski/collaborator.jpg";
import brittanyBroskiDesktop from "assets/images/brittanyBroski/home-desktop.jpg";
import brittanyBroskiTablet from "assets/images/brittanyBroski/home-tablet.jpg";
import brittanyBroskiMobile from "assets/images/brittanyBroski/home-mobile.jpg";
import brittanyBroskiMeta from "assets/images/brittanyBroski/meta.jpg";

import grimes2Collaborator from "assets/images/grimes2/collaborator.jpg";
import grimes2Image from "assets/images/grimes2/full.jpg";
import grimes2Meta from "assets/images/grimes2/meta.jpg";

import NFT from "assets/nfts/brittanyBroskiNFT.mp4";

require("dotenv").config();

export const ScreenContext = createContext<any>(null);
export const NavigateContext = createContext<any>(null);

gsap.registerPlugin(CustomEase, ScrollToPlugin);

CustomEase.create("card", "M0,0 C0.5,0 0,1 1,1 ");

function App() {
  const bitski = new Bitski(
    process.env.REACT_APP_BITSKI_CLIENT_ID,
    `${window.location.origin}/callback`
  );
  window.bitski = bitski;

  const [fw, setFw] = useState(window.innerWidth > desktop);
  const [d, setD] = useState(
    window.innerWidth <= desktop && window.innerWidth > tablet
  );
  const [t, setT] = useState(
    window.innerWidth <= tablet && window.innerWidth > mobile
  );
  const [m, setM] = useState(window.innerWidth <= mobile);
  const [navigate, setNavigate] = useState(null);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setFw(window.innerWidth > desktop);
      setD(window.innerWidth <= desktop && window.innerWidth > tablet);
      setT(window.innerWidth <= tablet && window.innerWidth > mobile);
      setM(window.innerWidth <= mobile);
    });

    return () => window.removeEventListener("resize", () => false);
  }, []);

  const firstLoaderRan = window.localStorage.getItem("firstLoaderRan");

  useEffect(() => {
    window.speed = 0;
    if (window.locomotiveScroll) {
      window.locomotiveScroll.on("scroll", (obj: any) => {
        let newSpeed = Math.min(Math.max(obj.speed.toFixed(2), -10), 10);
        if (window.speed !== newSpeed) window.speed = newSpeed;
      });
    }
  }, []);

  const lilNasImage = useMedia(
    lilNasXDesktop,
    lilNasXDesktop,
    lilNasXTablet,
    lilNasXMobile
  );

  const lilnasxData = {
    id: "lil-nas",
    auction: {
      productId: "5326ae32-5d95-428b-a87f-22e00131b872",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed viverra ipsum nunc aliquet bibendum enim facilisis gravida neque. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      collaborators: [
        {
          name: "Rudy Willingham",
          title: "creator",
          image: rudyWillinghamCollaborator,
        },
        {
          name: "Lil Nas X",
          title: "artist",
          image: lilNasXCollaborator,
        },
      ],
    },
    drops: {
      title: "Lil Nas X &\nRudy Willingham",
      mobileTitle: "Lil Nas X\n& Rudy\nWillingham",
      firstImage: {
        url: lilNasImage,
        alt: "Lil Nas X",
        name: "@LilNasX",
      },
      secondImage: {
        url: rudyWillinghamDesktop,
        alt: "Rudy Willingham",
        name: "@RudyWillingham",
      },
      nfts: [
        {
          title: "Top Moment NFT",
          link: "",
          state: {
            text: "Live Auction // 1 Edition",
            color: colors.razzmatazz100,
          },
        },
        {
          title: "TBD NFT Name",
          price: "$0.00",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed viverra ipsum nunc aliquet bibendum enim facilisis gravida neque. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          uri: NFT,
          mimeType: "video/mp4",
          link: "",
          state: {
            text: "Limited Edition // 75 Editions",
            color: colors.splash100,
          },
          bannerText: "Limited Edition #1",
        },
        {
          title: "TBD NFT Name",
          price: "$0.00",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed viverra ipsum nunc aliquet bibendum enim facilisis gravida neque. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          uri: NFT,
          mimeType: "video/mp4",
          link: "",
          state: {
            text: "Limited Edition // 75 Editions",
            color: colors.splash100,
          },
          bannerText: "Limited Edition #2",
        },
        {
          title: "TBD NFT Name",
          price: "$0.00",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed viverra ipsum nunc aliquet bibendum enim facilisis gravida neque. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          uri: NFT,
          mimeType: "video/mp4",
          link: "",
          state: {
            text: "Limited Edition // 75 Editions",
            color: colors.splash100,
          },
          bannerText: "Limited Edition #3",
        },
      ],
    },
    meta: {
      title: [
        <>two creators</>,
        <>
          <img src={rudyWillinghamMeta} alt="Rudy Willingham" /> join in the
        </>,
        <>
          metaverse <img src={lilNasXMeta} alt="Lil Nas X" />
        </>,
      ],
      banners: [
        {
          text: "Limited Edition",
          color: colors.offWhite,
          angle: "5",
          zIndex: 1,
          top: useMedia("19.6vw", "19.6vw", "19.6vw", "-3vw"),
        },
        {
          text: "Tiktok top moments powered by bitski",
          color: colors.richBlack100,
          angle: "-7.5",
          zIndex: 2,
          top: "12.6vw",
        },
        {
          text: "Rudy Willingham",
          color: colors.splash100,
          angle: "-15",
          zIndex: 3,
          top: window.innerWidth <= mobile ? "33vw" : "22.2vw",
        },
        {
          text: "Lil Nas X",
          color: colors.razzmatazz100,
          angle: "20",
          zIndex: 4,
          top: "17.3vw",
        },
      ],
    },
  };

  const fnmekaImage = useMedia(
    fnmekaDesktop,
    fnmekaDesktop,
    fnmekaTablet,
    fnmekaMobile
  );

  const fnmekaData = {
    id: "fnmeka",
    auction: {
      productId: "ef9f2b57-bbd3-424a-81d7-60cf712382a0",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed viverra ipsum nunc aliquet bibendum enim facilisis gravida neque. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      collaborators: [
        {
          name: "FNMeka",
          title: "creator",
          image: fnmekaCollaborator,
        },
        {
          name: "RTFKT",
          title: "artist",
          image: rtfktCollaborator,
        },
      ],
    },
    drops: {
      title: "FNMeka &\nRTFKT",
      mobileTitle: "FNMeka &\nRTFKT",
      firstImage: {
        url: fnmekaImage,
        alt: "FNMeka",
        name: "@FNMeka",
      },
      secondImage: {
        url: rtfktImage,
        alt: "RTFKT",
        name: "@RTFKT",
      },
      nfts: [
        {
          title: "Top Moment NFT",
          link: "",
          state: {
            text: "Live Auction // 1 Edition",
            color: colors.razzmatazz100,
          },
        },
        {
          title: "TBD NFT Name",
          price: "$0.00",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed viverra ipsum nunc aliquet bibendum enim facilisis gravida neque. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          uri: NFT,
          mimeType: "video/mp4",
          link: "",
          state: {
            text: "Limited Edition // 50 Editions",
            color: colors.splash100,
          },
          bannerText: "Limited Edition #1",
        },
      ],
    },
    meta: {
      title: [
        <>two creators</>,
        <>
          <img src={rtfktMeta} alt="Rudy Willingham" /> join in the
        </>,
        <>
          metaverse <img src={fnmekaMeta} alt="FNMeka" />
        </>,
      ],
      banners: [
        {
          text: "Limited Edition",
          color: colors.offWhite,
          angle: "5",
          zIndex: 1,
          top: useMedia("19.6vw", "19.6vw", "19.6vw", "-3vw"),
        },
        {
          text: "Tiktok top moments powered by bitski",
          color: colors.richBlack100,
          angle: "-7.5",
          zIndex: 2,
          top: "12.6vw",
        },
        {
          text: "RTFKT",
          color: colors.splash100,
          angle: "-15",
          zIndex: 3,
          top: useMedia("22.2vw", "22.2vw", "22.2vw", "33vw"),
        },
        {
          text: "FNMEKA",
          color: colors.razzmatazz100,
          angle: "20",
          zIndex: 4,
          top: "17.3vw",
        },
      ],
    },
  };

  const bellaPoarchImage = useMedia(
    bellaPoarchDesktop,
    bellaPoarchDesktop,
    bellaPoarchTablet,
    bellaPoarchMobile
  );

  const bellaPoarchData = {
    id: "bella-poarch",
    auction: {
      productId: "ef9f2b57-bbd3-424a-81d7-60cf712382a0",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed viverra ipsum nunc aliquet bibendum enim facilisis gravida neque. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      collaborators: [
        {
          name: "Bella Poarch",
          title: "creator",
          image: bellaPoarchCollaborator,
        },
        {
          name: "Grimes",
          title: "artist",
          image: grimes1Collaborator,
        },
      ],
    },
    drops: {
      title: "Bella Poarch\n& Grimes",
      mobileTitle: "FNMeka &\nGrimes",
      firstImage: {
        url: bellaPoarchImage,
        alt: "Bella Poarch",
        name: "@BellaPoarch",
      },
      secondImage: {
        url: grimes1Image,
        alt: "Grimes",
        name: "@Grimes",
      },
      nfts: [
        {
          title: "Top Moment NFT",
          link: "",
          state: {
            text: "Live Auction // 1 Edition",
            color: colors.razzmatazz100,
          },
        },
        {
          title: "TBD NFT Name",
          price: "$0.00",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed viverra ipsum nunc aliquet bibendum enim facilisis gravida neque. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          uri: NFT,
          mimeType: "video/mp4",
          link: "",
          state: {
            text: "Limited Edition // 50 Editions",
            color: colors.splash100,
          },
          bannerText: "Limited Edition #1",
        },
      ],
    },
    meta: {
      title: [
        <>two creators</>,
        <>
          <img src={grimes1Meta} alt="Grimes" /> join in the
        </>,
        <>
          metaverse <img src={bellaPoarchMeta} alt="Bella Poarch" />
        </>,
      ],
      banners: [
        {
          text: "Limited Edition",
          color: colors.offWhite,
          angle: "5",
          zIndex: 1,
          top: useMedia("19.6vw", "19.6vw", "19.6vw", "-3vw"),
        },
        {
          text: "Tiktok top moments powered by bitski",
          color: colors.richBlack100,
          angle: "-7.5",
          zIndex: 2,
          top: "12.6vw",
        },
        {
          text: "Grimes",
          color: colors.splash100,
          angle: "-15",
          zIndex: 3,
          top: useMedia("22.2vw", "22.2vw", "22.2vw", "33vw"),
        },
        {
          text: "Belle Poarch",
          color: colors.razzmatazz100,
          angle: "20",
          zIndex: 4,
          top: "17.3vw",
        },
      ],
    },
  };

  const curtisRoachImage = useMedia(
    curtisRoachDesktop,
    curtisRoachDesktop,
    curtisRoachTablet,
    curtisRoachMobile
  );

  const curtisRoachData = {
    id: "curtis-roach",
    auction: {
      productId: "ef9f2b57-bbd3-424a-81d7-60cf712382a0",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed viverra ipsum nunc aliquet bibendum enim facilisis gravida neque. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      collaborators: [
        {
          name: "Curtis Roach",
          title: "creator",
          image: curtisRoachCollaborator,
        },
        {
          name: "Coin Artist",
          title: "artist",
          image: coinArtistCollaborator,
        },
      ],
    },
    drops: {
      title: "Curtis Roach\n& Coin Artist",
      mobileTitle: "FNMeka &\nCoin Artist",
      firstImage: {
        url: curtisRoachImage,
        alt: "Curtis Roach",
        name: "@BellaPoarch",
      },
      secondImage: {
        url: coinArtistImage,
        alt: "Coin Artist",
        name: "@CoinArtist",
      },
      nfts: [
        {
          title: "Top Moment NFT",
          link: "",
          state: {
            text: "Live Auction // 1 Edition",
            color: colors.razzmatazz100,
          },
        },
        {
          title: "TBD NFT Name",
          price: "$0.00",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed viverra ipsum nunc aliquet bibendum enim facilisis gravida neque. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          uri: NFT,
          mimeType: "video/mp4",
          link: "",
          state: {
            text: "Limited Edition // 50 Editions",
            color: colors.splash100,
          },
          bannerText: "Limited Edition #1",
        },
      ],
    },
    meta: {
      title: [
        <>two creators</>,
        <>
          <img src={coinArtistMeta} alt="Coin Artist" /> join in the
        </>,
        <>
          metaverse <img src={curtisRoachMeta} alt="Curtis Roach" />
        </>,
      ],
      banners: [
        {
          text: "Limited Edition",
          color: colors.offWhite,
          angle: "5",
          zIndex: 1,
          top: useMedia("19.6vw", "19.6vw", "19.6vw", "-3vw"),
        },
        {
          text: "Tiktok top moments powered by bitski",
          color: colors.richBlack100,
          angle: "-7.5",
          zIndex: 2,
          top: "12.6vw",
        },
        {
          text: "Coin Artist",
          color: colors.splash100,
          angle: "-15",
          zIndex: 3,
          top: useMedia("22.2vw", "22.2vw", "22.2vw", "33vw"),
        },
        {
          text: "Curtis Roach",
          color: colors.razzmatazz100,
          angle: "20",
          zIndex: 4,
          top: "17.3vw",
        },
      ],
    },
  };

  const garyVImage = useMedia(
    garyVDesktop,
    garyVDesktop,
    garyVTablet,
    garyVMobile
  );

  const garyVData = {
    id: "gary-v",
    auction: {
      productId: "ef9f2b57-bbd3-424a-81d7-60cf712382a0",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed viverra ipsum nunc aliquet bibendum enim facilisis gravida neque. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      collaborators: [
        {
          name: "Gary Vaynerchuck",
          title: "creator",
          image: garyVCollaborator,
        },
        {
          name: "Jess Marciante",
          title: "artist",
          image: jessMCollaborator,
        },
        {
          name: "x0r",
          title: "artist",
          image: x0rCollaborator,
        },
      ],
    },
    drops: {
      title: "Gary Vaynerchuck,\nJess Marciante &\nx0r",
      mobileTitle: "FNMeka &\nJess Marciante",
      firstImage: {
        url: garyVImage,
        alt: "Gary Vaynerchuck",
        name: "@BellaPoarch",
      },
      secondImage: {
        url: jessMImage,
        alt: "Jess Marciante",
        name: "@JessMarciante",
      },
      thirdImage: {
        url: x0rImage,
        alt: "x0r",
        name: "@x0r",
      },
      nfts: [
        {
          title: "Top Moment NFT",
          link: "",
          state: {
            text: "Live Auction // 1 Edition",
            color: colors.razzmatazz100,
          },
        },
        {
          title: "TBD NFT Name",
          price: "$0.00",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed viverra ipsum nunc aliquet bibendum enim facilisis gravida neque. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          uri: NFT,
          mimeType: "video/mp4",
          link: "",
          state: {
            text: "Limited Edition // 50 Editions",
            color: colors.splash100,
          },
          bannerText: "Limited Edition #1",
        },
      ],
    },
    meta: {
      title: [
        <>
          three <img src={x0rMeta} alt="x0r" /> creators
        </>,
        <>
          <img src={jessMMeta} alt="Jess Marciante" /> join in the
        </>,
        <>
          metaverse <img src={garyVMeta} alt="Gary Vaynerchuck" />
        </>,
      ],
      banners: [
        {
          text: "Limited Edition",
          color: colors.offWhite,
          angle: "5",
          zIndex: 1,
          top: useMedia("19.6vw", "19.6vw", "19.6vw", "-3vw"),
        },
        {
          text: "Tiktok top moments powered by bitski",
          color: colors.richBlack100,
          angle: "-7.5",
          zIndex: 2,
          top: "12.6vw",
        },
        {
          text: "x0r",
          color: colors.splash100,
          angle: "-15",
          zIndex: 3,
          top: useMedia("22.2vw", "22.2vw", "22.2vw", "33vw"),
        },
        {
          text: "Jess Marciante",
          color: colors.razzmatazz100,
          angle: "20",
          zIndex: 4,
          top: "17.3vw",
        },
        {
          text: "Gary Vaynerchuk",
          color: colors.richBlack20,
          angle: "2.5",
          zIndex: 5,
          top: "17.3vw",
        },
      ],
    },
  };

  const brittanyBroskiImage = useMedia(
    brittanyBroskiDesktop,
    brittanyBroskiDesktop,
    brittanyBroskiTablet,
    brittanyBroskiMobile
  );

  const brittanyBroskiData = {
    id: "brittany-broski",
    auction: {
      productId: "ef9f2b57-bbd3-424a-81d7-60cf712382a0",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed viverra ipsum nunc aliquet bibendum enim facilisis gravida neque. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      collaborators: [
        {
          name: "Brittany Broski",
          title: "creator",
          image: brittanyBroskiCollaborator,
        },
        {
          name: "Grimes",
          title: "artist",
          image: grimes2Collaborator,
        },
      ],
    },
    drops: {
      title: "Brittany Broski\n& Grimes",
      mobileTitle: "Brittany Broski &\nGrimes",
      firstImage: {
        url: brittanyBroskiImage,
        alt: "Brittany Broski",
        name: "@Brittany Broski",
      },
      secondImage: {
        url: grimes2Image,
        alt: "Grimes",
        name: "@Grimes",
      },
      nfts: [
        {
          title: "Top Moment NFT",
          link: "",
          state: {
            text: "Live Auction // 1 Edition",
            color: colors.razzmatazz100,
          },
        },
        {
          title: "TBD NFT Name",
          price: "$0.00",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed viverra ipsum nunc aliquet bibendum enim facilisis gravida neque. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          uri: NFT,
          mimeType: "video/mp4",
          link: "",
          state: {
            text: "Limited Edition // 50 Editions",
            color: colors.splash100,
          },
          bannerText: "Limited Edition #1",
        },
      ],
    },
    meta: {
      title: [
        <>two creators</>,
        <>
          <img src={grimes2Meta} alt="Grimes" /> join in the
        </>,
        <>
          metaverse <img src={brittanyBroskiMeta} alt="Brittany Broski" />
        </>,
      ],
      banners: [
        {
          text: "Limited Edition",
          color: colors.offWhite,
          angle: "5",
          zIndex: 1,
          top: useMedia("19.6vw", "19.6vw", "19.6vw", "-3vw"),
        },
        {
          text: "Tiktok top moments powered by bitski",
          color: colors.richBlack100,
          angle: "-7.5",
          zIndex: 2,
          top: "12.6vw",
        },
        {
          text: "Grimes",
          color: colors.splash100,
          angle: "-15",
          zIndex: 3,
          top: useMedia("22.2vw", "22.2vw", "22.2vw", "33vw"),
        },
        {
          text: "Brittany Broski",
          color: colors.razzmatazz100,
          angle: "20",
          zIndex: 4,
          top: "17.3vw",
        },
      ],
    },
  };

  return (
    <NavigateContext.Provider value={setNavigate}>
      <ScreenContext.Provider
        value={{ fullWidth: fw, desktop: d, tablet: t, mobile: m }}
      >
        <Scroll />
        <Wrapper className="smooth-scroll">
          {firstLoaderRan && <Preloader />}
          {!firstLoaderRan && <FirstPreloader />}
          <RouteTransition trigger={navigate} setNavigate={setNavigate} />
          <CookieBanner />
          <Header />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path={["/lil-nas-x", "/rudy-willingham"]}>
              <Drop data={lilnasxData} />
            </Route>
            <Route exact path={["/fnmeka", "/rtfkt"]}>
              <Drop data={fnmekaData} />
            </Route>
            <Route exact path={["/bella-poarch", "/grimes-drop-1"]}>
              <Drop data={bellaPoarchData} />
            </Route>
            <Route exact path={["/curtis-roach", "/coin-artist"]}>
              <Drop data={curtisRoachData} />
            </Route>
            <Route exact path={["/gary-vee", "/x0r", "/jess-marciante"]}>
              <Drop data={garyVData} />
            </Route>
            <Route exact path={["/brittany-broski", "/grimes-drop-2"]}>
              <Drop data={brittanyBroskiData} />
            </Route>
            <Route exact path="/exhibit">
              <Exhibit/>
            </Route>
            <Route path="/callback">
              <Callback />
            </Route>
            <Route path="*">
              <FourOhFour />
            </Route>
          </Switch>
          <Footer />
        </Wrapper>
      </ScreenContext.Provider>
    </NavigateContext.Provider>
  );
}

const Wrapper = styled.div``;

export default App;
