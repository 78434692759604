import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import gsap from 'gsap'

import colors from 'styles/colors'
import media from 'styles/media'
import text from 'styles/text'

import { useMedia } from 'utils/Hooks'

import PrimaryButton from './PrimaryButton'

import { ReactComponent as ExitSVG } from 'assets/svg/exit.svg'

const CookieBanner: React.FC = () => {

  const wrapperRef = useRef(null)

  const closeBanner = () => {
    gsap.to(wrapperRef.current, {
      duration: 0.5,
      opacity: 0,
      onComplete: () => {
        window.locomotiveScroll.start()

        gsap.set(wrapperRef.current, {
          display: 'none'
        })
      }
    })
  }

  const handleAccept = () => {
    closeBanner()
  }

  useEffect(() => {
    gsap.fromTo(wrapperRef.current, {
      display: 'flex'
    }, {
      delay: 5.5,
      duration: 0.5,
      opacity: 1,
      onComplete: () => {
        window.locomotiveScroll.stop()
      }
    })
  }, [])

  return (
    <Wrapper ref={wrapperRef}>
      <Banner>
        <Title>{'// Cookies settings'}</Title>
        <Bottom>
          <Body>We use cookies on this website to enhance your experience. By continuing to browse this site you are agreeing to our Privacy Policy.</Body>
          <PrimaryButton onClick={handleAccept} textAlign="center" width={useMedia("11.319vw", "11.319vw", "16vw", "100%")} noArrow={true}>Accept All</PrimaryButton>
        </Bottom>
        <Exit onClick={closeBanner}/>
      </Banner>
    </Wrapper>
  )
}

export default CookieBanner

const Wrapper = styled.div`
  display: none;
  position: fixed; 
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  opacity: 0;
`

const Banner = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid ${colors.offWhite};
  background-color: ${colors.richBlack100};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  bottom: 3.194vw;
  height: 13.333vw;
  width: 62.083vw;
  padding: 2.778vw;

  ${media.tablet} {
    bottom: 8.887vw;
    height: 18.75vw;
    width: 87.305vw;
    padding: 3.809vw;
  }

  ${media.mobile} {
    bottom: 10.133vw;
    height: auto;
    width: 86.933vw;
    padding: 6.667vw;
  }
`

const Title = styled.span`
  width: 100%;
  text-transform: uppercase;
  color: ${colors.offWhite};

  ${text.desktop.priceText}

  ${media.tablet} {
    ${text.tablet.priceText}
  }

  ${media.mobile} {
    ${text.mobile.buttonText}
    margin-bottom: 5.333vw;
  }
`

const Bottom = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.mobile} {
    flex-direction: column;
  }
`

const Body = styled.p`
  color: ${colors.offWhite};

  width: 43.403vw;
  ${text.desktop.headerBodyCopy}

  ${media.tablet} {
    ${text.tablet.headerBodyCopy}
    width: 61.035vw;
  }

  ${media.mobile} {
    ${text.mobile.headerBodyCopy}
    width: 100%;
    margin-bottom: 10.667vw
  }
`

const Exit = styled(ExitSVG)`
  position: absolute;
  height: auto;
  cursor: pointer;

  width: 1.042vw;
  top: 3.09vw;
  right: 3.09vw;
  
  ${media.tablet} {
    width: 1.465vw;
    top: 4.346vw;
    right: 4.346vw;
  }

  ${media.mobile} {
    width: 4vw;
    top: 7.867vw;
    right: 7.867vw;
  }
`
