import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import gsap from 'gsap'

import colors from 'styles/colors'

import { useMedia } from 'utils/Hooks'

interface props {
  trigger: string
  setNavigate: any
}

const RouteTransition: React.FC<props> = ({ trigger, setNavigate }) => {
  const history = useHistory()

  const wrapperRef = useRef(null)
  const blueDivRef = useRef(null)
  const pinkDivRef = useRef(null)

  const pinkHeight = useMedia('3vw', '3vw', '7vw', '20vw')
  const blueHeight = useMedia('6vw', '6vw', '11vw', '25vw')

  useEffect(() => {
    if (trigger) {

      const tl1 = gsap.timeline({
        onComplete: () => {
          history.push(trigger)
          window.locomotiveScroll.update()
          window.locomotiveScroll.scrollTo(0)
        }
      })
  
      tl1.fromTo(wrapperRef.current, {
        display: 'flex',
        bottom: '0vw',
        top: "unset"
      }, {
        duration: 1.5,
        height: '100vh',
        ease: "circ.inOut"
      }, 0)

      tl1.set([blueDivRef.current, pinkDivRef.current], {
        top: "0vw",
        bottom: "unset"
      }, 0)
  
      tl1.to(blueDivRef.current, {
        duration: 0.75,
        height: blueHeight,
        ease: "circ.in"
      }, 0)
  
      tl1.to(pinkDivRef.current, {
        duration: 0.75,
        height: pinkHeight,
        ease: "circ.in"
      }, 0)
      
      tl1.to(blueDivRef.current, {
        duration: 0.75,
        height: "0vw",
        ease: "circ.out"
      }, 0.75)
  
      tl1.to(pinkDivRef.current, {
        duration: 0.75,
        height: "0vw",
        ease: "circ.out"
      }, 0.75)

      const tl = gsap.timeline({
        delay: 1.5,
        onComplete: () => {
          window.locomotiveScroll.update()
          setNavigate(null)
          gsap.set(wrapperRef.current, {
            display: 'none'
          })
        }
      })
      tl.fromTo(wrapperRef.current, {
        bottom: 'unset',
        top: '0vw'
      }, {
        duration: 1.5,
        height: '0vw',
        ease: "circ.inOut"
      }, 1.5)

      tl.set([blueDivRef.current, pinkDivRef.current], {
        top: "unset",
        bottom: "0vw"
      }, 1.5)

      tl.to(blueDivRef.current, {
        duration: 0.75,
        height: blueHeight,
        ease: "circ.in"
      }, 1.5)
  
      tl.to(pinkDivRef.current, {
        duration: 0.75,
        height: pinkHeight,
        ease: "circ.in"
      }, 1.5)
      
      tl.to(blueDivRef.current, {
        duration: 0.75,
        height: "0vw",
        ease: "circ.out"
      }, 2.25)
  
      tl.to(pinkDivRef.current, {
        duration: 0.75,
        height: "0vw",
        ease: "circ.out"
      }, 2.25)
  
      return () => {
        tl.kill()
        tl1.kill()
      }
    }
  }, [trigger, wrapperRef, pinkDivRef, blueDivRef, history, blueHeight, pinkHeight, setNavigate])

  return (
    <Wrapper ref={wrapperRef}>
      <PinkDiv ref={pinkDivRef}/>
      <BlueDiv ref={blueDivRef}/>
    </Wrapper>
  )
}

export default RouteTransition

const Wrapper = styled.div`
  position: fixed;
  z-index: 99;
  background-color: ${colors.richBlack100};
  width: 100vw;
  height: 0vh;
  top: 0;
  left: 0;
  display: none;
`

const PinkDiv = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0%;
  background-color: ${colors.razzmatazz100};
  z-index: 2;
`

const BlueDiv = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0%;
  background-color: ${colors.splash100};
  z-index: 1;
`
