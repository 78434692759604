import React, { useRef, useEffect, useContext } from 'react'
import styled from 'styled-components'
import gsap from 'gsap'
import { ScreenContext } from 'App'

import media from 'styles/media'

import { ReactComponent as arrowSVG } from 'assets/images/symbols/arrow.svg'

type props = {
  backgroundColor: string
  arrowColor: string
  mouseEnter?: boolean
}

const Arrow: React.FC<props> = ({
  backgroundColor,
  arrowColor,
  mouseEnter
}) => {

  const screen = useContext(ScreenContext)

  const arrow1Ref = useRef(null)
  const arrow2Ref = useRef(null)

  useEffect(() => {
    if (screen.fullWidth || screen.desktop) {
      if (mouseEnter) {
        handleMouseEnter()
      } else {
        handleMouseLeave()
      }
    }
  }, [mouseEnter, screen.fullWidth, screen.desktop])

  const handleMouseEnter = (e?: any) => {
    const tl = gsap.timeline()

    tl.set(arrow2Ref.current, {
      top: "130%",
      left: "-90%"
    })
    tl.to(arrow1Ref.current, {
      duration: 0.5,
      left: "130%",
      top: "-90%",
      ease: 'circ.inOut'
    }, 0)
    tl.to(arrow2Ref.current, {
      duration: 0.5,
      top: "20%",
      left: "50%",
      ease: 'circ.inOut'
    }, 0)
  }

  const handleMouseLeave = (e?: any) => {

    const tl = gsap.timeline()

    tl.set(arrow1Ref.current, {
      top: "130%",
      left: "-90%"
    })
    tl.to(arrow2Ref.current, {
      duration: 0.5,
      left: "130%",
      top: "-90%",
      ease: 'circ.inOut'
    }, 0)
    tl.to(arrow1Ref.current, {
      duration: 0.5,
      top: "20%",
      left: "50%",
      ease: 'circ.inOut'
    }, 0)
  }

  return (
    <Wrapper backgroundColor={backgroundColor}>
      <Arrow1 ref={arrow1Ref} color={arrowColor}/>
      {(screen.fullWidth || screen.desktop) && <Arrow2 ref={arrow2Ref} color={arrowColor}/>}
    </Wrapper>
  )
}

export default Arrow

const Wrapper = styled.div<{backgroundColor: string}>`
  background-color: ${props => props.backgroundColor};
  position: relative;
  overflow: hidden;

  ${media.desktop} {
    width: 5.556vw;
    height: 5.556vw;
  }

  ${media.tablet} {
    width: 2.441vw;
    height: 2.441vw;
  }

  ${media.mobile} {
    width: 3.333vw;
    height: 3.333vw;
  }
`

const Arrow1 = styled(arrowSVG)<{color: string}>`
  height: auto;
  position: absolute;

  path {
    stroke: ${props => props.color};
  }

  ${media.desktop} {
    width: 1.736vw;
    top: 20%;
    left: 50%;
  }

  ${media.tablet} {
    width: 100%;
  }

  ${media.mobile} {
    width: 100%;
  }
`

const Arrow2 = styled(arrowSVG)<{color: string}>`
  height: auto;
  position: absolute;

  path {
    stroke: ${props => props.color};
  }

  ${media.desktop} {
    width: 1.736vw;
    top: 130%;
    left: -90%;
  }
`
