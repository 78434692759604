import React, { useContext, useState, useEffect, useRef } from "react"
import { ScreenContext } from "App"
import styled from "styled-components"
import gsap from 'gsap'
import colors from "styles/colors"
import FAQQuestion from '../../components/FAQQuestion'

import redDot from '../../assets/svg/redDot.svg'
import blueTriangle from '../../assets/svg/blueTriangle.svg'
import whiteDiamonds from '../../assets/svg/whiteDiamonds.svg'
import { useMemo } from "react"
import Media from "styles/media"

const faqData = [{
  title: 'What is an NFT?',
  answerHTML: <p>NFTs (Non-Fungible Tokens) are a way of proving ownership of unique digital goods such as videos, art, in-game gear, tickets, club membership and more.<br/><br/>You can think of some NFTs as digital trading cards. Just like trading cards, NFTs can be owned, collected and sold. When you buy an NFT, or re-sell it, the original creator of the NFTs -- in this case your favorite TikTok creator -- gets a share of the profits.<br/><br/>Under the hood, NFTs act as digital ownership certificates that live on a universal ledger called the blockchain that certify the uniqueness of digital goods.<br/><br/>As a result, NFTs allow creators to sell their digital work such as video creations, virtual sneakers and more, which empowers a whole new digital economy. This is how the metaverse begins!<br/><br/>You can read more about NFTs <a href="https://www.theverge.com/22310188/nft-explainer-what-is-blockchain-crypto-art-faq" target="_blank" rel="noopener noreferrer">here</a>.</p>,
  glow: {
    color: colors.razzmatazz100,
    positionX: { desktop: 0.031, tablet: 0.539, mobile: 0.551 },
    positionY: { desktop: 0.403, tablet: 0.030, mobile: 0.158 }
  }
},
{
  title: 'Why is TikTok entering the NFT game?',
  answerHTML: <p>Inspired by the creativity and innovation on the platform, TikTok saw a chance to recognize and empower the Creator community through NFTs. NFTs are a new way for Creators to monetize their content and we want to support them on this journey. The creation that happens on TikTok helps drive culture and starts trends that impact our society. We want to bring something unique and groundbreaking to the NFT landscape by curating some of these cultural milestones and pairing them with established NFT artists. Now, you can own a moment on TikTok that broke the internet and is setting the stage for what creator independence could look like.</p>,
  glow: {
    color: colors.splash100,
    positionX: { desktop: 0.085, tablet: 0.253, mobile: 0.411 },
    positionY: { desktop: 0.532, tablet: 0.094, mobile: 0.158 }
  }
}, {
  title: 'How do I buy an NFT?',
  answerHTML: <p>You can purchase an NFT as easily as buying a t-shirt online since Bitski accepts credit cards. The NFT will be added (or “minted”) to the blockchain at the time of purchase, and you will need a wallet in order to hold your NFTs.</p>,
  glow: {
    color: colors.razzmatazz100,
    positionX: { desktop: 0.020, tablet: 0.716, mobile: 0.752 },
    positionY: { desktop: 0.056, tablet: 0.030, mobile: 0.158 }
  }
}, {
  title: 'Do I need to set up a wallet in advance?',
  answerHTML: <>
  <p>Not at all! At the time of purchase or bidding on an NFT, you can create a secure Bitski wallet. Your data and wallet is fully protected since Bitski stores these in their servers so you never need to worry about losing your private key to access your wallet to view and buy NFTs.<br/><br/>If you prefer to go through the process of setting up a wallet and acquiring ETH in advance for bidding, you can still do that with Bitski or use Metamask instead.</p>
  <ul>
    <li>To set up your Bitski wallet, simply purchase or bid on an NFT (and you will be prompted to set up a Bitski wallet), or visit wallet.bitski.com.</li>
    <li>To set up your Metamask wallet, download their iOS or Android app. If you’re on a browser, you can download their Chrome extension <a href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en" target="_blank" rel="noopener noreferrer">here</a>.</li>
  </ul>
  <p>Other wallets require that you add government-backed currency and exchange it for cryptocurrency prior to purchasing an NFT, but with Bitski, we make it easy for you by simply using your credit card and providing a wallet at the point of purchase.</p>
  </>,
  glow: {
    color: colors.splash100,
    positionX: { desktop: 0.085, tablet: 0.030, mobile: -0.016 },
    positionY: { desktop: 0.761, tablet: 0.094, mobile: 0.158 }
  }
}, {
  title: 'Where can I see my NFT?',
  answerHTML: <p>After purchasing a limited edition NFT, it might take up to X minutes for it to show up in your wallet on wallet.bitski.com. Under the hood, this is when the NFT gets created (or "minted") on the blockchain and assigned to your wallet. Congrats! You are now the owner of a Limited Edition NFT.<br/><br/>Your NFT will automatically appear in your Bitski wallet once it’s created (“minted”) onto the blockchain. You can log into your Bitski wallet (wallet.bitski.com) to view your NFTs directly from your browser, as well as key information about your NFT including the edition number.<br/><br/>If you are the winner of an auction, the NFT will be sent to the wallet you used for your winning bid once the auction concludes. While you can’t view the NFT natively in Metamask, you can download the Metamask app and look under the “collectibles” tab there.</p>,
  glow: {
    color: colors.razzmatazz100,
    positionX: { desktop: 0.020, tablet: 0.571, mobile: 0.504 },
    positionY: { desktop: 0.205, tablet: 0.030, mobile: 0.158 }
  }
}, {
  title: 'What can I do with my NFT?',
  answerHTML: <>
  <p>Outside of knowing that you supported a creator you love and are getting the privilege of bragging rights, you can:</p>
  <ul>
    <li>Sell your NFTs onto a secondary marketplace like OpenSea for profit. All you need to do is sign on to OpenSea and select your Bitski or Metamask wallet to upload your NFT for sale. The fun part is that both you and the NFT creators get to partake in any profits earned.</li>
    <li>Connect your Bitski wallet to digital galleries like OnCyber.io to view your NFTs in beautiful environments from temples to spaceships. </li>
    <li>Show it off by changing your profile picture on TikTok </li>
    <li>Create your own merch with the art (i.e. posters, t-shirts, phone screensavers, etc.)</li>
    <li>Redeem the utility attached to your NFT (?)</li>
  </ul>
  <p>We’re also working on ensuring there are more homes and use cases for your NFTs. Since the Bitski wallet can plug into various “places” in the metaverse and help creators sell NFTs that have special attributes such as power-ups in games, this is just the beginning for creators and users alike!</p>
  </>,
  glow: {
    color: colors.splash100,
    positionX: { desktop: 0.260, tablet: 0.481, mobile: 0.781 },
    positionY: { desktop: 0.306, tablet: 0.264, mobile: 0.158 }
  }
}, {
  title: 'What forms of payment are accepted?',
  answerHTML: <p>We accept credit card payments for limited edition NFTs. For the 1/1 auctions, buyers can bid with ETH using either their Bitski or Metamask wallet.</p>,
  glow: {
    color: colors.razzmatazz100,
    positionX: { desktop: 0.020, tablet: 0.519, mobile: 0.053 },
    positionY: { desktop: 0.259, tablet: 0.030, mobile: 0.158 }
  }
}]

const shapeData = {
  desktop: [{
    circle:   { top: '18.3vw', left: '4.1vw', angle: "0deg" },
    triangle: { top: '46.8vw', left: '60vw', angle: "-137deg" },
    diamonds: { top: '5vw', left: '58.5vw', angle: "47deg" },
  },
  {
    circle:   { top: '48.7vw', left: '12.8vw', angle: "0deg" },
    triangle: { top: '20.6vw', left: '67.4vw', angle: "140deg" },
    diamonds: { top: '5.8vw', left: '8.1vw', angle: "-48deg" },
  },
  {
    circle:   { top: '41.3vw', left: '68.5vw', angle: "0deg" },
    triangle: { top: '3.1vw', left: '70.1vw', angle: "55deg" },
    diamonds: { top: '35.2vw', left: '21.2vw', angle: "-126deg" },
  },
  {
    circle:   { top: '4.4vw', left: '73.3vw', angle: "0deg" },
    triangle: { top: '8.1vw', left: '20.6vw', angle: "-34deg" },
    diamonds: { top: '46.3vw', left: '74.6vw', angle: "150deg" },
  },
  {
    circle:   { top: '13.1vw', left: '25.1vw', angle: "0deg" },
    triangle: { top: '50.3vw', left: '27.4vw', angle: "-139.5deg" },
    diamonds: { top: '4.3vw', left: '78.8vw', angle: "39deg" },
  },
  {
    circle:   { top: '46.2vw', left: '31.9vw', angle: "0deg" },
    triangle: { top: '34.5vw', left: '86.2vw', angle: "-139.5deg" },
    diamonds: { top: '2.8vw', left: '31.5vw', angle: "-41deg" },
  },
  {
    circle:   { top: '33.1vw', left: '88.1vw', angle: "0deg" },
    triangle: { top: '6.6vw', left: '36.5vw', angle: "94deg" },
    diamonds: { top: '40.8vw', left: '42.6vw', angle: "-126deg" },
  }],
  tablet: [{
    circle:   { top: '36.0vw', left: '6.7vw', angle: "0deg" },
    triangle: { top: '59.2vw', left: '81.6vw', angle: "-139deg" },
    diamonds: { top: '4.6vw', left: '71.4vw', angle: "48deg" },
  },
  {
    circle:   { top: '76.2vw', left: '47.7vw', angle: "0deg" },
    triangle: { top: '26.6vw', left: '82.2vw', angle: "137deg" },
    diamonds: { top: '27.2vw', left: '3.0vw', angle: "-32deg" },
  },
  {
    circle:   { top: '68.4vw', left: '82.9vw', angle: "0deg" },
    triangle: { top: '36.7vw', left: '61.6vw', angle: "82deg" },
    diamonds: { top: '74.1vw', left: '16.2vw', angle: "-134deg" },
  },
  {
    circle:   { top: '53.2vw', left: '85.2vw', angle: "0deg" },
    triangle: { top: '56.3vw', left: '8.4vw', angle: "-14deg" },
    diamonds: { top: '104.5vw', left: '65.1vw', angle: "166deg" },
  },
  {
    circle:   { top: '71.3vw', left: '4.9vw', angle: "0deg" },
    triangle: { top: '114.7vw', left: '31.6vw', angle: "-123deg" },
    diamonds: { top: '61.3vw', left: '78.6vw', angle: "68deg" },
  },
  {
    circle:   { top: '135.4vw', left: '10.6vw', angle: "0deg" },
    triangle: { top: '121.3vw', left: '89.1vw', angle: "173deg" },
    diamonds: { top: '86.4vw', left: '1.8vw', angle: "-14deg" },
  },
  {
    circle:   { top: '144.9vw', left: '89.0vw', angle: "0deg" },
    triangle: { top: '114.0vw', left: '26.5vw', angle: "91deg" },
    diamonds: { top: '142.7vw', left: '4.9vw', angle: "-98deg" },
  }],
  mobile: [{
    circle:   { top: '118.4vw', left: '-1.8vw', angle: "0deg" },
    triangle: { top: '188.3vw', left: '86.9vw', angle: "-139deg" },
    diamonds: { top: '60.8vw', left: '84vw', angle: "47deg" },
  },
  {
    circle:   { top: '59.7vw', left: '-0.8vw', angle: "0deg" },
    triangle: { top: '178.7vw', left: '-1.9vw', angle: "-139deg" },
    diamonds: { top: '162.3vw', left: '92vw', angle: "105deg" },
  },
  {
    circle:   { top: '65.9vw', left: '88.8vw', angle: "0deg" },
    triangle: { top: '68.5vw', left: '-2.4vw', angle: "-139deg" },
    diamonds: { top: '121.6vw', left: '7.7vw', angle: "-159deg" },
  },
  {
    circle:   { top: '263.7vw', left: '91.7vw', angle: "0deg" },
    triangle: { top: '192vw', left: '-1vw', angle: "-139deg" },
    diamonds: { top: '89.6vw', left: '83.2vw', angle: "-73deg" },
  },
  {
    circle:   { top: '310.9vw', left: '60.5vw', angle: "0deg" },
    triangle: { top: '110.4vw', left: '36vw', angle: "-34deg" },
    diamonds: { top: '174.7vw', left: '86.1vw', angle: "35deg" },
  },
  {
    circle:   { top: '390vw', left: '14.1vw', angle: "0deg" },
    triangle: { top: '173.5vw', left: '85.9vw', angle: "87deg" },
    diamonds: { top: '287.6vw', left: '93.6vw', angle: "125deg" },
  },
  {
    circle:   { top: '172.6vw', left: '-1.9vw', angle: "0deg" },
    triangle: { top: '202.1vw', left: '85.9vw', angle: "-173deg" },
    diamonds: { top: '216.2vw', left: '22.7vw', angle: "-176deg" },
  }]
}

const FAQ: React.FC<{}> = () => {
  const [FAQIndex, setFAQIndex] = useState(0)
  const redDotRef = useRef<HTMLImageElement>(null)
  const blueTriangleRef = useRef<HTMLImageElement>(null)
  const whiteDiamondsRef = useRef<HTMLImageElement>(null)

  const screen = useContext(ScreenContext)

  const currentMediaSize = useMemo(() => screen.fullWidth || screen.desktop ? 'desktop' : screen.tablet ? 'tablet' : 'mobile', [screen])

  const handleMouseMove = (e: MouseEvent) => {
    let normalizedX = -e.clientX / window.innerWidth + 0.5
    let normalizedY = -e.clientY / window.innerHeight + 0.5
    gsap.to([redDotRef.current, blueTriangleRef.current, whiteDiamondsRef.current], { x: normalizedX * 10, y: normalizedY * 10 })
  }

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove)
    return () => window.removeEventListener('mousemove', handleMouseMove)
  }, [])

  const handleClick = (index: number) => {
    setFAQIndex(index)
    if (screen.mobile) {
      let target = document.getElementById('faqContainer').children[index]
      setTimeout(() => {
        window.locomotiveScroll.scrollTo(target, { duration: 200 })
      }, 210);
    }
  }

  const questions = faqData.map((questionObj, index) => {
    let className = index > FAQIndex ? 'question-next' : index < FAQIndex ? 'question-previous' : 'question-current'
    return <FAQQuestion className={className} key={index} question={questionObj} handleClick={handleClick} index={index}/>
  })

  return <Wrapper data-scroll-section>
    <ShapesContainer>
      <Shape data={shapeData[currentMediaSize][FAQIndex].circle}>
        <RedDot ref={redDotRef} src={redDot}/>
      </Shape>
      <Shape data={shapeData[currentMediaSize][FAQIndex].triangle}>
        <BlueTriangle ref={blueTriangleRef} src={blueTriangle}/>
      </Shape>
      <Shape data={shapeData[currentMediaSize][FAQIndex].diamonds}>
        <WhiteDiamonds ref={whiteDiamondsRef} src={whiteDiamonds}/>
      </Shape>
    </ShapesContainer>
    <QuestionsContainer id="faqContainer">
      {questions}
    </QuestionsContainer>
  </Wrapper>
}

const Wrapper = styled.section`
  position: relative;

  ${Media.desktop} {
    margin-bottom: 13.889vw;
  }

  ${Media.tablet} {
    margin-bottom: 24.316vw;
  }

  ${Media.mobile} {
    margin-bottom: 54.933vw;
  }
`

const ShapesContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Shape = styled.div<{ data: { top: string; left: string; angle: string; } }>`
  position: absolute;
  z-index: 20;
  top: ${props => props.data.top};
  left: ${props => props.data.left};
  transform: rotate(${props => props.data.angle});
  transition: all 400ms ease-out 300ms;
  ${Media.mobile} {
    transition-delay: 0;
  }
`

const ShapeImage = styled.img`
  height: auto;
  transition: all 50ms ease-in-out;
`

const RedDot = styled(ShapeImage)`
  ${Media.tablet} {
    width: 2.3vw;
  }
  ${Media.mobile} {
    width: 3.5vw;
  }
`
const BlueTriangle = styled(ShapeImage)`
  ${Media.tablet} {
    width: 2.7vw;
  }
  ${Media.mobile} {
    width: 4vw;
  }
`
const WhiteDiamonds = styled(ShapeImage)`
  ${Media.tablet} {
    width: 6.7vw;
  }
  ${Media.mobile} {
    width: 9.7vw;
  }
`

const QuestionsContainer = styled.div`
  display: flex;
  border-right: 1px solid ${colors.offWhite};
  ${Media.tablet} {
    flex-direction: column;
    border-right: none;
    border-bottom: 1px solid ${colors.offWhite};
    height: 155.8vw;
  }
  ${Media.mobile} {
    flex-direction: column;
    border-right: none;
    border-bottom: 1px solid ${colors.offWhite};
    height: auto;
  }
`

export default FAQ
