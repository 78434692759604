import React, { useEffect, useRef, useCallback, } from 'react'
import styled from 'styled-components'
import gsap from 'gsap'
import { useProgress } from '@react-three/drei'

import { useMedia } from 'utils/Hooks'

import colors from 'styles/colors'
import media from 'styles/media'

const Preloader: React.FC = () => {

  const progress = useProgress(state => state.progress)
  const total = useProgress(state => state.total)
  const lineRef = useRef(null)
  const letter1Ref = useRef(null)
  const letter2Ref = useRef(null)
  const blueDivRef = useRef(null)
  const pinkDivRef = useRef(null)
  const wrapperRef = useRef(null)
  const pinkDiv2Ref = useRef(null)
  const blueDiv2Ref = useRef(null)
  const coverRef = useRef(null)

  const pinkHeight = useMedia('3vw', '3vw', '7vw', '20vw')
  const blueHeight = useMedia('6vw', '6vw', '11vw', '25vw')
  const checkIfDone = useCallback((progress: number) => {
    if (progress === 100) {
      const tl = gsap.timeline({
        delay: 0.5,
        onComplete: () => {
          window.locomotiveScroll.start()
          tl.kill()
          gsap.set(wrapperRef.current, {
            display: 'none'
          })
        }
      })

      tl.to(wrapperRef.current, {
        duration: 1.5,
        height: '0vw',
        ease: "circ.inOut"
      })

      tl.to(blueDivRef.current, {
        duration: 0.75,
        height: blueHeight,
        ease: "circ.in"
      }, 0)

      tl.to(pinkDivRef.current, {
        duration: 0.75,
        height: pinkHeight,
        ease: "circ.in"
      }, 0)

      tl.to(blueDivRef.current, {
        duration: 0.75,
        height: "0vw",
        ease: "circ.out"
      }, 0.75)

      tl.to(pinkDivRef.current, {
        duration: 0.75,
        height: "0vw",
        ease: "circ.out"
      }, 0.75)

      return () => {
        tl.kill()
      }
    }
  }, [pinkHeight, blueHeight])

  const numberAnimation = useCallback(() => {
    let loadProgress = !total ? 100 : progress
    if (loadProgress) {
      let value = 1340 - (loadProgress * 13.4)

      let val1 = Math.floor(loadProgress / 10)
      let val2 = loadProgress

      const tl = gsap.timeline({
        onComplete: () => {
          tl.kill()
          checkIfDone(loadProgress)
        }
      })

      tl.to(lineRef.current, {
        duration: 1,
        strokeDashoffset: value
      }, 0)

      tl.to(letter2Ref.current, {
        duration: 1,
        y: `-${val2 * 0.9899}%`,
        ease: "circ.inOut"
      }, 0)

      tl.to(letter1Ref.current, {
        duration: 1,
        y: `-${val1 * 9}%`,
        ease: "circ.inOut"
      }, 0)

      return () => {
        tl.kill()
      }
    }

  }, [progress, total, checkIfDone])

  useEffect(() => {
    window.locomotiveScroll.stop()

    const tl = gsap.timeline({
      onComplete: () => {
        tl.kill()
        numberAnimation()
      }
    })

    tl.to(coverRef.current, {
      duration: 1,
      height: '0vh',
      ease: "circ.inOut"
    }, 0)
    tl.to(pinkDiv2Ref.current, {
      duration: 0.5,
      height: pinkHeight,
      ease: "circ.in"
    }, 0)
    tl.to(pinkDiv2Ref.current, {
      duration: 0.5,
      height: "0vw",
      ease: "circ.out"
    }, 0.5)
    tl.to(blueDiv2Ref.current, {
      duration: 0.5,
      height: blueHeight,
      ease: "circ.in"
    }, 0)
    tl.to(blueDiv2Ref.current, {
      duration: 0.5,
      height: "0vw",
      ease: "circ.out"
    }, 0.5)

    return () => {
      tl.kill()
    }
  }, [numberAnimation, pinkHeight, blueHeight])

  return (
    <Wrapper ref={wrapperRef}>
      <Cover ref={coverRef}>
        <PinkDiv ref={pinkDiv2Ref}/>
        <BlueDiv ref={blueDiv2Ref}/>
      </Cover>
      <Content>
        <Svg viewBox="0 0 1340 10">
          <Line1 x1={0} x2={1340} y1={0} y2={0} />
          <Line ref={lineRef} strokeDashoffset="1340" strokeDasharray="1340" x1={0} x2={1340} y1={0} y2={0} />
        </Svg>
        <Counter>
          <LetterWrapper ref={letter1Ref}>
            <Letter>0</Letter>
            <Letter>1</Letter>
            <Letter>2</Letter>
            <Letter>3</Letter>
            <Letter>4</Letter>
            <Letter>5</Letter>
            <Letter>6</Letter>
            <Letter>7</Letter>
            <Letter>8</Letter>
            <Letter>9</Letter>
          </LetterWrapper>
          <LetterWrapper2 ref={letter2Ref}>
            <Letter>0</Letter>
            <Letter>1</Letter>
            <Letter>2</Letter>
            <Letter>3</Letter>
            <Letter>4</Letter>
            <Letter>5</Letter>
            <Letter>6</Letter>
            <Letter>7</Letter>
            <Letter>8</Letter>
            <Letter>9</Letter>
            <Letter>0</Letter>
            <Letter>1</Letter>
            <Letter>2</Letter>
            <Letter>3</Letter>
            <Letter>4</Letter>
            <Letter>5</Letter>
            <Letter>6</Letter>
            <Letter>7</Letter>
            <Letter>8</Letter>
            <Letter>9</Letter>
            <Letter>0</Letter>
            <Letter>1</Letter>
            <Letter>2</Letter>
            <Letter>3</Letter>
            <Letter>4</Letter>
            <Letter>5</Letter>
            <Letter>6</Letter>
            <Letter>7</Letter>
            <Letter>8</Letter>
            <Letter>9</Letter>
            <Letter>0</Letter>
            <Letter>1</Letter>
            <Letter>2</Letter>
            <Letter>3</Letter>
            <Letter>4</Letter>
            <Letter>5</Letter>
            <Letter>6</Letter>
            <Letter>7</Letter>
            <Letter>8</Letter>
            <Letter>9</Letter>
            <Letter>0</Letter>
            <Letter>1</Letter>
            <Letter>2</Letter>
            <Letter>3</Letter>
            <Letter>4</Letter>
            <Letter>5</Letter>
            <Letter>6</Letter>
            <Letter>7</Letter>
            <Letter>8</Letter>
            <Letter>9</Letter>
            <Letter>0</Letter>
            <Letter>1</Letter>
            <Letter>2</Letter>
            <Letter>3</Letter>
            <Letter>4</Letter>
            <Letter>5</Letter>
            <Letter>6</Letter>
            <Letter>7</Letter>
            <Letter>8</Letter>
            <Letter>9</Letter>
            <Letter>0</Letter>
            <Letter>1</Letter>
            <Letter>2</Letter>
            <Letter>3</Letter>
            <Letter>4</Letter>
            <Letter>5</Letter>
            <Letter>6</Letter>
            <Letter>7</Letter>
            <Letter>8</Letter>
            <Letter>9</Letter>
            <Letter>0</Letter>
            <Letter>1</Letter>
            <Letter>2</Letter>
            <Letter>3</Letter>
            <Letter>4</Letter>
            <Letter>5</Letter>
            <Letter>6</Letter>
            <Letter>7</Letter>
            <Letter>8</Letter>
            <Letter>9</Letter>
            <Letter>0</Letter>
            <Letter>1</Letter>
            <Letter>2</Letter>
            <Letter>3</Letter>
            <Letter>4</Letter>
            <Letter>5</Letter>
            <Letter>6</Letter>
            <Letter>7</Letter>
            <Letter>8</Letter>
            <Letter>9</Letter>
            <Letter>0</Letter>
            <Letter>1</Letter>
            <Letter>2</Letter>
            <Letter>3</Letter>
            <Letter>4</Letter>
            <Letter>5</Letter>
            <Letter>6</Letter>
            <Letter>7</Letter>
            <Letter>8</Letter>
            <Letter>9</Letter>
          </LetterWrapper2>
          <LetterWrapper>
            <Letter>%</Letter>
          </LetterWrapper>
        </Counter>
      </Content>
      <BlueDiv ref={blueDivRef}/>
      <PinkDiv ref={pinkDivRef}/>
    </Wrapper>
  )
}

export default Preloader

const Wrapper = styled.div`
  background-color: ${colors.richBlack100};
  position: fixed;
  top: 0vw;
  left: 0vw;
  z-index: 99;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`

const Content = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
`

const Svg = styled.svg`
  position: absolute;
  left: 3.472vw;
  bottom: 17.222vw;
  width: 93.056vw;

  ${media.fullWidth} {
    height: 10px;
  }

  ${media.desktop} {
    height: 0.694vw;
  }

  ${media.tablet} {
    left: 4.883vw;
    width: 90.234vw;
    height: 0.977vw;
    bottom: 28.809vw;
  }

  ${media.mobile} {
    left: 6.667vw;
    width: 86.667vw;
    height: 2.667vw;
    bottom: 68.667vw;
  }
`

const Line = styled.line`
  stroke: ${colors.offWhite};
  stroke-width: 100%;
`

const Line1 = styled(Line)`
  opacity: 0.1;
`

const Counter = styled.div`
  position: absolute;
  overflow: hidden;
  align-items: flex-start;
  display: flex;
  left: 3.264vw;
  bottom: 7.153vw;
  height: 8.333vw;
  width: 25.833vw;

  ${media.tablet} {
    left: 4.883vw;
    bottom: 14.648vw;
    width: 28.223vw;
    height: 11.719vw;
  }

  ${media.mobile} {
    left: 6.667vw;
    bottom: 29.7vw;
    width: 77.067vw;
    height: 32vw;
  }
`

const LetterWrapper = styled.div<{left?: string}>`
  position: relative;
  top: 5%;
  left: 0;
  height: 83.0vw;
  width: 5.764vw;
  display: flex;
  flex-direction: column;

  ${media.tablet} {
    height: 117.19vw;
    width: 8.105vw;
  }

  ${media.mobile} {
    height: 320vw;
    width: 22.133vw;
  }
`

const LetterWrapper2 = styled.div<{left?: string}>`
  position: relative;
  top: 5%;
  left: 0;
  height: 830vw;
  width: 5.764vw;
  display: flex;
  flex-direction: column;

  ${media.tablet} {
    height: 1171.9vw;
    width: 8.105vw;
  }

  ${media.mobile} {
    height: 3200vw;
    width: 22.133vw;
  }
`

const Letter = styled.p`
  font-family: neuebit;
  font-style: normal;
  font-weight: bold;
  font-size: 14.583vw;
  color: transparent;
  line-height: 8.3vw;

  height: 8.3vw;
  width: 5.764vw;

  text-transform: uppercase;
  -webkit-text-stroke: 1px ${colors.offWhite};

  ${media.tablet} {
    height: 11.719vw;
    line-height: 11.719vw;
    width: 8.105vw;
    font-size: 20.508vw;
  }

  ${media.mobile} {
    width: 22.133vw;
    height: 32vw;
    line-height: 32vw;
    font-size: 56vw;
  }
`

const PinkDiv = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0%;
  background-color: ${colors.razzmatazz100};
  z-index: 2;
`

const BlueDiv = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0%;
  background-color: ${colors.splash100};
  z-index: 1;
`

const Cover = styled.div`
  z-index: 9;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: ${colors.richBlack100};
`

