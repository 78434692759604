// TOOLS
import React, { Suspense, useContext, useEffect, useMemo, useRef } from "react"
import { Canvas, extend, ReactThreeFiber, useFrame } from "@react-three/fiber"
import { shaderMaterial, useTexture } from "@react-three/drei"
import { useHistory } from 'react-router-dom'
import * as THREE from 'three'
import gsap from 'gsap'
import styled from "styled-components"
import { map } from "utils/Functions"
import { lerp } from "three/src/math/MathUtils"
import { ScreenContext } from "App"
// COMPONENTS
import PrimaryButton from "components/PrimaryButton"
// STYLES
import Colors from "styles/colors"
import text from "styles/text"
import Media, { tablet, mobile } from "styles/media"
// ASSETS
import { ReactComponent as ArrowSVG } from 'assets/svg/arrow.svg'
import dragCursor from 'assets/svg/dragCursor.svg'
import Glows from 'assets/images/carouselGlows.png'
// CREATOR IMAGES
import BellaPoarch from 'assets/images/bellaPoarch/full.jpg'
import Grimes1 from 'assets/images/grimes1/full.jpg'
import Grimes2 from 'assets/images/grimes2/full.jpg'
import LilNasX from 'assets/images/lilNasX/full.jpg'
import Rudy from 'assets/images/rudyWillingham/full.jpg'
import BrittanyBroski from 'assets/images/brittanyBroski/full.jpg'
import CurtisRoach from 'assets/images/curtisRoach/full.jpg'
import CoinArtist from 'assets/images/coinArtist/full.jpg'
import FNMeka from 'assets/images/fnmeka/full.jpg'
import RTFKT from 'assets/images/rtfkt/full.jpg'
import GaryVee from 'assets/images/garyV/full.jpg'
import JessMarciante from 'assets/images/jessM/full.jpg'
import X0R from 'assets/images/x0r/full.jpg'
// CREATOR DATA
const creatorData = [{
  className: 'carousel-bella',
  creatorOne: 'Bella Poarch &',
  creatorTwo: 'Grimes',
  imageOne: BellaPoarch,
  imageTwo: Grimes1,
  buttonText: 'Enter Drop',
  link: '/bella-poarch'
}, {
  className: 'carousel-lilnasx',
  creatorOne: 'Lil Nas X &',
  creatorTwo: 'Rudy Willingham',
  imageOne: LilNasX,
  imageTwo: Rudy,
  buttonText: 'Enter Drop',
  link: '/lil-nas-x'
}, {
  className: 'carousel-curtis',
  creatorOne: 'Curtis Roach &',
  creatorTwo: 'Coin Artist',
  imageOne: CurtisRoach,
  imageTwo: CoinArtist,
  buttonText: 'Enter Drop',
  link: '/curtis-roach'
}, {
  className: 'carousel-brittany',
  creatorOne: 'Brittany Broski',
  creatorTwo: '& Grimes',
  imageOne: BrittanyBroski,
  imageTwo: Grimes2,
  buttonText: 'Notify Me',
  link: '/brittany-broski'
}, {
  className: 'carousel-fnmeka',
  creatorOne: 'FNMeka &',
  creatorTwo: 'RTFKT',
  imageOne: FNMeka,
  imageTwo: RTFKT,
  buttonText: 'Notify Me',
  link: '/fnmeka'
}, {
  className: 'carousel-garyvee',
  creatorOne: 'Jess Marciante',
  creatorTwo: '& X0R',
  creatorThree: 'Gary Vaynerchuk,',
  imageOne: GaryVee,
  imageTwo: JessMarciante,
  imageThree: X0R,
  buttonText: 'Notify Me',
  link: '/gary-vee'
}]

// SHADER
const ProductCarouselMaterial = shaderMaterial({
  uMap: new THREE.Texture(),
  uDelta: 0,
  uAlpha: 0.6
},
`
  #define PI 3.14159
  uniform float uDelta;
  varying vec2 vUv;

  void main() {
    vec3 pos = position;
    pos.x -= abs(sin(uv.y * PI) * 0.125) * uDelta * 2.;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
    vUv = uv;
  }
`,
`
  uniform sampler2D uMap;
  uniform float uDelta;
  uniform float uAlpha;

  varying vec2 vUv;

  void main() {
    float angle = 0.5;
    vec2 offset = uDelta / 4.0 * vec2(cos(angle), sin(angle));
    float r = texture2D(uMap, vUv + offset).r;
    vec2 gb = texture2D(uMap, vUv).gb;
    vec4 image = vec4(r, gb, 1.0);
    gl_FragColor = mix(vec4(vec3(0.), 1.), image, uAlpha);
  }
`)

type CarouselMaterialType = {
  uMap: THREE.Texture
  uDelta: number
  uAlpha: number
}

declare global { namespace JSX { interface IntrinsicElements {
  productCarouselMaterial: ReactThreeFiber.Object3DNode<CarouselMaterialType, typeof ProductCarouselMaterial>
}}}

extend({ ProductCarouselMaterial })

// END SHADER

type CarouselImageProps = {
  image: string
  index: number
}

// WRAPPER COMPONENT
const CollaboratorCarousel: React.FC<{}> = () => {

  const wrapper = useRef(null)
  const canvas = useRef(null)
  const touchDown = useRef(false)
  const touchStartPosition = useRef(0)
  const currentOffset = useRef(0)
  const needsNewOffset = useRef(false)
  const currentDataIndex = useRef(0)
  const isAnimating = useRef(false)
  const fade = useRef('in')
  const cursor = useRef()

  const screen = useContext(ScreenContext)

  useEffect(() => {
    setTimeout(() => {
      window.locomotiveScroll.update()
      currentOffset.current = 0.002;
    }, 4000);
  }, [])

  let history = useHistory()
  let filteredCreatorData = creatorData.filter(x => x.link !== history.location.pathname)

  // HANDLER FUNCTIONS
  const handleTouchStart = (e: TouchEvent & MouseEvent) => {
    if (screen.mobile) return;
    touchDown.current = true
    touchStartPosition.current = e.touches ? e.touches[0].clientX : e.clientX
  }

  const handleTouchMove = (e: any) => {
    if (!touchDown.current) return
    isAnimating.current = true
    const x = e.touches ? e.touches[0].clientX : e.clientX
    let newOffset = Math.min(Math.max((touchStartPosition.current - x) * 0.01, -0.3), 0.3)
    fadeOut()
    currentOffset.current = newOffset
    touchStartPosition.current = x
  }

  const getClosestMesh = () => {
    let closestMesh = imageMeshRefs.filter(ref => ref.current && Math.abs(ref.current.position.x) <= 0.75)[0]
    currentOffset.current = closestMesh.current.position.x * 0.173
    currentDataIndex.current = closestMesh.current.name
    if (isAnimating.current) setTimeout(() => { fadeIn() }, 200);
    else fadeIn()
    needsNewOffset.current = false
  }

  const handleNext = () => {
    fadeOut()
    currentOffset.current = 0.265
    needsNewOffset.current = true
  }

  const handlePrev = () => {
    fadeOut()
    currentOffset.current = -0.265
    needsNewOffset.current = true
  }

  const handleTouchEnd = () => {
    if (!touchDown.current) return
    touchDown.current = false
    needsNewOffset.current = true
    getClosestMesh()
  }

  const handleCursorMove = (e: MouseEvent) => {
    gsap.set(cursor.current, {
      x: e.clientX - (window.innerWidth * 0.039),
      y: e.pageY + (window.locomotiveScroll.scroll.instance.scroll.y - (window.innerWidth * 6.43))
    })
  }
  // END HANDLER FUNCTIONS

  // ANIMATIONS
  const animationSpeed = 0.4

  const fadeIn = () => {
    if (fade.current === 'in') return
    fade.current = 'in'
    let meshRefs = imageMeshRefs.filter(ref => ref.current)
    gsap.to(meshRefs[currentDataIndex.current].current.material.uniforms.uAlpha, {value: 1 })
    gsap.fromTo(`.${filteredCreatorData[currentDataIndex.current].className}-name`, {
      top: '110%',
    }, {
      top: '0.1vw',
      stagger: 0.05,
      duration: animationSpeed,
      ease: 'circ.out',
    })
    gsap.fromTo(`.${filteredCreatorData[currentDataIndex.current].className}-button`, {
      top: '130%',
    }, {
      top: '6%',
      duration: animationSpeed,
      ease: 'circ.out',
      delay: 0.4
    })
    gsap.fromTo(`.${filteredCreatorData[currentDataIndex.current].className}-image`, {
      top: '110%',
    }, {
      top: '0',
      duration: animationSpeed,
      ease: 'circ.out',
      delay: 0.1
    })
    gsap.fromTo(`.${filteredCreatorData[currentDataIndex.current].className}-image2`, {
      top: '110%',
    }, {
      top: '0',
      duration: animationSpeed,
      ease: 'circ.out',
      delay: 0.2
    })
  }

  const fadeOut = () => {
    fade.current = 'out'
    let meshRefs = imageMeshRefs.filter(ref => ref.current)
    gsap.to(meshRefs[currentDataIndex.current].current.material.uniforms.uAlpha, { value: 0.6 })
    gsap.to(`.${filteredCreatorData[currentDataIndex.current].className}-name`, {
      top: '-110%',
      duration: animationSpeed * 1.5,
      ease: 'circ.out',
      stagger: 0.05,
    })
    gsap.to(`.${filteredCreatorData[currentDataIndex.current].className}-button`, {
      top: '-130%',
      duration: animationSpeed * 1.5,
      ease: 'circ.out',
      delay: 0.2,
      onComplete: () => isAnimating.current = false
    })
    gsap.to(`.${filteredCreatorData[currentDataIndex.current].className}-image`, {
      top: '-110%',
      duration: animationSpeed * 1.5,
      ease: 'circ.out',
      delay: 0.1,
    })
    gsap.to(`.${filteredCreatorData[currentDataIndex.current].className}-image2`, {
      top: '-110%',
      duration: animationSpeed * 1.5,
      ease: 'circ.out',
      delay: 0.2,
    })
  }
  const fadeInCursor = () => gsap.to(cursor.current, { opacity: 1, duration: 0.3 })

  const fadeOutCursor = () => gsap.to(cursor.current, { opacity: 0, duration: 0.3 })
  // END ANIMATIONS

  useEffect(() => {
    let ref = wrapper.current
    ref.addEventListener('mousedown', handleTouchStart)
    ref.addEventListener('mousemove', handleTouchMove)
    ref.addEventListener('mouseup', handleTouchEnd)
    ref.addEventListener('mouseleave', handleTouchEnd)
    ref.addEventListener('touchstart', handleTouchStart)
    ref.addEventListener('touchmove', handleTouchMove)
    ref.addEventListener('touchend', handleTouchEnd)
    ref.addEventListener('mousemove', handleCursorMove)
    return () => {
      ref.removeEventListener('mousedown', handleTouchStart)
      ref.removeEventListener('mousemove', handleTouchMove)
      ref.removeEventListener('mouseup', handleTouchEnd)
      ref.removeEventListener('mouseleave', handleTouchEnd)
      ref.removeEventListener('touchstart', handleTouchStart)
      ref.removeEventListener('touchmove', handleTouchMove)
      ref.removeEventListener('touchend', handleTouchEnd)
      ref.removeEventListener('mousemove', handleCursorMove)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // add logic here to remove creator already on page!
  let images = creatorData.filter(creator => creator.link !== history.location.pathname).map(creator => creator.imageOne)

  let imageMeshRefs = useMemo(() => [], [])

  // CAROUSEL IMAGE COMPONENT
  const CarouselImage: React.FC<CarouselImageProps> = ({ image, index }) => {

    const mesh = useRef(null)
    const material = useRef<CarouselMaterialType>(null)
    const texture = useTexture(image)

    useEffect(() => { imageMeshRefs.push(mesh) }, [])

    useEffect(() => {
      if (material.current) material.current.uMap = texture
    }, [texture])

    const positionX = (window.innerWidth > mobile && window.innerWidth <= tablet ? 1.2 : 1.5) * index

    const halfOffset = (window.innerWidth > mobile && window.innerWidth <= tablet ? 3 : 3.5)

    const totalOffset = (window.innerWidth > mobile && window.innerWidth <= tablet ? 6 : 7.5)

    let offsetX: number;

    useFrame(() => {
      if (currentOffset.current && Math.abs(currentOffset.current) > 0.001) {

        if(!touchDown.current && index === 0) {
          if (Math.abs(currentOffset.current) < 0.02 && needsNewOffset.current) getClosestMesh()

          offsetX = lerp(currentOffset.current, 0, 0.15)
          currentOffset.current = offsetX
        } else offsetX = currentOffset.current

        let newX = ((mesh.current.position.x - offsetX + halfOffset) % totalOffset) - halfOffset

        if (newX < -halfOffset) newX += totalOffset

        mesh.current.position.setX(newX)
        mesh.current.position.setY(Math.cos((newX / 15) * Math.PI) * -5 + 5)
        mesh.current.rotation.set(0, 0, map(newX, -15, 15, -Math.PI, Math.PI))

        material.current.uDelta = currentOffset.current
      }
    })


    return <mesh ref={mesh} position={new THREE.Vector3(positionX, 0, 0)} name={`${index}`}>
      <planeGeometry args={[0.65, 1, 50, 50]}/>
      <productCarouselMaterial
        ref={material}
        uMap={new THREE.Texture()}
        uAlpha={index === 0 ? 1 : 0.6}
        uDelta={0}
      />
    </mesh>
  }
  // END CAROUSEL IMAGE COMPONENT

  // COMPONENT MAPPING
  const carouselImages = images.map((image, index) => <CarouselImage
    image={image}
    key={index}
    index={index}
  />)

  const nameOnes = filteredCreatorData.map((creator, index) => <CreatorName
    className={`${creator.className}-name`}
    key={index}
  >
    {creator.creatorOne}
  </CreatorName>)

  const nameTwos = filteredCreatorData.map((creator, index) => <CreatorName
    className={`${creator.className}-name`}
    key={index}
  >
    {creator.creatorTwo}
  </CreatorName>)

  const nameThrees = filteredCreatorData.map((creator, index) => <CreatorName
  className={`${creator.className}-name`}
  key={index}
  >
  {creator.creatorThree ? creator.creatorThree : ''}
  </CreatorName>)

  const buttons = filteredCreatorData.map((creator, index) => <PrimaryButton
    className={`${creator.className}-button`}
    key={index}
    onClick={() => history.push(creator.link)}
    onMouseEnter={fadeOutCursor}
    onMouseLeave={fadeInCursor}
  >
    {creator.buttonText}
  </PrimaryButton>)

  const imageTwos = filteredCreatorData.map((creator, index) => <CreatorTwoImage className={`${creator.className}-image`} key={index} src={creator.imageTwo} />)

  const imageThrees = filteredCreatorData.map((creator, index) => <CreatorTwoImage className={`${creator.className}-image2`} key={index} src={creator.imageThree} />)
  // END COMPONENT MAPPING

  return <Wrapper ref={wrapper} data-scroll-section onMouseEnter={fadeInCursor} onMouseLeave={fadeOutCursor}>
    <Title><span>{'//'}</span>ALL COLLABORATIONS</Title>
    <CreatorInfoWrapper>
      <NameThreeMask>{nameThrees}</NameThreeMask>
      <NameMask>{nameOnes}</NameMask>
      <NameMask>{nameTwos}</NameMask>
      <ButtonMask>{buttons}</ButtonMask>
      <ImageTwoMask>{imageTwos}</ImageTwoMask>
      <ImageThreeMask>{imageThrees}</ImageThreeMask>
    </CreatorInfoWrapper>
    <Suspense fallback={<></>}>
      <CanvasWrapper>
        <Canvas ref={canvas} dpr={screen.mobile ? Math.min(window.devicePixelRatio, 2) : 1.5} camera={{ fov: 2 * Math.atan((1.684) / (2 * 5)) * (180 / Math.PI) }}>
          {carouselImages}
        </Canvas>
        <DragCursor src={dragCursor} ref={cursor}/>
      </CanvasWrapper>
    </Suspense>
    <ArrowPrev onClick={handlePrev}/>
    <ArrowNext onClick={handleNext}/>
  </Wrapper>
}

// STYLING
const Title = styled.h1`
  color: ${Colors.offWhite};
  font: 700 8.3vw/100% 'NeueBit';
  user-select: none;
  -webkit-user-select: none;
  margin: 20vw 0 2vw;
  span {
    margin: 0 7vw 0 3.5vw;
  }
  ${Media.tablet} {
    font: 700 7.8vw/100% 'NeueBit';
  }
  ${Media.mobile} {
    font: 700 8vw/100% 'NeueBit';
    margin: 20vw 0 -5vw;
  }
`

const CreatorInfoWrapper = styled.div`
  position: absolute;
  width: 46.8vw;
  height: 23.2vw;
  top: 29.4vw;
  left: 22.3vw;
  z-index: 10;
  ${Media.tablet} {
    width: 65.9vw;
    height: 28.6vw;
    top: 41.5vw;
    left: 13.8vw;
  }
  ${Media.mobile} {
    width: 66vw;
    height: 85vw;
    top: 38vw;
    left: 17vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`

const NameMask = styled.div`
  overflow: hidden;
  height: 3vw;
  position: relative;
  ${Media.tablet} {
    height: 4.2vw;
  }
  ${Media.mobile} {
    height: 8vw;
  }
`

const NameThreeMask = styled(NameMask)`
  position: absolute;
  width: 100%;
  top: -3vw;
  left: 0;
  ${Media.tablet} {
    top: -4.2vw
  }
  ${Media.mobile} {
    top: 42vw
  }
`

const ButtonMask = styled.div`
  position: relative;
  height: 5vw;
  overflow: hidden;
  margin-top: 1.7vw;
  transform: translateX(-0.3vw);
  z-index: 20;
  button {
    position: absolute;
    top: 130%;
    left: 0.3vw;
    user-select: none;
    -webkit-user-select: none;
  }
  ${Media.tablet} {
    height: 7vw;
    button {
      left: 0.5vw;
    }
  }
  ${Media.mobile} {
    height: 18vw;
    button {
      left: 0.5vw;
      width: 65vw;
      > div {
        width: 100%;
      }
    }
  }
`

const ImageTwoMask = styled.div`
  width: 11.5vw;
  height: 17.4vw;
  position: absolute;
  right: 0;
  bottom: 0;
  transform: rotate(-2.5deg);
  overflow: hidden;
  ${Media.tablet} {
    width: 16.1vw;
    height: 24.4vw;
  }
  ${Media.mobile} {
    width: 20vw;
    height: 30.1vw;
    bottom: initial;
    top: 0;
  }
`

const ImageThreeMask = styled.div`
  width: 7.36vw;
  height: 11.11vw;
  position: absolute;
  top: -9vw;
  right: 3vw;
  overflow: hidden;
  transform: rotate(2.5deg);
  ${Media.tablet} {
    width: 10.3vw;
    height: 15.6vw;
  }
  ${Media.mobile} {
    width: 14.9vw;
    height: 22.7vw;
    top: -17vw;
    right: 49vw;
  }
`

const CreatorName = styled.h1`
  ${text.desktop.headerH3}
  color: ${Colors.offWhite};
  position: absolute;
  top: 110%;
  user-select: none;
  -webkit-user-select: none;
  ${Media.tablet} {
    ${text.tablet.headerH3}
  }
  ${Media.mobile} {
    ${text.mobile.headerH4}
  }
`

const CreatorTwoImage = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  top: 110%;
  user-select: none;
  -webkit-user-select: none;
  pointer-events: none;
`

const CanvasWrapper = styled.div`
  height: 58vw;
  ${Media.tablet} {
    height: 82.2vw;
  }
  ${Media.mobile} {
    height: 120vw;
  }
`

const DragCursor = styled.img`
  position: absolute;
  width: 7.8vw;
  height: auto;
  z-index: 10;
  pointer-events: none;
  cursor: none;
  opacity: 0;
  ${Media.tablet} {
    display: none;
  }
  ${Media.mobile} {
    display: none;
  }
`

const Wrapper = styled.section`
  cursor: none;
  background: url(${Glows}) center/contain no-repeat;

  ${Media.desktop} {
    margin-bottom: 13.889vw;
  }

  ${Media.tablet} {
    margin-bottom: 24.316vw;
    cursor: default;
  }
  ${Media.mobile} {
    margin-bottom: 54.933vw;

    cursor: default;
    background-size: 150%;
  }
  .carousel-bella-name {
    top: 0.3vw
  }
  .carousel-bella-button {
    top: 6%;
  }
  .carousel-bella-image {
    top: 0;
  }
`

const Arrow = styled(ArrowSVG)`
  visibility: hidden;
  position: absolute;
  padding: 6vw;
  box-sizing: content-box;
  top: 60vw;
  path {
    stroke: ${Colors.offWhite};
  }
  transform: rotate(45deg);
  ${Media.mobile} {
    visibility: initial;
  }
`

const ArrowPrev = styled(Arrow)`
  transform: rotate(225deg);
  left: 0;
`

const ArrowNext = styled(Arrow)`
  right: 0;
`

export default CollaboratorCarousel
