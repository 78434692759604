import React, { useRef, useContext } from 'react'
import { ScreenContext } from 'App'
import styled, { keyframes } from 'styled-components'
import gsap from 'gsap'

import { useNavigate, useMedia } from 'utils/Hooks'

import media from 'styles/media'
import colors from 'styles/colors'
import text from 'styles/text'

import PrimaryButton from 'components/PrimaryButton'
import Cursor from 'components/EnterReformCursor'

const FourOhFour: React.FC = () => {

  const screen = useContext(ScreenContext)
  const navigate = useNavigate()

  const wrapperRef = useRef(null)
  const banner4Ref = useRef(null)
  const contentRef = useRef(null)
    
  const navigateHome = () => {
    navigate('/')
  }

  const createTextArray = (text: string, limit?: number) => {
    let textArray = [];
  
    for (let i = 0; i < (limit || 30); i++) {
      textArray.push(
        <p key={i}>{text + ' // '}</p>
      );
    }

    return textArray
  }

  const mouseMoveHandler = (e: any) => {
    const cursor = document.getElementById('enter-reform-cursor')

    gsap.set(cursor, {
      x: e.clientX - (window.innerWidth * 0.05),
      y: e.clientY - (window.innerWidth * 0.05) + (window.locomotiveScroll.scroll.instance.scroll.y)
    })
  }

  const handleMouseEnter = () => {

    wrapperRef.current.addEventListener("mousemove", mouseMoveHandler)

    gsap.set('#enter-reform-cursor', {
      opacity: 1
    })

    gsap.to(banner4Ref.current, {
      duration: 0.3,
      zIndex: 5
    })

    gsap.to(contentRef.current, {
      duration: 0.3,
      opacity: 0.2
    })
  }

  const handleMouseLeave = () => {
    wrapperRef.current.removeEventListener("mousemove", mouseMoveHandler)

    gsap.set('#enter-reform-cursor', {
      opacity: 0
    })

    gsap.set(banner4Ref.current, {
      zIndex: 3
    })

    gsap.to(contentRef.current, {
      duration: 0.3,
      opacity: 1
    })
  }

  const handleClick = () => {
    window.open('https://reformcollective.com', "__blank")
  }

  const banner1Speed = 8
  const banner1Text = createTextArray("TIKTOK TOP MOMENTS")
  const banner1Color = colors.razzmatazz100
  const banner1Angle = useMedia("30.73", "30.73", "30.73", "33")
  const banner1ZIndex = 1
  const banner1Top = useMedia('60.6vw', '60.6vw', '100.6vw', '171vw')

  const banner2Speed = 4
  const banner2Text = createTextArray("POWERED BY BITSKI")
  const banner2Color = colors.splash100
  const banner2Angle = useMedia("-21.73", "-21.73", "-21.73", "-29.97")
  const banner2ZIndex = 2
  const banner2Top = useMedia('3.6vw', '3.6vw', '12.6vw', '37vw')

  const banner3Speed = 4
  const banner3Text = createTextArray("LIL NAS X // RUDY WILLINGHAM // FNMEKA // RTFKT // BELLA POARCH // GRIMES // CURTIS ROACH // COIN ARTIST // GARY VEE // JESS MARCIANTE // X0R // BRITTANY BROSKI // GRIMES", 2)
  const banner3Color = colors.richBlack100
  const banner3Angle = useMedia("61.73", "61.73", "61.73", "16.89")
  const banner3ZIndex = 4
  const banner3Top = useMedia('-10vw', '-10vw', '-8vw', '109vw')
  const banner3Left = useMedia('-15vw', '-15vw', '-40vw', '0vw')

  const banner4Speed = 20
  const banner4Text = createTextArray("SITE DESIGNED BY REFORM COLLECTIVE")
  const banner4Color = colors.offWhite
  const banner4Angle = useMedia("-7.25", "-7.25", "-7.25", "-18.96")
  const banner4ZIndex = 3
  const banner4Top = useMedia('49vw', '49vw', '95vw', '159vw')

  return (
    <Wrapper ref={wrapperRef} id="fourOhFour" data-scroll-section>
      {(screen.desktop || screen.fullWidth) && <Cursor id="enter-reform-cursor"/>}
      <Content ref={contentRef}>

        <BlackTitle>404</BlackTitle>
        <BlueTitle>404</BlueTitle>
        <PinkTitle>404</PinkTitle>
        <ButtonWrapper>
          <PrimaryButton
            onClick={navigateHome}
          >Return Home</PrimaryButton>
        </ButtonWrapper>

        <Banner
          data-scroll-offset="top bottom"
          angle={banner1Angle}
          bGColor={banner1Color}
          zIndex={banner1ZIndex}
          top={banner1Top}
        >
          <span
            data-scroll-direction="horizontal"
            data-scroll-target={`#fourOhFour`}
            data-scroll
            data-scroll-speed={banner1Speed}
            className="banner__text"
          >
            <Text speed={banner1Speed}>
              {banner1Text}
            </Text>
          </span>
        </Banner>

        <Banner
          data-scroll-offset="top bottom"
          angle={banner2Angle}
          bGColor={banner2Color}
          zIndex={banner2ZIndex}
          top={banner2Top}
        >
          <span
            data-scroll-direction="horizontal"
            data-scroll-target={`#fourOhFour`}
            data-scroll
            data-scroll-speed={banner2Speed}
            className="banner__text"
          >
            <Text speed={banner2Speed}>
              {banner2Text}
            </Text>
          </span>
        </Banner>
      
        <Banner
          data-scroll-offset="top bottom"
          angle={banner3Angle}
          bGColor={banner3Color}
          zIndex={banner3ZIndex}
          top={banner3Top}
          left={banner3Left}
        >
          <span
            data-scroll-direction="horizontal"
            data-scroll-target={`#fourOhFour`}
            data-scroll
            data-scroll-speed={banner3Speed}
            className="banner__text"
          >
            <Text speed={banner3Speed}>
              {banner3Text}
            </Text>
          </span>
        </Banner>

      </Content>

      <Banner
        data-scroll-offset="top bottom"
        angle={banner4Angle}
        bGColor={banner4Color}
        zIndex={banner4ZIndex}
        top={banner4Top}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        ref={banner4Ref}
        cursor="none"
        onClick={handleClick}
      >
        <span
          data-scroll-direction="horizontal"
          data-scroll-target={`#fourOhFour`}
          data-scroll
          data-scroll-speed={banner4Speed}
          className="banner__text"
        >
          <Text speed={banner4Speed}>
            {banner4Text}
          </Text>
        </span>
      </Banner>
    

    </Wrapper>
  )
}

export default FourOhFour

const Wrapper = styled.section`
  position: relative;
  background-color: ${colors.richBlack100};
  overflow: hidden;

  height: 90vw;

  ${media.tablet} {
    height: 136vw;
  }

  ${media.mobile} {
    height: 250vw;
  }
`

const Content = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

const Title = styled.h1`
  position: absolute;
  font-family: Proxima Nova, sans-serif;
  font-style: normal;
  font-weight: 800;
  line-height: 75%;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  left: 50%;

  top: 20.972vw;
  font-size: 13.889vw;

  ${media.tablet} {
    top: 45.996vw;
    font-size: 19.531vw;
  }

  ${media.mobile} {
    top: 77.333vw;
    font-size: 32vw;
  }
`

const BlackTitle = styled(Title)`
  color: ${colors.richBlack100};
  z-index: 3;
  transform: translateX(-50%);
`

const PinkTitle = styled(Title)`
  color: ${colors.razzmatazz100};
  z-index: 2;
  transform: translateX(-49%) translateY(2%);
`

const BlueTitle = styled(Title)`
  color: ${colors.splash100};
  z-index: 1;
  transform: translateX(-51%) translateY(-2%);
`

const ButtonWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  top: 35.75vw;

  ${media.tablet} {
    top: 63.965vw;
  }

  ${media.mobile} {
    top: 116.8vw;
  }
`

const sidewaysAnimation = keyframes`
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
`

const Text = styled.h2<{speed: number}>`
  line-height: 4.9vw;
  white-space: pre;
  position: relative;
  display: flex;
  align-items: center;
  animation: ${sidewaysAnimation} ${props => props.speed * 8}s linear infinite;

  div {
    display: flex;

    .cb_dash {
      margin: 0 20px;
    }
  }
`;

const Banner = styled.div<{
  angle: string;
  bGColor: string;
  zIndex: number;
  top: string;
  left?: string
  cursor?: string
}>`
  ${text.desktop.headerH2};
  color: ${(props) =>
    props.bGColor === colors.richBlack100
      ? colors.offWhite
      : colors.richBlack100};
  background: ${(props) => props.bGColor};
  z-index: ${(props) => props.zIndex};
  top: ${(props) => props.top};
  left: ${props => props.left || '-25vw'};
  transform: rotate(${(props) => props.angle}deg);
  position: absolute;
  transform-origin: 50%;
  white-space: nowrap;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 1.7vw 0;
  width: 150vw;
  height: 8.3vw;
  cursor: ${props => props.cursor || 'unset'};

  ${media.tablet} {
    left: ${props => props.left || '-50vw'};
    height: 11.621vw;
    width: 208.398vw;
    padding: 2.441vw;
  }

  ${media.mobile} {
    left: ${props => props.left || '-250vw'};
    height: 16vw;
    width: 569.067vw;
    padding: 8vw 0vw;
  }

  span {
    position: relative;
    display: flex;
    font-family: NeueBit;
    font-style: normal;
    font-weight: bold;
    line-height: 100%;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 4.861vw;
    margin-left: -150vw;
    ${media.tablet} {
      font-size: 5vw;
    }

    ${media.mobile} {
      font-size: 8vw;
    }
  }
`;
