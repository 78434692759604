import { shaderMaterial } from "@react-three/drei";
import { extend } from "@react-three/fiber";
import { Texture } from "three";

const ImageColorSkewMaterial = shaderMaterial(
  {
    map: new Texture(),
    delta: 0,
  },
  `
  varying vec2 vUv;

    void main() {
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        vUv = uv;
    }
`,
  `
    uniform sampler2D map;
    uniform float delta;

    varying vec2 vUv;

    void main() {
        float angle = 0.9;
        vec2 offset = delta / 4.0 * vec2(cos(angle), sin(angle));
        float r = texture2D(map, vUv + offset).r;
        vec2 gb = texture2D(map, vUv).gb;
        gl_FragColor = vec4(r, gb, 1.0);
    }
`
);

extend({ ImageColorSkewMaterial });
