import React, { useEffect } from 'react'
import styled from 'styled-components'

import { Bitski } from 'bitski'

import colors from 'styles/colors'

const Callback: React.FC = () => {

  useEffect(() => {
    Bitski.callback()
  }, [])

  return (
    <Wrapper>
      LOADING OR WHATEVER
    </Wrapper>
  )
}

export default Callback

const Wrapper = styled.section`
  color: ${colors.offWhite};
`