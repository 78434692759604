/*
Suggestion for this:
define the media queries in each style so we don't have to change them at every place they're used in the app
e.g. headerh1: `
  font: something;
  ${media.mobile} {
    font: something
  }
`
i'll do this if i have time later - jackson

*/

const text = {
  fullWidth: {
    headerH1: `
      font-family: Sofia Pro;
      font-style: normal;
      font-weight: bold;
      font-size: 100px;
      line-height: 100%;
      letter-spacing: -0.04em;
    `,
    headerH2: `
      font-family: Sofia Pro;
      font-style: normal;
      font-weight: bold;
      font-size: 70px;
      line-height: 99.5%;
      letter-spacing: -0.05em;
    `,
    headerH3: `
      font-family: Sofia Pro;
      font-style: normal;
      font-weight: bold;
      font-size: 48px;
      line-height: 90%;
      letter-spacing: -0.04em;
    `,
    headerH4: `
      font-family: Sofia Pro;
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 90%;
      letter-spacing: -0.04em;
    `,
    headerBodyCopy: `
      font-family: Sofia Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 160%;
    `,
    headerBodyCopy2: `
      font-family: Sofia Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 170%;
    `,
    displayH1: `
      font-family: Proxima-Nova, sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 120px;
      line-height: 75%;
      letter-spacing: -0.03em;
      text-transform: uppercase;
    `,
    displayH2: `
      font-family: Proxima-Nova, sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 60px;
      line-height: 100%;
      letter-spacing: -0.03em;
      text-transform: uppercase;
    `,
    displayH3: `
      font-family: Proxima-Nova, sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 40px;
      line-height: 100%;
      letter-spacing: -0.03em;
      text-transform: uppercase;
    `,
    displayH4: `
      font-family: Proxima-Nova, sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      line-height: 150%;
      text-transform: uppercase;
    `,
    displayH5: `
      font-family: Proxima-Nova, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 90%;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    `,
    jumboTicker: `
      font-family: neuebit, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 70px;
      line-height: 90%;
      text-transform: uppercase;
    `,
    priceText: `
      font-family: neuebit, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 38px;
      line-height: 90%;
      letter-spacing: 0.03em;
      text-transform: uppercase;
    `,
    buttonText: `
      font-family: neuebit, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 90%;
      letter-spacing: 0.03em;
      text-transform: uppercase;
    `,
    tickerText: `
      font-family: neuebit, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 100%;
      letter-spacing: -0.01em;
    `,
  },
  desktop: {
    headerH1: `
      font-family: Sofia Pro;
      font-style: normal;
      font-weight: bold;
      font-size: 6.944vw;
      line-height: 100%;
      letter-spacing: -0.04em;
    `,
    headerH2: `
      font-family: Sofia Pro;
      font-style: normal;
      font-weight: bold;
      font-size: 4.861vw;
      line-height: 99.5%;
      letter-spacing: -0.05em;
    `,
    headerH3: `
      font-family: Sofia Pro;
      font-style: normal;
      font-weight: bold;
      font-size: 3.333vw;
      line-height: 90%;
      letter-spacing: -0.04em;
    `,
    headerH4: `
      font-family: Sofia Pro;
      font-style: normal;
      font-weight: bold;
      font-size: 2.222vw;
      line-height: 90%;
      letter-spacing: -0.04em;
    `,
    headerBodyCopy: `
      font-family: Sofia Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 1.250vw;
      line-height: 160%;
    `,
    headerBodyCopy2: `
      font-family: Sofia Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 1.111vw;
      line-height: 170%;
    `,
    displayH1: `
      font-family: Proxima-Nova, sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 8.333vw;
      line-height: 75%;
      letter-spacing: -0.03em;
      text-transform: uppercase;
    `,
    displayH2: `
      font-family: Proxima-Nova, sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 4.167vw;
      line-height: 100%;
      letter-spacing: -0.03em;
      text-transform: uppercase;
    `,
    displayH3: `
      font-family: Proxima-Nova, sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 2.778vw;
      line-height: 100%;
      letter-spacing: -0.03em;
      text-transform: uppercase;
    `,
    displayH4: `
      font-family: Proxima-Nova, sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 1.250vw;
      line-height: 150%;
      text-transform: uppercase;
    `,
    displayH5: `
      font-family: Proxima-Nova, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 1.042vw;
      line-height: 90%;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    `,
    jumboTicker: `
      font-family: neuebit, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 4.861vw;
      line-height: 90%;
      text-transform: uppercase;
    `,
    priceText: `
      font-family: neuebit, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 2.639vw;
      line-height: 90%;
      letter-spacing: 0.03em;
      text-transform: uppercase;
    `,
    buttonText: `
      font-family: neuebit, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 1.667vw;
      line-height: 90%;
      letter-spacing: 0.03em;
      text-transform: uppercase;
    `,
    tickerText: `
      font-family: neuebit, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 1.250vw;
      line-height: 100%;
      letter-spacing: -0.01em;
    `,
  },
  tablet: {
    headerH1: `
      font-family: Sofia Pro;
      font-style: normal;
      font-weight: bold;
      font-size: 9.77vw;
      line-height: 100%;
      letter-spacing: -0.04em;
    `,
    headerH2: `
      font-family: Sofia Pro;
      font-style: normal;
      font-weight: bold;
      font-size: 6.836vw;
      line-height: 99.5%;
      letter-spacing: -0.05em;
    `,
    headerH3: `
      font-family: Sofia Pro;
      font-style: normal;
      font-weight: bold;
      font-size: 4.69vw;
      line-height: 90%;
      letter-spacing: -0.04em;
    `,
    headerH4: `
      font-family: Sofia Pro;
      font-style: normal;
      font-weight: bold;
      font-size: 3.125vw;
      line-height: 90%;
      letter-spacing: -0.04em;
    `,
    headerBodyCopy: `
      font-family: Sofia Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 1.758vw;
      line-height: 160%;
    `,
    headerBodyCopy2: `
      font-family: Sofia Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 1.563vw;
      line-height: 170%;
    `,
    displayH1: `
      font-family: Proxima-Nova, sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 11.72vw;
      line-height: 75%;
      letter-spacing: -0.03em;
      text-transform: uppercase;
    `,
    displayH2: `
      font-family: Proxima-Nova, sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 5.86vw;
      line-height: 100%;
      letter-spacing: -0.03em;
      text-transform: uppercase;
    `,
    displayH3: `
      font-family: Proxima-Nova, sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 3.91vw;
      line-height: 100%;
      letter-spacing: -0.03em;
      text-transform: uppercase;
    `,
    displayH4: `
      font-family: Proxima-Nova, sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 1.758vw;
      line-height: 150%;
      text-transform: uppercase;
    `,
    displayH5: `
      font-family: Proxima-Nova, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 1.46vw;
      line-height: 90%;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    `,
    jumboTicker: `
      font-family: neuebit, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 6.836vw;
      line-height: 90%;
      text-transform: uppercase;
    `,
    priceText: `
      font-family: neuebit, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 3.711vw;
      line-height: 90%;
      letter-spacing: 0.03em;
      text-transform: uppercase;
    `,
    buttonText: `
      font-family: neuebit, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 2.344vw;
      line-height: 90%;
      letter-spacing: 0.03em;
      text-transform: uppercase;
    `,
    tickerText: `
      font-family: neuebit, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 1.758vw;
      line-height: 100%;
      letter-spacing: -0.01em;
    `,
  },
  mobile: {
    headerH1: `
      font-family: Sofia Pro;
      font-style: normal;
      font-weight: bold;
      font-size: 26.667vw;
      line-height: 100%;
      letter-spacing: -0.04em;
    `,
    headerH2: `
      font-family: Sofia Pro;
      font-style: normal;
      font-weight: bold;
      font-size: 18.667vw;
      line-height: 99.5%;
      letter-spacing: -0.05em;
    `,
    headerH3: `
      font-family: Sofia Pro;
      font-style: normal;
      font-weight: bold;
      font-size: 12.800vw;
      line-height: 90%;
      letter-spacing: -0.04em;
    `,
    headerH4: `
      font-family: Sofia Pro;
      font-style: normal;
      font-weight: bold;
      font-size: 8.533vw;
      line-height: 90%;
      letter-spacing: -0.04em;
    `,
    headerBodyCopy: `
      font-family: Sofia Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 4.8vw;
      line-height: 160%;
    `,
    headerBodyCopy2: `
      font-family: Sofia Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 4.267vw;
      line-height: 170%;
    `,
    displayH1: `
      font-family: Proxima-Nova, sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 32vw;
      line-height: 75%;
      letter-spacing: -0.03em;
      text-transform: uppercase;
    `,
    displayH2: `
      font-family: Proxima-Nova, sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 16vw;
      line-height: 100%;
      letter-spacing: -0.03em;
      text-transform: uppercase;
    `,
    displayH3: `
      font-family: Proxima-Nova, sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 10.667vw;
      line-height: 100%;
      letter-spacing: -0.03em;
      text-transform: uppercase;
    `,
    displayH4: `
      font-family: Proxima-Nova, sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 4.800vw;
      line-height: 150%;
      text-transform: uppercase;
    `,
    displayH5: `
      font-family: Proxima-Nova, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 4vw;
      line-height: 90%;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    `,
    jumboTicker: `
      font-family: neuebit, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 18.667vw;
      line-height: 90%;
      text-transform: uppercase;
    `,
    priceText: `
      font-family: neuebit, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 10.133vw;
      line-height: 90%;
      letter-spacing: 0.03em;
      text-transform: uppercase;
    `,
    buttonText: `
      font-family: neuebit, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 6.400vw;
      line-height: 90%;
      letter-spacing: 0.03em;
      text-transform: uppercase;
    `,
    tickerText: `
      font-family: neuebit, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 4.800vw;
      line-height: 100%;
      letter-spacing: -0.01em;
    `,
  },
};

export default text;
