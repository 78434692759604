import React, { useRef, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import gsap from "gsap";
import { ScreenContext } from "App";
import colors from "styles/colors";
import media from "styles/media";
import text from "styles/text";
//@ts-ignore
import { ReactComponent as TikTokSVG } from "assets/svg/tikTokLogo.svg";
import hero1 from "assets/images/exhibit/hero1.jpg";
import hero2 from "assets/images/exhibit/hero2.jpg";
import hero3 from "assets/images/exhibit/hero3.jpg";
import hero4 from "assets/images/exhibit/hero4.jpg";
import hero5 from "assets/images/exhibit/hero5.jpg";
import hero6 from "assets/images/exhibit/hero6.jpg";
import hero7 from "assets/images/exhibit/hero7.jpg";

import { ReactComponent as ArrowSVG } from "assets/images/symbols/arrow.svg";

const Hero: React.FC<{}> = () => {
  const screen = useContext(ScreenContext);
  const image1Ref = useRef<HTMLImageElement>(null);
  const image2Ref = useRef<HTMLImageElement>(null);
  const image3Ref = useRef<HTMLImageElement>(null);
  const image4Ref = useRef<HTMLImageElement>(null);
  const image5Ref = useRef<HTMLImageElement>(null);
  const image6Ref = useRef<HTMLImageElement>(null);
  const image7Ref = useRef<HTMLImageElement>(null);
  const museum = useRef<HTMLElement>(null);
  const topMoments = useRef<HTMLElement>(null);
  const ofthe = useRef<HTMLElement>(null);
  const moving = useRef<HTMLElement>(null);
  const nyc = useRef<HTMLElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  // const glow1Ref = useRef<HTMLCanvasElement>(null);
  // const glow2Ref = useRef<HTMLCanvasElement>(null);
  // const exhibitWrapper = useRef<HTMLDivElement>(null);
  const [heroPlay] = useState(true);

  useEffect(() => {
    const tl = gsap.timeline({
      repeat: -1,
      // scrollTrigger: {
      //   trigger: museum.current,
      //   toggleActions: "play pause resume pause",
      // },
      paused: true,
    });
    if (image1Ref.current) {
      tl.to(
        image1Ref.current,
        {
          width: screen.mobile ? "" : screen.tablet ? "06.3vw" : "05.9vw",
          duration: 5,
        },
        0
      )
        .to(
          image2Ref.current,
          {
            width: screen.mobile ? "" : screen.tablet ? "16.4vw" : "22.7vw",
            duration: 5,
          },
          0
        )
        .to(
          museum.current,
          {
            x: screen.mobile ? "" : screen.tablet ? "07.9vw" : "07.6vw",
            duration: 5,
          },
          0
        )
        .to(
          museum.current,
          {
            x: screen.mobile ? "" : screen.tablet ? "13.0vw" : "13.6vw",
            duration: 5,
          },
          5
        )
        .to(
          image1Ref.current,
          {
            width: screen.mobile ? "" : screen.tablet ? "11.4vw" : "11.8vw",
            duration: 5,
          },
          5
        )
        .to(
          image2Ref.current,
          {
            width: screen.mobile ? "" : screen.tablet ? "11.3vw" : "16.8vw",
            duration: 5,
          },
          5
        );
    }

    if (heroPlay) {
      tl.play();
    } else {
      tl.pause();
    }

    return () => {
      tl.kill();
    };
  }, [heroPlay, screen]);

  useEffect(() => {
    const tl = gsap.timeline({
      repeat: -1,
      // scrollTrigger: {
      //   trigger: ".m__wrapper",
      //   toggleActions: "play pause resume pause",
      // },
    });
    if (image3Ref.current) {
      tl.to(
        image3Ref.current,
        {
          width: screen.mobile ? "" : screen.tablet ? "42.0vw" : "43.8vw",
          duration: 6,
        },
        0
      )
        .to(
          image4Ref.current,
          {
            width: screen.mobile ? "" : screen.tablet ? "15.6vw" : "17.7vw",
            duration: 6,
          },
          0
        )
        .to(
          ofthe.current,
          {
            x: screen.mobile ? "" : screen.tablet ? "43.5vw" : "45.8vw",
            duration: 6,
          },
          0
        )
        .to(
          ofthe.current,
          {
            x: screen.mobile ? "" : screen.tablet ? "52.7vw" : "57.2vw",
            duration: 6,
          },
          6
        )
        .to(
          image3Ref.current,
          {
            width: screen.mobile ? "" : screen.tablet ? "51.3vw" : "55.6vw",
            duration: 6,
          },
          6
        )
        .to(
          image4Ref.current,
          {
            width: screen.mobile ? "" : screen.tablet ? "06.3vw" : "05.9vw",
            duration: 6,
          },
          6
        );
    }
    if (heroPlay) {
      tl.play();
    } else {
      tl.pause();
    }

    return () => {
      tl.kill();
    };
  }, [heroPlay, screen]);

  useEffect(() => {
    const tl = gsap.timeline({
      repeat: -1,
      // scrollTrigger: {
      //   trigger: ".m__wrapper",
      //   toggleActions: "play pause resume pause",
      // },
      paused: true,
    });
    if (image5Ref.current) {
      tl.to(
        image5Ref.current,
        {
          width: screen.mobile ? "" : screen.tablet ? "12.7vw" : "17.7vw",
          duration: 7,
        },
        0
      )
        .to(
          image6Ref.current,
          {
            width: screen.mobile ? "" : screen.tablet ? "11.7vw" : "12.3vw",
            duration: 7,
          },
          0
        )
        .to(
          moving.current,
          {
            x: screen.mobile ? "" : screen.tablet ? "14.2vw" : "19.8vw",
            duration: 7,
          },
          0
        )
        .to(
          moving.current,
          {
            x: screen.mobile ? "" : screen.tablet ? "07.8vw" : "06.9vw",
            duration: 7,
          },
          7
        )
        .to(
          image5Ref.current,
          {
            width: screen.mobile ? "" : screen.tablet ? "06.3vw" : "05.9vw",
            duration: 7,
          },
          7
        )
        .to(
          image6Ref.current,
          {
            width: screen.mobile ? "" : screen.tablet ? "18.1vw" : "24.1vw",
            duration: 7,
          },
          7
        );
    }
    if (heroPlay) {
      tl.play();
    } else {
      tl.pause();
    }

    return () => {
      tl.kill();
    };
  }, [heroPlay, screen]);

  useEffect(() => {
    const tl = gsap.timeline({
      repeat: -1,
      // scrollTrigger: {
      //   trigger: ".m__wrapper",
      //   toggleActions: "play pause resume pause",
      // },
      paused: true,
    });
    if (image5Ref.current) {
      tl.to(
        image7Ref.current,
        {
          width: screen.mobile ? "" : screen.tablet ? "37.6vw" : "47.4vw",
          duration: 5.6,
        },
        0
      )
        .to(
          buttonRef.current,
          {
            width: screen.mobile ? "" : screen.tablet ? "32.2vw" : "25.7vw",
            duration: 5.6,
          },
          0
        )
        .to(
          nyc.current,
          {
            x: screen.mobile ? "" : screen.tablet ? "39.1vw" : "49.4vw",
            duration: 5.6,
          },
          0
        )
        .to(
          nyc.current,
          {
            x: screen.mobile ? "" : screen.tablet ? "51.8vw" : "61.2vw",
            duration: 5.6,
          },
          5.6
        )
        .to(
          image7Ref.current,
          {
            width: screen.mobile ? "" : screen.tablet ? "50.3vw" : "59.2vw",
            duration: 5.6,
          },
          5.6
        )
        .to(
          buttonRef.current,
          {
            width: screen.mobile ? "" : screen.tablet ? "19.5vw" : "13.9vw",
            duration: 5.6,
          },
          5.6
        );
    }
    if (heroPlay) {
      tl.play();
    } else {
      tl.pause();
    }

    return () => {
      tl.kill();
    };
  }, [heroPlay, screen]);

  useEffect(() => {
    const tl = gsap.timeline({
      // scrollTrigger: {
      //   trigger: ".m__wrapper",
      //   toggleActions: "play pause resume pause",
      // },
      repeat: -1,
      paused: true,
    });
    tl.to(".m__text", {
      x: "-102%",
      ease: "none",
      duration: window.innerWidth > 1200 ? 35 : 20,
    }).to(".m__text", { x: 0, duration: 0 });
    if (heroPlay) {
      tl.play();
    } else {
      tl.pause();
    }

    return () => {
      tl.kill();
    };
  }, [heroPlay]);

  // useEllipseGlow(
  //   glow1Ref,
  //   glow1Ref,
  //   0.5,
  //   0.5,
  //   0.1355,
  //   0.3785,
  //   Math.PI * 0.25,
  //   colors.razzmatazz100,
  //   0.4
  // );

  // useEllipseGlow(
  //   glow2Ref,
  //   glow2Ref,
  //   0.5,
  //   0.5,
  //   0.1355,
  //   0.3785,
  //   Math.PI * 0.2,
  //   colors.splash100,
  //   0.4
  // );

  // useEffect(() => {
  //   window.locomotiveScroll.on("call", (args: string) => {
  //     newState.current = !newState.current;
  //     setHeroPlay(newState.current);
  //   });
  // }, []);

  return (
    <Wrapper
      data-scroll
      data-scroll-section
      // data-scroll-repeat
      // data-scroll-call="handleInView"
    >
      <VisitText>Visit the Exhibit ↓</VisitText>
      <Moving className="m__wrapper">
        <TopRow>
          <Text className="m__text" top>
            <Tiktok /> TIKTOK TOP MOMENTS
          </Text>
          <Text className="m__text" ref={topMoments} top>
            <Tiktok /> TIKTOK TOP MOMENTS
          </Text>
        </TopRow>
        <Row>
          <Image1 src={hero1} ref={image1Ref} />
          <Text
            ref={museum}
            left={screen.mobile ? "" : screen.tablet ? "13vw" : "13.6vw"}
            outlined
          >
            @ THE MUSEUM
          </Text>
          <Image2 src={hero2} ref={image2Ref} />
        </Row>
        <Row>
          <Image3 src={hero3} ref={image3Ref} />
          <Text
            ref={ofthe}
            left={screen.mobile ? "" : screen.tablet ? "52.7vw" : "57.2vw"}
            outlined
          >
            OF THE
          </Text>
          <Image4 src={hero4} ref={image4Ref} />
        </Row>
        <Row>
          <Image5 src={hero5} ref={image5Ref} />
          <Text
            ref={moving}
            left={screen.mobile ? "" : screen.tablet ? "7.8vw" : "6.9vw"}
            outlined
          >
            MOVING IMAGE
          </Text>
          <Image6 src={hero6} ref={image6Ref} />
        </Row>
        <Row>
          <Image7 src={hero7} ref={image7Ref} />
          <Text
            ref={nyc}
            left={screen.mobile ? "" : screen.tablet ? "51.8vw" : "61.2vw"}
            red
          >
            NYC
          </Text>
          <Button ref={buttonRef}>
            <Arrow />
            <span>Visit the exhibit</span>
          </Button>
        </Row>
      </Moving>
    </Wrapper>
  );
};

export default Hero;

const Wrapper = styled.section`
  margin-bottom: 6.9vw;
  ${media.desktop} {
    padding-top: 7.8vw;
    padding-left: 3.5vw;
    padding-right: 3.5vw;
  }

  ${media.tablet} {
    padding-top: 12.21vw;
    padding-left: 4.9vw;
    padding-right: 4.9vw;
  }

  ${media.mobile} {
    padding-left: 6.7vw;
    padding-right: 6.7vw;
  }
`;

const VisitText = styled.h1`
  display: none;
  font: 700 2.3vw/90% "NeueBit";
  color: ${colors.razzmatazz100};
  letter-spacing: 0.03em;
  text-transform: uppercase;
  margin-bottom: 2vw;
  ${media.tablet} {
    display: block;
  }
`;

const Moving = styled.div`
  ${media.tablet} {
  }
  ${media.mobile} {
  }
  ${media.fullWidth} {
  }
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  height: 9.4vw;
  border-top: 1px solid ${colors.offWhite};
  padding: 1.7vw 0;
  position: relative;
  ${media.tablet} {
    height: 10.2vw;
  }
  ${media.mobile} {
  }
  ${media.fullWidth} {
  }
`;

const Text = styled.h2<{
  outlined?: boolean;
  red?: boolean;
  top?: boolean;
  left?: string;
  ref?: any;
}>`
  ${text.desktop.displayH1};
  pointer-events: none;
  margin: 0;
  font-weight: 800;
  color: ${(props) =>
    props.outlined
      ? "transparent"
      : props.red
      ? colors.razzmatazz100
      : colors.offWhite};

  ${(props) =>
    props.outlined &&
    `-webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: ${colors.offWhite};`}
  white-space: nowrap;
  letter-spacing: -0.05em;
  position: ${(props) => (props.top ? "relative" : "absolute")};
  left: 0;
  transform: translateX(${(props) => props.left});
  ${media.tablet} {
    ${text.tablet.displayH1}
    font-size: 8.7vw;
  }
  ${media.mobile} {
  }
  ${media.fullWidth} {
  }
`;

const TopRow = styled.div`
  width: 100%;
  display: flex;
  height: 9.4vw;
  border-top: 1px solid ${colors.offWhite};
  padding: 1.7vw 0;
  position: relative;
  overflow: hidden;

  ${Text} {
    margin: 0 1vw;
  }
  ${media.tablet} {
  }
  ${media.mobile} {
  }
  ${media.fullWidth} {
  }
`;

const Tiktok = styled(TikTokSVG)`
  width: 5.1vw;
  height: 5.9vw;

  ${media.tablet} {
  }
  ${media.mobile} {
  }
  ${media.fullWidth} {
  }
`;

const Image = styled.img`
  object-fit: cover;
  object-position: center center;
  height: 5.9vw;
  border-radius: 6.3vw;
  position: absolute;
`;

const Image1 = styled(Image)`
  width: 11.8vw;

  left: 0;
  ${media.tablet} {
    width: 11.4vw;
  }
  ${media.mobile} {
  }
  ${media.fullWidth} {
  }
`;

const Image2 = styled(Image)`
  width: 16.8vw;
  right: 0;
  ${media.tablet} {
    width: 11.3vw;
  }
  ${media.mobile} {
  }
  ${media.fullWidth} {
  }
`;

const Image3 = styled(Image)`
  width: 55.6vw;
  left: 0;
  ${media.tablet} {
    width: 51.3vw;
  }
  ${media.mobile} {
  }
  ${media.fullWidth} {
  }
`;

const Image4 = styled(Image)`
  width: 5.9vw;
  right: 0;
  ${media.tablet} {
    width: 6.3vw;
  }
  ${media.mobile} {
  }
  ${media.fullWidth} {
  }
`;

const Image5 = styled(Image)`
  width: 5.9vw;
  left: 0;
  ${media.tablet} {
    width: 6.3vw;
  }
  ${media.mobile} {
  }
  ${media.fullWidth} {
  }
`;

const Image6 = styled(Image)`
  width: 24.1vw;
  right: 0;
  ${media.tablet} {
    width: 18.1vw;
  }
  ${media.mobile} {
  }
  ${media.fullWidth} {
  }
`;

const Image7 = styled(Image)`
  width: 59.2vw;
  left: 0;
  ${media.tablet} {
    width: 50.3vw;
  }
  ${media.mobile} {
  }
  ${media.fullWidth} {
  }
`;

const Arrow = styled(ArrowSVG)`
  transform: rotate(180deg);
  transform-origin: 50% 50%;
  width: 2.6vw;
  height: 2.6vw;
  position: absolute;
  left: 2vw;
  top: 1.6vw;
  ${media.tablet} {
  }
  ${media.mobile} {
  }
  ${media.fullWidth} {
  }
`;

const Button = styled.button`
  position: absolute;
  right: 0;
  width: 13.9vw;
  height: 5.9vw;
  padding: 1.7vw 2.4vw;
  background: transparent;
  border: 2px solid ${colors.offWhite};
  border-radius: 6.9vw;
  color: ${colors.offWhite};

  span {
    position: absolute;
    right: 1.7vw;
    top: 1.5vw;
    ${text.desktop.buttonText};
    width: 5.5vw;
    text-align: left;
  }

  ${media.tablet} {
    width: 19.5vw;
    span {
      ${text.tablet.tickerText}
      width: 9.8vw;
      top: 2vw;
    }
  }
  ${media.mobile} {
  }
  ${media.fullWidth} {
  }
`;

// const Glow = styled.canvas`
//   position: absolute;
//   width: 52.3vw;
//   height: 52.3vw;
//   filter: blur(6.9vw);
//   z-index: -1;
//   ${media.mobile} {
//     width: 62.1vw;
//     height: 51.7vw;
//   }
// `;

// const Glow1 = styled(Glow)`
//   top: 16.9vw;
//   left: 25.7vw;
//   ${media.tablet} {
//     top: -35%;
//     left: 7%;
//   }
//   ${media.mobile} {
//     top: -40%;
//     left: 12%;
//   }
// `;

// const Glow2 = styled(Glow)`
//   top: 0;

//   left: 16.6vw;
//   ${media.mobile} {
//     top: -5%;
//     left: 18%;
//   }
// `;
