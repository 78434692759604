import React, { useRef } from "react";
import styled from "styled-components";
import gsap from "gsap";

import media from "styles/media";
import colors from "styles/colors";
import text from "styles/text";

import { useMedia } from "utils/Hooks";

import { ReactComponent as ArrowSVG } from "assets/images/symbols/arrow.svg";

type props = {
  children: any;
  className?: string;
  onClick: any;
  mobileClick?: boolean;
  onMouseEnter?: any;
  onMouseLeave?: any;
  noArrow?: boolean
  width?: string
  textAlign?: string
};

const PrimaryButton: React.FC<props> = ({
  children,
  className,
  onClick,
  mobileClick,
  onMouseEnter,
  onMouseLeave,
  noArrow,
  width,
  textAlign
}) => {
  const mainDivRef = useRef(null);
  const arrowRef = useRef(null);
  const arrow2Ref = useRef(null);
  const pinkDivRef = useRef(null);
  const blueDivRef = useRef(null);

  const pinkTransform = useMedia("0.278vw", "0.278vw", "0.391vw", "1.067vw");
  const blueTransform = useMedia(
    "-0.278vw",
    "-0.278vw",
    "-0.391vw",
    "-1.067vw"
  );

  const handleMouseEnter = () => {
    if (onMouseEnter) onMouseEnter()
    if (window.innerWidth > 420) {
      const tl = gsap.timeline({
        onComplete: () => {
          tl.kill();
        },
      });

      tl.to(mainDivRef.current, {
        duration: 0.2,
        color: colors.offWhite,
        backgroundColor: colors.richBlack100,
      });

      tl.to(
        arrowRef.current,
        {
          duration: 0.2,
          x: "150%",
          y: "-150%",
        },
        0
      );

      tl.to(
        arrow2Ref.current,
        {
          duration: 0.2,
          x: "0%",
          y: "0%",
        },
        0
      );

      tl.to(
        pinkDivRef.current,
        {
          duration: 0.2,
          x: pinkTransform,
          y: pinkTransform,
        },
        0
      );

      tl.to(
        blueDivRef.current,
        {
          duration: 0.2,
          x: blueTransform,
          y: blueTransform,
        },
        0
      );
      return () => {
        tl.kill();
      };
    }
  };

  const handleMouseLeave = () => {
    if (onMouseLeave) onMouseLeave();
    const tl = gsap.timeline({
      onComplete: () => {
        tl.kill();
      },
    });

    tl.to(
      mainDivRef.current,
      {
        duration: 0.2,
        color: colors.richBlack100,
        backgroundColor: colors.offWhite,
      },
      0
    );

    tl.to(
      arrowRef.current,
      {
        duration: 0.2,
        x: "0%",
        y: "0%",
      },
      0
    );

    tl.to(
      arrow2Ref.current,
      {
        duration: 0.2,
        x: "-150%",
        y: "150%",
      },
      0
    );

    tl.to(
      pinkDivRef.current,
      {
        duration: 0.2,
        x: "0vw",
        y: "0vw",
      },
      0
    );

    tl.to(
      blueDivRef.current,
      {
        duration: 0.2,
        x: "0vw",
        y: "0vw",
      },
      0
    );

    return () => {
      tl.kill();
    };
  };

  return (
    <Button
      className={className}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      width={width}
    >
      <PinkDiv width={width} ref={pinkDivRef} />
      <BlueDiv width={width} ref={blueDivRef} />
      <MainDiv width={width} ref={mainDivRef} mobileClick={mobileClick} textAlign={textAlign}>
        {children}
        {!noArrow && <ArrowWrapper>
          <Arrow ref={arrowRef} />
          <Arrow2 ref={arrow2Ref} />
        </ArrowWrapper>}
      </MainDiv>
    </Button>
  );
};

export default PrimaryButton;

const buttonSize = `
  height: 4.17vw;
  border-radius: 2.08vw;

  ${media.tablet} {
    height: 5.859vw;
    border-radius: 2.93vw;
  }

  ${media.mobile} {
    height: 16vw;
    border-radius: 8vw;
  }
`;

const Button = styled.button<{width?: string}>`
  position: relative;
  cursor: pointer;
  border: none;

  ${buttonSize}

  width: ${props => props.width || '20.56vw'};

  ${media.tablet} {
    width: ${props => props.width || '28.906vw'};
  }

  ${media.mobile} {
    width: ${props => props.width || '78.933vw'};
  }
`;

const ArrowWrapper = styled.div`
  position: absolute;
  overflow: hidden;

  right: 1.88vw;
  width: 2.083vw;
  height: 2.083vw;

  ${media.tablet} {
    right: 2.637vw;
    width: 2.93vw;
    height: 2.93vw;
  }

  ${media.mobile} {
    right: 7.2vw;
    width: 8vw;
    height: 8vw;
  }
`;

const Arrow = styled(ArrowSVG)`
  position: absolute;
  height: auto;
  width: 1.319vw;
  top: 0.417vw;
  left: 0.417vw;

  path {
    stroke: ${colors.richBlack100};
  }

  ${media.tablet} {
    width: 1.855vw;
    top: 0.586vw;
    left: 0.586vw;
  }

  ${media.mobile} {
    width: 5.067vw;
    top: 1.6vw;
    left: 1.333vw;
  }
`;

const Arrow2 = styled(Arrow)`
  transform: translate(-150%, 150%);

  path {
    stroke: ${colors.offWhite};
  }
`;

const Div = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  ${buttonSize}
`;

const MainDiv = styled(Div)<{ mobileClick: boolean, width?: string, textAlign?: string }>`
  background-color: ${(props) =>
    props.mobileClick ? colors.richBlack20 : colors.offWhite};
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: ${props => props.textAlign || 'flex-start'};
  text-transform: uppercase;
  color: ${colors.richBlack100};
  transition: background-color 0.3s;
  ${text.desktop.buttonText}
  padding-left: 1.88vw;
  padding-right: 1.88vw;
  width: ${props => props.width || '20.56vw'};

  ${media.tablet} {
    ${text.tablet.buttonText}
    padding-left: 2.93vw;
    padding-right: 2.93vw;
    width: ${props => props.width || '28.906vw'};
  }

  ${media.mobile} {
    ${text.mobile.buttonText}
    padding-left: 8vw;
    padding-right: 8vw;
    width: ${props => props.width || '78.933vw'};


  }
`;

const PinkDiv = styled(Div)<{width?: string}>`
  background-color: ${colors.razzmatazz100};
  z-index: 1;

  width: ${props => props.width || '20.56vw'};

  ${media.tablet} {
    width: ${props => props.width || '28.906vw'};
  }

  ${media.mobile} {
    width: ${props => props.width || '78.933vw'};

  }
`;

const BlueDiv = styled(Div)<{width?: string}>`
  background-color: ${colors.splash100};
  z-index: 1;

  width: ${props => props.width || '20.56vw'};

  ${media.tablet} {
    width: ${props => props.width || '28.906vw'};
  }

  ${media.mobile} {
    width: ${props => props.width || '78.933vw'};

  }
`;
