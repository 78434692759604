import React, { useContext, useEffect, useRef } from "react"
import styled from "styled-components"
import gsap from 'gsap'
import { useEllipseGlow } from "utils/Hooks"
import { ScreenContext } from "App"
import colors from "styles/colors"
import text from "styles/text"

import {ReactComponent as ArrowSVG} from '../assets/svg/whiteArrow.svg'
import Media from "styles/media"

type Props = {
  className: string;
  index: number;
  handleClick: (index: number) => void;
  question: {
    title: string;
    answerHTML: JSX.Element;
    glow: {
      color: string;
      positionX: {
        desktop: number
        tablet: number
        mobile: number
      };
      positionY: {
        desktop: number
        tablet: number
        mobile: number
      };
    }
  }
}

const FAQQuestion: React.FC<Props> = ({ className, handleClick, question, index }) => {

  const wrapperRef = useRef<HTMLDivElement>(null)
  const glowRef = useRef<HTMLCanvasElement>(null)

  const screen = useContext(ScreenContext)

  useEllipseGlow(
    glowRef,
    glowRef,
    question.glow.positionX[screen.mobile ? 'mobile' : screen.tablet ? 'tablet' : 'desktop'],
    question.glow.positionY[screen.mobile ? 'mobile' : screen.tablet ? 'tablet' : 'desktop'],
    screen.mobile ? 0.2240 : screen.tablet ? 0.1318 : 0.1355,
    screen.mobile ? 0.6253 : screen.tablet ? 0.3682 : 0.3785,
    screen.mobile ? Math.PI * -0.22 : screen.tablet ? Math.PI * -0.22 : Math.PI * -0.722,
    question.glow.color,
    0.6
  )

  const redStagger = useRef<HTMLDivElement>(null)
  const blueStagger = useRef<HTMLDivElement>(null)
  const whiteStagger = useRef<HTMLDivElement>(null)
  const title = useRef<HTMLHeadingElement>(null)

  const timeline = useRef(null)


  useEffect(() => {
    let tl = gsap.timeline({ paused: true })
    tl.to(redStagger.current, { top: '0', duration: 0.3, ease: 'circ-out'}, 0)
    tl.to(blueStagger.current, { top: '0', duration: 0.3, ease: 'circ-out'}, 0.05)
    tl.to(whiteStagger.current, { top: '0', duration: 0.3, ease: 'circ-out'}, 0.1)
    tl.to(title.current, { bottom: '1vw', color: '#000', duration: 0.3, ease: 'circ-out'}, 0.1)
    tl.to(`#faq_desktopArrow-${index} path`, { stroke: '#000', duration: 0.3, ease: 'circ-out'}, 0.1)
    timeline.current = tl
  }, [index])

  const beforeHandleClick = () => {
    if (className !== "question-current") {
      timeline.current.reverse()
      handleClick(index)
    }
    // else if (screen.mobile) {
    //   handleClick(-1)
    // }
    else return
  }


  return <Wrapper ref={wrapperRef} className={className} onClick={beforeHandleClick}>
    <HoverContainer onMouseEnter={() => timeline.current.play()} onMouseOut={() => timeline.current.reverse()}>
      <RedStagger ref={redStagger}/>
      <BlueStagger ref={blueStagger}/>
      <WhiteStagger ref={whiteStagger}/>
    </HoverContainer>
    <Glow ref={glowRef} />
    <SideTitle ref={title}>{question.title}<Arrow id={`faq_desktopArrow-${index}`}/></SideTitle>
    <Content>
      <Title>{question.title}<MobileArrow/></Title>
      <Divider />
      {question.answerHTML}
    </Content>
  </Wrapper>
}

const Glow = styled.canvas`
  position: absolute;
  width: 68.25vw;
  height: 53.5vw;
  top: 0;
  left: 0;
  filter: blur(75px);
  ${Media.tablet} {
    width: 100%;
    height: 70.4vw;
    filter: blur(75px);
  }
  ${Media.mobile} {
    display: none;
    width: 100%;
    height: 282.6vw;
    z-index: 0;
  }
`

const Arrow = styled(ArrowSVG)`
  width: 1.5vw;
  height: auto;
  transform: rotate(90deg);
  transition: transform 300ms cubic-bezier(0.5, 0, 0.5, 1) 300ms;
`

const SideTitle = styled.h1`
  ${text.desktop.headerH4}
  line-height: 1;
  letter-spacing: -0.03em;
  color: ${colors.offWhite};
  transform: rotate(270deg);
  transform-origin: 0 100%;
  position: absolute;
  padding: 1.4vw 2.1vw;
  bottom: 0;
  left: 5.2vw;
  width: 53.3vw;
  height: 5.2vw;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  z-index: 6;
  ${Media.tablet} {
    display: none;
  }
  ${Media.mobile} {
    display: none;
  }
`

const BlueStagger = styled.div`
  position: absolute;
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 5.2vw;
  left: 0;
  /* z-index: 4; */
  background: ${colors.splash100};
  ${Media.tablet} {
    display: none;
  }
  ${Media.mobile} {
    display: none;
  }
`

const RedStagger = styled(BlueStagger)`
  background: ${colors.razzmatazz100};
  /* z-index: 3; */
  ${Media.tablet} {
    display: none;
  }
  ${Media.mobile} {
    display: none;
  }
`

const WhiteStagger = styled(BlueStagger)`
  background: ${colors.offWhite};
  /* z-index: 4; */
  ${Media.tablet} {
    display: none;
  }
  ${Media.mobile} {
    display: none;
  }
`

const HoverContainer = styled.div`
  background: none;
  width: 53.3vw;
  height: 5.2vw;
  z-index: 5;
  position: absolute;
  left: 5.2vw;
  bottom: 0;
  transform: rotate(270deg);
  transform-origin: 0 100%;
  overflow: hidden;
  ${Media.tablet} {
    pointer-events: none;
    transform: none;
  }
  ${Media.mobile} {
    pointer-events: none;
    transform: none;
  }
`

const Content = styled.div`
  margin: 4.2vw 0 4.8vw 6.1vw;
  width: 46vw;
  position: relative;
  color: ${colors.offWhite};
  p, li {
    ${text.desktop.headerBodyCopy2}
    margin: 0;
  }
  a {
    text-decoration: underline;
    color: ${colors.splash100};
  }
  ${Media.tablet} {
    margin: 0;
    width: 100%;
    p, li {
      ${text.tablet.headerBodyCopy}
      margin-left: 11.4vw;
      width: 67.4vw;
    }
  }
  ${Media.mobile} {
    margin: 0;
    width: 100%;
    p, li {
      ${text.mobile.headerBodyCopy2}
    }
    ul {
      padding: 5vw;
    }
  }
`

const Title = styled.h1`
  ${text.desktop.headerH2}
  color: ${colors.offWhite};
  margin: 0 0 2.1vw;
  ${Media.tablet} {
    ${text.tablet.headerH3}
    margin-bottom: 5.9vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  ${Media.mobile} {
    ${text.mobile.headerH4}
    margin-bottom: 6.7vw;
    position: relative;
  }
`

const MobileArrow = styled(ArrowSVG)`
  display: none;
  height: auto;
  width: 2vw;
  transform: rotate(135deg);
  transition: transform 300ms cubic-bezier(0.5, 0, 0.5, 1) 300ms;
  ${Media.tablet} {
    display: initial;
  }
`

const Divider = styled.div`
  width: 6.8vw;
  height: 1px;
  background: ${colors.offWhite};
  margin-bottom: 2.1vw;
  ${Media.tablet} {
    display: none;
  }
  ${Media.mobile} {
    display: none;
  }
`

const Wrapper = styled.div`
  border: 1px solid ${colors.offWhite};
  border-right: none;
  box-sizing: border-box;
  overflow: hidden;
  padding-left: 5.2vw;
  position: relative;
  width: 5.2vw;
  flex-grow: 0;
  transition: flex-grow 300ms cubic-bezier(0.5, 0, 0.5, 1) 250ms;
  &.question-current {
    flex-grow: 1;
    ${HoverContainer} {
      pointer-events: none;
    }
    ${MobileArrow} {
      transform: rotate(90deg);
    }
  }
  &.question-next ${Arrow} {
    transform: rotate(180deg);
  }
  ${Media.tablet} {
    width: 100%;
    height: 14.2vw;
    padding: 5vw 5vw 0;
    transition-delay: 0;
    border: none;
    border-top: 1px solid ${colors.offWhite};
  }
  ${Media.mobile} {
    width: 100%;
    height: 27vw;
    padding: 6.7vw 6.7vw 0;
    border: none;
    border-top: 1px solid ${colors.offWhite};
    transition: height 200ms ease-out;
    &:nth-of-type(1), &:nth-of-type(3) {
      height: 20vw
    }
    &.question-current {
      flex-grow: 0;
      padding-bottom: 21.9vw;
      &:nth-of-type(1) { height: 238.1vw }
      &:nth-of-type(2) { height: 174.7vw }
      &:nth-of-type(3) { height: 86.9vw }
      &:nth-of-type(4) { height: 261.1vw }
      &:nth-of-type(5) { height: 232.3vw }
      &:nth-of-type(6) { height: 282.7vw }
      &:nth-of-type(7) { height: 81.1vw }
    }
  }
`

export default FAQQuestion
