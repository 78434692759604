import { keyframes } from "styled-components"

const animations = {
  spin: keyframes`
    from { transform: rotate(0) }
    to { transform: rotate(360deg) }
  `,
  nudgeDown: keyframes`
    0% { transform: none }
    5% { transform: translateY(1vw) }
    10% { transform: none }
    15% { transform: translateY(1vw) }
    20% { transform: none}
  `
}

export default animations
