import React, { useEffect, useRef, useContext, useCallback } from 'react'
import styled from 'styled-components'
import gsap from 'gsap'

import { ScreenContext } from 'App'

import colors from 'styles/colors'
import text from 'styles/text'
import media from 'styles/media'

import { useMedia } from 'utils/Hooks'

import {ReactComponent as TikTokSVG} from 'assets/svg/tiktok.svg'
import {ReactComponent as RedDotSVG} from 'assets/svg/redDot.svg'
import {ReactComponent as BitskiSVG} from 'assets/svg/bitskiLogomarkBlue.svg'

const FirstPreloader: React.FC = () => {
  const screen = useContext(ScreenContext)

  const line1Ref = useRef(null)
  const line2Ref = useRef(null)
  const line3Ref = useRef(null)
  const line4Ref = useRef(null)
  const line5Ref = useRef(null)
  const tiktokPinkRef = useRef(null)
  const tiktokBlueRef = useRef(null)
  const tiktokWhiteRef = useRef(null)
  const topMomentsPinkRef = useRef(null)
  const topMomentsBlueRef = useRef(null)
  const topMomentsWhiteRef = useRef(null)
  const bitskiPinkRef = useRef(null)
  const bitskiBlueRef = useRef(null)
  const bitskiWhiteRef = useRef(null)
  const dropsRef = useRef(null)
  const pinkDivRef = useRef(null)
  const blueDivRef = useRef(null)
  const pinkDiv2Ref = useRef(null)
  const blueDiv2Ref = useRef(null)
  const coverRef = useRef(null)
  const wrapperRef = useRef(null)

  const finalAnimation = useCallback(() => {
    const tl = gsap.timeline({
      onComplete: () => {
        tl.kill()
        window.locomotiveScroll.start()
        window.localStorage.setItem('firstLoaderRan', 'true')
        gsap.set(wrapperRef.current, {
          display: 'none'
        })
      }
    })

    tl.to(wrapperRef.current, {
      duration: 1,
      height: "0vh",
      ease: "circ.inOut"
    }, 0)

    tl.to(blueDiv2Ref.current, {
      duration: 0.5,
      height: "20vw",
      ease: "circ.in"
    }, 0)

    tl.to(pinkDiv2Ref.current, {
      duration: 0.5,
      height: '0vw',
      ease: "circ.out"
    }, 0.5)
    tl.to(pinkDiv2Ref.current, {
      duration: 0.5,
      height: "35vw",
      ease: "circ.in"
    }, 0)

    tl.to(blueDiv2Ref.current, {
      duration: 0.5,
      height: '0vw',
      ease: "circ.out"
    }, 0.5)
  }, [])

  const textTop = useMedia('1.389vw', '1.389vw', '2.441vw', '4vw')
  const textAnimation = useCallback(() => {
    const tl = gsap.timeline({
      onComplete: () => {
        tl.kill()
        finalAnimation()
      }
    })

    tl.fromTo([line1Ref.current, line2Ref.current, line3Ref.current, line4Ref.current, line5Ref.current], {
      display: 'flex'
    }, {
      duration: 1.25,
      stagger: 0.25,
      width: '100%',
      ease: "circ.out"
    }, 0)
    

    tl.to(tiktokPinkRef.current, {
      duration: 2,
      top: textTop,
      ease: "circ.out"
    }, 0)
    tl.to(tiktokBlueRef.current, {
      duration: 1.75,
      top: textTop,
      ease: "circ.out"
    }, 0.25)
    tl.to(tiktokWhiteRef.current, {
      duration: 1.5,
      top: textTop,
      ease: "circ.out"
    }, 0.5)

    tl.to(topMomentsPinkRef.current, {
      duration: 1.25,
      top: textTop,
      ease: "circ.out"
    }, 0.75)
    tl.to(topMomentsBlueRef.current, {
      duration: 1,
      top: textTop,
      ease: "circ.out"
    }, 1)
    tl.to(topMomentsWhiteRef.current, {
      duration: 0.75,
      top: textTop,
      ease: "circ.out"
    }, 1.25)

    tl.to(bitskiPinkRef.current, {
      duration: 1.25,
      top: textTop,
      ease: "circ.out"
    }, 1.15)
    tl.to(bitskiBlueRef.current, {
      duration: 1,
      top: textTop,
      ease: "circ.out"
    }, 1.40)
    tl.to(bitskiWhiteRef.current, {
      duration: 0.75,
      top: textTop,
      ease: "circ.out"
    }, 1.55)

    tl.to(dropsRef.current, {
      duration: 1,
      top: textTop,
      ease: "circ.out"
    }, 1.75)

    return () => {
      tl.kill()
    }
  }, [finalAnimation, textTop])

  useEffect(() => {
    window.locomotiveScroll.stop()

    const tl = gsap.timeline({
      onComplete: () => {
        tl.kill()
        textAnimation()
      }
    })

    tl.to(coverRef.current, {
      duration: 1,
      height: "0vh"
    }, 0)
    tl.to(pinkDivRef.current, {
      duration: 0.5,
      height: '35vw',
      ease: "circ.in"
    }, 0)
    tl.to(pinkDivRef.current, {
      duration: 0.5,
      height: '0vw',
      ease: "circ.out"
    }, 0.5)
    tl.to(blueDivRef.current, {
      duration: 0.5,
      height: '20vw',
      ease: "circ.in"
    }, 0)
    tl.to(blueDivRef.current, {
      duration: 0.5,
      height: '0vw',
      ease: "circ.out"
    }, 0.5)

    return () => {
      tl.kill()
    }
  }, [textAnimation])

  return (
    <Wrapper ref={wrapperRef}>
      <BlueDiv ref={blueDiv2Ref}/>
      <PinkDiv ref={pinkDiv2Ref}/>

      <Cover ref={coverRef}>
        <BlueDiv ref={blueDivRef}/>
        <PinkDiv ref={pinkDivRef}/>
      </Cover>

      <Content>
        <HR ref={line1Ref}/>
        <Row $height={useMedia('8.681vw', '8.681vw', '14.648vw', '24vw')}>
          <RowContent ref={tiktokWhiteRef} $zIndex={3}>
            <TikTok $color={colors.offWhite}/>
          </RowContent>
          <RowContent ref={tiktokBlueRef} $zIndex={2}>
            <TikTok $color={colors.splash100}/>
          </RowContent>
          <RowContent ref={tiktokPinkRef} $zIndex={1}>
            <TikTok $color={colors.razzmatazz100}/>
          </RowContent>
        </Row>
        <HR ref={line2Ref}/>
        <Row $height={useMedia('8.681vw', '8.681vw', '23.633vw', '40vw')}>
          <RowContent ref={topMomentsWhiteRef} $zIndex={3}>
            <Text $color={colors.offWhite}>tiktok top moments</Text>
          </RowContent>
          <RowContent ref={topMomentsBlueRef} $zIndex={2}>
            <Text $color={colors.splash100}>tiktok top moments</Text>
          </RowContent>
          <RowContent ref={topMomentsPinkRef} $zIndex={1}>
            <Text $color={colors.razzmatazz100}>tiktok top moments</Text>
          </RowContent>
        </Row>
        <HR ref={line3Ref}/>
        <Row $height={useMedia('8.681vw', '8.681vw', '23.633vw', '40vw')}>
          <RowContent ref={bitskiWhiteRef}  $zIndex={3} >
            {(screen.fullWidth || screen.desktop) && <Text $color={'transparent'} $textStroke={colors.offWhite}>Powered by bitski </Text>}
            {(screen.fullWidth || screen.desktop) && <Bitski $color={colors.offWhite}/>}
            {(screen.tablet) && <Text $color={'transparent'} $textStroke={colors.offWhite}>Powered by</Text>}
            {(screen.tablet) && <Text $color={'transparent'} $textStroke={colors.offWhite}>bitski<Bitski $color={colors.offWhite}/></Text>}
            {(screen.mobile) && <Text $color={'transparent'} $textStroke={colors.offWhite}>Powered</Text>}
            {(screen.mobile) && <Text $color={'transparent'} $textStroke={colors.offWhite}>by bitski<Bitski $color={colors.offWhite}/></Text>}
          </RowContent>
          <RowContent ref={bitskiBlueRef} $zIndex={2} >
            {(screen.fullWidth || screen.desktop) && <Text $color={'transparent'} $textStroke={colors.splash100}>Powered by bitski</Text>}
            {(screen.fullWidth || screen.desktop) && <Bitski $color={colors.splash100}/>}
            {(screen.tablet) && <Text $color={'transparent'} $textStroke={colors.splash100}>Powered by</Text>}
            {(screen.tablet) && <Text $color={'transparent'} $textStroke={colors.splash100}>bitski<Bitski $color={colors.offWhite}/></Text>}
            {(screen.mobile) && <Text $color={'transparent'} $textStroke={colors.splash100}>Powered</Text>}
            {(screen.mobile) && <Text $color={'transparent'} $textStroke={colors.splash100}>by bitski<Bitski $color={colors.offWhite}/></Text>}
          </RowContent>
          <RowContent ref={bitskiPinkRef} $zIndex={1} >
            {(screen.fullWidth || screen.desktop) && <Text $color={'transparent'} $textStroke={colors.razzmatazz100}>Powered by bitski</Text>}
            {(screen.fullWidth || screen.desktop) && <Bitski $color={colors.razzmatazz100}/>}
            {(screen.tablet) && <Text $color={'transparent'} $textStroke={colors.razzmatazz100}>Powered by</Text>}
            {(screen.tablet) && <Text $color={'transparent'} $textStroke={colors.razzmatazz100}>bitski<Bitski $color={colors.offWhite}/></Text>}
            {(screen.mobile) && <Text $color={'transparent'} $textStroke={colors.razzmatazz100}>Powered</Text>}
            {(screen.mobile) && <Text $color={'transparent'} $textStroke={colors.razzmatazz100}>by bitski<Bitski $color={colors.offWhite}/></Text>}
          </RowContent>
        </Row>
        <HR ref={line4Ref}/>
        <Row $height={useMedia('8.681vw', '8.681vw', '23.633vw', '40vw')}> 
          <RowContent ref={dropsRef} $zIndex={1}>
            {(screen.fullWidth || screen.desktop) && <RedDot/>}
            {(screen.fullWidth || screen.desktop) && <Text $color={colors.razzmatazz100}>drops are live</Text>}
            {(screen.tablet || screen.mobile) && <Text $color={colors.razzmatazz100}>drops are</Text>}
            {(screen.tablet || screen.mobile) && <Text $color={colors.razzmatazz100}><RedDot/>live</Text>}  
          </RowContent>
        </Row>
        <HR ref={line5Ref}/>
      </Content>
    </Wrapper>
  )
}

export default FirstPreloader

const Wrapper = styled.section`
  background-color: ${colors.richBlack100};
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 99;
  overflow: hidden;
  
`

const Content = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;

  padding-top: 6.944vw;

  ${media.tablet} {
    padding-top: 14.648vw;
  }
  
  ${media.mobile} {
    padding-top: 20vw;
  }
`

const Row = styled.div<{$height: string}>`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: ${props => props.$height};

  padding-left: 3.472vw;

  ${media.tablet} {
    padding-left: 5.176vw;
  }

  ${media.mobile} {
    padding-left: 6.667vw;
  }
`

const Text = styled.span<{$color?: string, $textStroke?: string}>`
  ${text.desktop.displayH1}
  color: ${props => props.$color};
  display: flex;
  align-items: center;
  text-transform: uppercase;
  -webkit-text-stroke: ${props => props.$textStroke ? `1px ${props.$textStroke}` : 'unset'};

  ${media.tablet} {
    font-size: 11.719vw;
    line-height: 80%;
    font-weight: 800;
  }

  ${media.mobile} {
    ${text.mobile.displayH2}
  }
`

const TikTok = styled(TikTokSVG)<{$color: string}>`
  height: auto;
  width: 5.764vw;

  path {
    fill: ${props => props.$color};
    stroke-width: 1px;
  }

  ${media.tablet} {
    width: 8.496vw;
  }

  ${media.mobile} {
    width: 13.867vw;
  }
`

const Bitski = styled(BitskiSVG)<{$color: string}>`
  height: auto;
  width: 5.556vw;
  margin-left: 1.736vw;
  display: inline-block;

  path {
    stroke: ${props => props.$color};
    stroke-width: 1px;
    fill: transparent;
  }

  ${media.tablet} {
    width: 7.91vw;
  }

  ${media.mobile} {
    width: 10.133vw;
  }
`

const RedDot = styled(RedDotSVG)`
  height: auto;
  width: 2.778vw;
  margin-right: 0.972vw;

  ${media.tablet} {
    width: 3.906vw;
  }

  ${media.mobile} {
    width: 6.667vw;
  }
`

const RowContent = styled.div<{$zIndex?: number}>`
  position: absolute;
  z-index: ${props => props.$zIndex};
  top: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  ${media.tablet} {
    flex-direction: column;
    align-items: flex-start;
  }

  ${media.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`

const HR = styled.hr`
  height: 1px;
  background-color: ${colors.offWhite};
  width: 100%;
  outline: none;
  margin: 0vw;
  width: 0%;
  display: none;
`

const Cover = styled.div`
  background-color: ${colors.richBlack100};
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`

const BlueDiv = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 20vw;
  height: 0vw;
  background-color: ${colors.splash100};
`

const PinkDiv = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 35vw;
  height: 0vw;
  background-color: ${colors.razzmatazz100};
`
