import React from "react";
import styled from "styled-components";

import tiktok from "assets/svg/tikTokLogo.svg";
import bitski from "assets/svg/bitskiStroke.svg";

import text from "styles/text";
import colors from "styles/colors";
import media from "styles/media";

import { useMedia } from "utils/Hooks";

const Banners: React.FC<{}> = () => {
  return (
    <Wrapper data-scroll-section id="direction">
      <Banner>
        <TikTokLogo src={tiktok} alt="TikTok Logo" />
      </Banner>
      <Banner>
        <Marquee
          data-scroll-direction="horizontal"
          data-scroll-target="#direction"
          data-scroll
          data-scroll-speed={useMedia("12", "12", "12", "4")}
        >
          <Title>Tiktok top moments</Title>
          <Dot />
          <Title>Tiktok top moments</Title>
          <Dot />
        </Marquee>
        <Marquee
          data-scroll-direction="horizontal"
          data-scroll-target="#direction"
          data-scroll
          data-scroll-speed={useMedia("12", "12", "12", "4")}
        >
          <Title>Tiktok top moments</Title>
          <Dot />
          <Title>Tiktok top moments</Title>
          <Dot />
        </Marquee>
        <Marquee
          data-scroll-direction="horizontal"
          data-scroll-target="#direction"
          data-scroll
          data-scroll-speed={useMedia("12", "12", "12", "4")}
        >
          <Title>Tiktok top moments</Title>
          <Dot />
          <Title>Tiktok top moments</Title>
          <Dot />
        </Marquee>
        <Marquee
          data-scroll-direction="horizontal"
          data-scroll-target="#direction"
          data-scroll
          data-scroll-speed={useMedia("12", "12", "12", "4")}
        >
          <Title>Tiktok top moments</Title>
          <Dot />
          <Title>Tiktok top moments</Title>
          <Dot />
        </Marquee>
      </Banner>
      <Banner>
        <Marquee
          data-scroll-direction="horizontal"
          data-scroll-target="#direction"
          data-scroll
          data-scroll-speed={useMedia("15", "15", "15", "6")}
        >
          <BitskiTitle>powered by bitski</BitskiTitle>
          <BitskiLogo src={bitski} alt="Bitski Logo" />
          <BitskiTitle>powered by bitski</BitskiTitle>
          <BitskiLogo src={bitski} alt="Bitski Logo" />
        </Marquee>
        <Marquee
          data-scroll-direction="horizontal"
          data-scroll-target="#direction"
          data-scroll
          data-scroll-speed={useMedia("15", "15", "15", "6")}
        >
          <BitskiTitle>powered by bitski</BitskiTitle>
          <BitskiLogo src={bitski} alt="Bitski Logo" />
          <BitskiTitle>powered by bitski</BitskiTitle>
          <BitskiLogo src={bitski} alt="Bitski Logo" />
        </Marquee>
        <Marquee
          data-scroll-direction="horizontal"
          data-scroll-target="#direction"
          data-scroll
          data-scroll-speed={useMedia("15", "15", "15", "6")}
        >
          <BitskiTitle>powered by bitski</BitskiTitle>
          <BitskiLogo src={bitski} alt="Bitski Logo" />
          <BitskiTitle>powered by bitski</BitskiTitle>
          <BitskiLogo src={bitski} alt="Bitski Logo" />
        </Marquee>
        <Marquee
          data-scroll-direction="horizontal"
          data-scroll-target="#direction"
          data-scroll
          data-scroll-speed={useMedia("15", "15", "15", "6")}
        >
          <BitskiTitle>powered by bitski</BitskiTitle>
          <BitskiLogo src={bitski} alt="Bitski Logo" />
          <BitskiTitle>powered by bitski</BitskiTitle>
          <BitskiLogo src={bitski} alt="Bitski Logo" />
        </Marquee>
      </Banner>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  height: 51.1vw;
  padding-top: 12.01vw;

  ${media.tablet} {
    height: 71.88vw;
    padding-top: 16.89vw;
  }

  ${media.mobile} {
    height: 52.267vw;
    padding-top: 16vw;
  }
`;

const Banner = styled.div`
  width: 100%;
  overflow: hidden;
  border-top: solid 1px ${colors.offWhite};
  color: ${colors.offWhite};
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 8.33vw;

  :first-child {
    justify-content: flex-start;
  }

  :last-child {
    border-bottom: solid 1px ${colors.offWhite};
  }

  ${media.tablet} {
    height: 13.67vw;
  }

  ${media.mobile} {
    height: 12.267vw;
  }
`;

const Marquee = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  flex-shrink: 0;
`;

const TikTokLogo = styled.img`
  width: 5.15vw;
  margin-left: 3.47vw;

  ${media.tablet} {
    width: 7.23vw;
    margin-left: 4.883vw;
  }

  ${media.mobile} {
    width: 5.867vw;
    margin-left: 6.667vw;
  }
`;

const Title = styled.span`
  flex-shrink: 0;
  ${text.desktop.displayH1}
  margin-left: 1.74vw;
  margin-right: 1.74vw;

  ${media.tablet} {
    ${text.tablet.displayH1}
    margin-right: 2.44vw;
    margin-left: 2.44vw;
  }

  ${media.mobile} {
    font-size: 8.267vw;
    margin-right: 2.667vw;
    margin-left: 2.667vw;
  }
`;

const Dot = styled.div`
  border-radius: 50%;
  background-color: ${colors.razzmatazz100};
  flex-shrink: 0;
  width: 3.13vw;
  height: 3.13vw;

  ${media.tablet} {
    width: 4.39vw;
    height: 4.39vw;
  }

  ${media.mobile} {
    width: 15px;
    height: 15px;
  }
`;

const BitskiTitle = styled(Title)`
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  background-size: 100%;
  background-clip: text;
  -webkit-text-stroke-color: ${colors.offWhite};
  background-color: rgba(0, 0, 0, 0);
  -webkit-text-stroke-width: 3px;

  ${media.mobile} {
    -webkit-text-stroke-width: 0.5px;
  }
`;

const BitskiLogo = styled.img`
  width: 5.7vw;

  ${media.tablet} {
    width: 8.01vw;
  }

  ${media.mobile} {
    width: 5.8vw;
  }
`;

export default Banners;
