import React from "react";
import styled from "styled-components";
import PrimaryButton from "components/PrimaryButton";
import NFTPlayer from "components/NFTPlayer";

import colors from "styles/colors";
import text from "styles/text";
import media from "styles/media";

import { ReactComponent as DotSVG } from "assets/svg/redDot.svg";

type props = {
  left: boolean;
  title: string;
  price: string;
  description: string;
  uri: string;
  link: string;
  mimeType: string;
  wrapperId: string
  state: {
    text: string;
    color: string;
  };
};

const NFTView: React.FC<props> = ({
  left,
  title,
  price,
  description,
  uri,
  link,
  state,
  mimeType,
  wrapperId
}) => {
  
  const purchase = () => {
    console.log(`link to ${link}`);
  };

  return (
    <Wrapper id={wrapperId} data-scroll-section>
      <Left $left={left}>
        <Auction color={state.color}>
          <Dot color={state.color} />
          {state.text}
        </Auction>
        <Title>{title}</Title>
        <Price>{price}</Price>
        <Body>{description}</Body>
        <ButtonWrapper>
          <PrimaryButton
            onClick={purchase}
          >
            Purchase
          </PrimaryButton>
        </ButtonWrapper>
      </Left>
      <Right $left={left}>
        <NFTPlayer eventId={wrapperId} uri={uri} mimeType={mimeType} />
      </Right>
    </Wrapper>
  );
};

export default NFTView;

const Wrapper = styled.section`
  display: flex;

  height: 54.722vw;

  ${media.tablet} {
    height: 64.844vw;
  }

  ${media.mobile} {
    height: 304.533vw;
    flex-direction: column;
  }
`;

const Left = styled.div<{ $left: boolean }>`
  order: ${(props) => (props.$left ? 2 : 1)};

  width: 50.6vw;
  padding-top: 10.6vw;
  padding-left: 12.7vw;
  padding-right: 8.9vw;

  ${media.tablet} {
    width: 50.8vw;
    padding-top: 7.4vw;
    padding-left: 4.9vw;
    padding-right: 5.9vw;
  }

  ${media.mobile} {
    order: 2;
    width: 100vw;
    display: flex;
    flex-direction: column;
    padding-left: 6.667vw;
    padding-right: 6.667vw;
    padding-top: 13.333vw;
    padding-bottom: 26.667vw;
  }
`;

const Right = styled.div<{ $left: boolean }>`
  order: ${(props) => (props.$left ? 1 : 2)};
  height: 100%;

  width: 49.444vw;
  padding-top: 4.375vw;
  padding-bottom: 4.375vw;
  padding-right: 3.472vw;

  ${media.tablet} {
    width: 49.219vw;
    padding-top: 7.813vw;
    padding-bottom: 7.813vw;
    padding-right: 0vw;
  }

  ${media.mobile} {
    order: 1;
    width: 100vw;
    height: 100vw;
    padding-top: 0vw;
    padding-right: 0vw;
    padding-left: 0vw;
    padding-bottom: 0vw;
  }
`;

const Auction = styled.span<{ color: string }>`
  display: flex;
  align-items: center;
  font-family: "proxima-nova";
  font-style: normal;
  font-weight: 500;
  line-height: 90%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${(props) => props.color};

  margin-bottom: 2.083vw;
  font-size: 1.042vw;

  ${media.tablet} {
    margin-bottom: 2.93vw;
    font-size: 1.465vw;
  }

  ${media.mobile} {
    font-size: 4vw;
    margin-bottom: 5.333vw;
    order: 1;
  }
`;

const Dot = styled(DotSVG)<{ color: string }>`
  height: auto;

  margin-right: 0.694vw;
  width: 0.694vw;

  ${media.tablet} {
    width: 0.977vw;
    margin-right: 0.977vw;
  }

  ${media.mobile} {
    width: 2.667vw;
    margin-right: 2.667vw;
  }

  circle {
    fill: ${(props) => props.color};
  }
`;

const Title = styled.h2`
  color: ${colors.offWhite};

  ${text.desktop.headerH3}
  margin-bottom: 0.694vw;

  ${media.tablet} {
    ${text.tablet.headerH3}
    margin-bottom: 0.977vw;
  }

  ${media.mobile} {
    ${text.mobile.headerH3}
    margin-bottom: 2.667vw;
    order: 2;
  }
`;

const Price = styled.p`
  color: ${colors.splash100};

  ${text.desktop.priceText};
  margin-bottom: 2.083vw;

  ${media.tablet} {
    ${text.tablet.priceText}
    margin-bottom: 2.93vw;
  }

  ${media.mobile} {
    ${text.mobile.priceText}
    margin-bottom: 10.667vw;
    order: 3;
  }
`;

const Body = styled.p`
  color: ${colors.offWhite};

  ${text.desktop.headerBodyCopy}
  margin-bottom: 4.167vw;

  ${media.tablet} {
    ${text.tablet.headerBodyCopy}
    margin-bottom: 5.859vw;
  }

  ${media.mobile} {
    ${text.mobile.headerBodyCopy}
    margin-bottom: 0vw;
    order: 5;
  }
`;

const ButtonWrapper = styled.div`
  ${media.mobile} {
    order: 4;
    margin-bottom: 10.667vw;
  }
`;
