import { useCallback, useEffect, useRef } from "react"
import { useAnimationFrame } from 'utils/Hooks'
import styled, { keyframes } from "styled-components"
import gsap from 'gsap'

import Colors from "styles/colors"
import text from "styles/text"
import animations from "styles/animations"

import {ReactComponent as SpinnerSVG} from 'assets/svg/aboutCampaignSpinner.svg'

import whiteDiamonds from 'assets/svg/whiteDiamonds.svg'
import redDot from 'assets/svg/redDot.svg'
import blueTriangle from 'assets/svg/blueTriangle.svg'

import bitskiBlue from "assets/svg/bitskiLogomarkBlue.svg"
import bitskiRed from "assets/svg/bitskiLogomarkRed.svg"
import bitskiGray from "assets/svg/bitskiLogomarkGray.svg"
import chatIconBlue from "assets/svg/chatIconBlue.svg"
import chatIconGray from "assets/svg/chatIconGray.svg"
import heartIcon from "assets/svg/heartIconRed.svg"
import shareIcon from "assets/svg/shareIconGray.svg"
import Media from "styles/media"

const PhoneZoomContent: React.FC<{}> = () => {

  const wrapper = useRef<HTMLDivElement>(null)
  const spinner = useRef(null)

  const time = useRef(0)

  const handleMouseMove = (e: any) => {
    let normalizedX = -e.clientX / window.innerWidth + 0.5
    let normalizedY = -e.clientY / window.innerHeight + 0.5
    gsap.to('.about_parallax5',  { x: normalizedX * 5,  y: normalizedY * 5  })
    gsap.to('.about_parallax10', { x: normalizedX * 10, y: normalizedY * 10 })
    gsap.to('.about_parallax15', { x: normalizedX * 15, y: normalizedY * 15 })
  }

  useEffect(() => {
    let w = wrapper.current
    if (w) w.addEventListener('mousemove', e => handleMouseMove(e))
    return () => {
      if (w) w.removeEventListener('mousemove', e => handleMouseMove(e))
    }
  }, [])

  const animationCallback = useCallback(() => {
    if (spinner.current && spinner.current.parentElement.className.includes('in-view')) {
      time.current += 0.5 + Math.abs(window.speed)
      time.current %= 360
      if (spinner.current) {
        spinner.current.children[0].style.transform = `rotate(${time.current}deg)`
        spinner.current.children[1].style.transform = `rotate(-${time.current}deg)`
      }
    }
  }, [spinner])

  useAnimationFrame(animationCallback)

  const tickerContent = () => <>
    <BlackSquare />
    <TickerText>More than 1 billion videos viewed last year</TickerText>
    <RedDot src={redDot}/>
    <TickerText>689 million monthly active users</TickerText>
    <WhiteDiamonds src={whiteDiamonds} />
    <TickerText>2 billion downloads</TickerText>
    <BlueTriangle src={blueTriangle}/>
    <TickerText>Available in 155 countries</TickerText>
  </>

  return <Wrapper ref={wrapper}>
    <StickyWrapper id="phoneZoomContent">
      <Ticker data-scroll data-scroll-sticky data-scroll-target="#phoneZoomContent" data-scroll-offset="0 98vh" data-scroll-repeat>
        <TickerContent>
          {tickerContent()}
          {tickerContent()}
          {tickerContent()}
        </TickerContent>
      </Ticker>
      <Hero data-scroll data-scroll-class="in-view" data-scroll-offset="50%">
        <HeroIcons>
          <ShareBig src={shareIcon} className="about_parallax10"/>
          <HeartMed src={heartIcon} className="about_parallax15"/>
          <ChatSmall src={chatIconGray} className="about_parallax10"/>
          <BitskiRed src={bitskiRed} className="about_parallax5"/>
        </HeroIcons>
        <SpinnerContainer data-scroll data-scroll-class="in-view" data-scroll-offset="0" data-scroll-repeat>
          <Spinner ref={spinner}/>
        </SpinnerContainer>
        <SmallHeading>TikTok doesn't know sh*t about NFTs.</SmallHeading>
        <Heading>But we do know about creators.</Heading>
      </Hero>
      <Sections>
        <SectionIcons>
          <BitskiBlueOne src={bitskiBlue} className="about_parallax15"/>
          <ShareSmall src={shareIcon}  className="about_parallax10"/>
          <HeartBig src={heartIcon}  className="about_parallax5"/>
          <BitskiBlueTwo src={bitskiBlue}  className="about_parallax10"/>
          <ShareMed src={shareIcon} className="about_parallax5" />
          <ChatBig src={chatIconBlue}  className="about_parallax5"/>
          <HeartSmall src={heartIcon}  className="about_parallax10"/>
          <BitskiGray src={bitskiGray}  className="about_parallax15"/>
        </SectionIcons>
        <SectionOne data-scroll data-scroll-class="in-view" data-scroll-offset="40%">
          <Number data-scroll data-scroll-speed="-1">01</Number>
          <Subheading>TikToks cultural impact</Subheading>
          <Text>TikTok is defined by the creators that use our platform as a cultural incubator. We captured their top moments and paired them with NFT artists to bring you a once in a lifetime opportunity: to own a piece of pop culture that broke the internet, and define what the future of creator independence looks like.</Text>
        </SectionOne>
        <SectionTwo data-scroll data-scroll-class="in-view" data-scroll-offset="40%">
          <Number data-scroll data-scroll-speed="-1">02</Number>
          <Subheading>Why we selected these videos</Subheading>
          <Text>TikTok selected a group of culturally-defining creators that have captivated TikTok communities in entertaining and viral moments. To celebrate these historic pieces of culture, we will sell six of their iconic TikTok videos on the blockchain. </Text>
        </SectionTwo>
        <SectionThree data-scroll data-scroll-class="in-view" data-scroll-offset="40%">
          <Number data-scroll data-scroll-speed="-1">03</Number>
          <Subheading>What's an NFT?<br/>Why do they matter?</Subheading>
          <Text>Non-fungible tokens (NFTs) have emerged as a new and exciting way to help creators sell their digital work.</Text>
          <List>
            <ListItem>NFTs (non-fungible tokens) are a way of proving ownership of unique goods such as digital art, music, in-game gear and more.</ListItem>
            <ListItem>NFTs act as digital certificates to help people prove that they own these items on a universal record-keeping system known as the blockchain.</ListItem>
          </List>
          <Text>The ability for anyone to view these records and inability to edit the history of transactions enables ownership of virtual things that previously were not “ownable.” As a result, NFTs allow creators to sell their digital work such as video creations, virtual sneakers and more, which empowers a whole new digital economy. This is how the metaverse begins!</Text>
        </SectionThree>
      </Sections>
    </StickyWrapper>
  </Wrapper>
}

const Wrapper = styled.div`
  padding: 100vw 0 185vw;
  position: relative;
  ${Media.tablet} {
    padding-bottom: 250vw;
  }
  ${Media.mobile} {
    padding: 200vw 0 450vw;
  }
`

const StickyWrapper = styled.div``

// TICKER STYLES
const ticker = keyframes`
  from { transform: none }
  to { transform: translateX(-33%)}
`

const Ticker = styled.div`
  position: relative;
  top: -2px;
  z-index: 20;
  opacity: 0;
  transition: opacity 0.5s ease;
  &.is-inview {
    opacity: 1
  }
`

const TickerContent = styled.div`
  width: 173.3vw;
  background: #1A1A1A;
  display: flex;
  align-items: center;
  padding: 0.7vw 0;
  animation: ${ticker} 10s linear infinite;
  ${Media.tablet} {
    width: max-content;
  }
  ${Media.mobile} {
    width: max-content;
  }
`

const TickerText = styled.p`
  ${text.desktop.tickerText}
  line-height: 1;
  color: white;
  margin: 0.2vw 1.4vw 0 0.7vw;
  ${Media.tablet} {
    ${text.tablet.tickerText}
    margin: 0.2vw 2vw 0 1vw;
  }
  ${Media.mobile} {
    ${text.mobile.tickerText}
    margin: 2.7vw 5.4vw 2.7vw 2.7vw;
  }
`

const BlackSquare = styled.div`
  width: 1vw;
  height: 1vw;
  background: ${Colors.richBlack100};
  border: 1px solid ${Colors.offWhite};
  ${Media.mobile} {
    height: 4vw;
    width: 4vw;
  }
`

const RedDot = styled.img`
  width: 1vw;
  height: auto;
  ${Media.mobile} {
    width: 4vw;
  }
`

const BlueTriangle = styled.img`
  width: 1vw;
  height: auto;
  ${Media.mobile} {
    width: 4vw;
  }
`

const WhiteDiamonds = styled.img`
  width: 3vw;
  height: auto;
  ${Media.mobile} {
    width: 12vw;
  }
`
// END TICKER STYLES

const Hero = styled.div`
  padding: 9vw 6.9vw 13.2vw;
  position: relative;
  pointer-events: none;
  &.in-view h1 {
    opacity: 1;
    transform: none;
  }
  ${Media.tablet} {
    padding: 8.4vw 4.9vw 6.5vw;
  }
  ${Media.mobile} {
    padding: 20vw 6.7vw 40vw;
  }
`

const Icon = styled.img`
  position: absolute;
  height: auto;
`

const HeroIcons = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

// ICON STYLES
const ShareBig = styled(Icon)`
  width: 5.5vw;
  top: 12vw;
  left: 55vw;
  transform: rotate(-30deg);
  ${Media.tablet} {
    width: 4.7vw;
    top: 7vw;
    left: 72.7vw
  }
  ${Media.mobile} {
    width: 8.5vw;
    top: 45vw;
    left: 86vw;
  }
`

const HeartMed = styled(Icon)`
  width: 5.5vw;
  top: 45vw;
  left: 14vw;
  transform: rotate(-27deg);
  ${Media.tablet} {
    width: 7.8vw;
    top: 63vw;
    left: 10.4vw;
  }
  ${Media.mobile} {
    width: 6.7vw;
    top: 120.5vw;
    left: 15.5vw;
  }
`

const ChatSmall = styled(Icon)`
  width: 3.6vw;
  bottom: 11.2vw;
  right: 34.6vw;
  transform: rotate(18deg);
  ${Media.tablet} {
    width: 5.1vw;
    bottom: -11.2vw;
    right: 9.4vw;
  }
  ${Media.mobile} {
    width: 6.7vw;
    bottom: initial;
    top: 325vw;
    left: 12vw;
  }
`

const BitskiRed = styled(Icon)`
  width: 3.8vw;
  bottom: 5.1vw;
  right: 7.7vw;
  transform: rotate(69deg);
  ${Media.tablet} {
    width: 5vw;
    bottom: 3.1vw;
    right: -2.1vw;
  }
  ${Media.mobile} {
    width: 8.8vw;
    top: 278.9vw;
    right: 9.9vw;
  }
`

const BitskiBlueOne = styled(Icon)`
  width: 3.5vw;
  top: 12.5vw;
  left: 9.6vw;
  transform: rotate(39deg);
  ${Media.tablet} {
    display: none;
  }
  ${Media.mobile} {
    width: 13.6vw;
    top: 2vw;
    left: initial;
    right: 9.1vw;
  }
`

const ShareSmall = styled(Icon)`
  width: 3.6vw;
  top: 17.8vw;
  left: 37.1vw;
  transform: rotate(-38deg);
  ${Media.tablet} {
    width: 5vw;
    top: 31.1vw;
    left: 16.3vw;
  }
  ${Media.mobile} {
    display: none;
  }
`

const HeartBig = styled(Icon)`
  width: 7.7vw;
  top: 24.7vw;
  right: -3.7vw;
  transform: rotate(-45deg);
  ${Media.tablet} {
    width: 10.8vw;
    top: 60.4vw;
    right: -6vw;
  }
  ${Media.mobile} {
    width: 9.3vw;
    top: 375vw;
    right: 0;
  }
`

const BitskiBlueTwo = styled(Icon)`
  width: 3.5vw;
  top: 44.3vw;
  left: 53vw;
  transform: rotate(-90deg);
  ${Media.tablet} {
    width: 5vw;
    top: 70.2vw;
    left: 49.3vw;
  }
  ${Media.mobile} {
    width: 13.6vw;
    top: 450vw;
    left: 91vw;
  }
`

const ShareMed = styled(Icon)`
  display: none;
  transform: rotate(38deg);
  ${Media.tablet} {
    display: initial;
    width: 7.8vw;
    top: 109.6vw;
    right: 10.4vw;
  }
  ${Media.mobile} {
    display: initial;
    width: 13.9vw;
    top: 335vw;
    right: 81.5vw;
    transform: rotate(-38deg);
  }
`

const ChatBig = styled(Icon)`
  width: 7.7vw;
  top: 54.8vw;
  left: -3.3vw;
  transform: rotate(40deg);
  ${Media.tablet} {
    width: 10.8vw;
    top: 132vw;
    left: -6vw;
  }
  ${Media.mobile} {
    width: 11.7vw;
    top: 255vw;
    left: 89.3vw;
  }
`

const HeartSmall = styled(Icon)`
  width: 3.6vw;
  top: 70.5vw;
  left: 19.9vw;
  transform: rotate(33deg);
  ${Media.tablet} {
    width: 5.1vw;
    top: 180vw;
    left: 17.2vw;
  }
  ${Media.mobile} {
    width: 13.9vw;
    top: 550vw;
    left: -5vw;
  }
`

const BitskiGray = styled(Icon)`
  width: 3.3vw;
  bottom: 17.8vw;
  right: 3.5vw;
  transform: rotate(70deg);
  ${Media.tablet} {
    width: 4.6vw;
    top: 189vw;
    right: 0vw;
  }
  ${Media.mobile} {
    width: 12.5vw;
    bottom: -15vw;
    right: -3.5vw;
  }
`
// END ICON STYLES

const SmallHeading = styled.h1`
  ${text.desktop.displayH2}
  color: white;
  width: 46vw;
  opacity: 0;
  transform: translateY(100%);
  transition: all 0.8s ease;
  margin-bottom: 4.2vw;
  ${Media.tablet} {
    ${text.tablet.displayH2}
    width: 65vw;
  }
  ${Media.mobile} {
    ${text.mobile.displayH3}
    font-size: 7.5vw;
    width: 100%;
  }
`

const Heading = styled.h1`
  ${text.desktop.displayH1}
  width: 56.5vw;
  color: white;
  position: relative;
  margin-bottom: 0.5vw;
  opacity: 0;
  transform: translateY(100%);
  transition: all 0.8s ease;
  transition-delay: 0.1s;
  ${Media.tablet} {
    ${text.tablet.displayH1}
    width: 80vw;
  }
  ${Media.mobile} {
    ${text.mobile.displayH2}
    width: 100%;
    padding-right: 5vw;
  }
`

const SpinnerContainer = styled.div`
  width: 30.8vw;
  height: auto;
  position: absolute;
  top: 11.9vw;
  right: 6.2vw;
  ${Media.tablet} {
    width: 33.8vw;
    right: -6.7vw;
  }
  ${Media.mobile} {
    display: none;
  }
`

const Spinner = styled(SpinnerSVG)`
  width: 100%;
  height: auto;
  path {
    transform-origin: center;
    &:last-of-type { animation: ${animations.nudgeDown} 5s linear infinite; }
  }
`

const Sections = styled.div`
  position: relative;
  pointer-events: none;
  height: 100vw;
  ${Media.tablet} {
    height: 215vw;
  }
  ${Media.mobile} {
    height: auto;
    padding: 8vw 6.7vw 6.7vw;
  }
`

const SectionIcons = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const Number = styled.h1`
  position: absolute;
  top: 0;
  left: 0;
  font: 800 20.8vw/100% 'Proxima Nova', sans-serif;
  background: linear-gradient(180deg, #FE2C55 12.14%, rgba(37, 244, 238, 0.35) 57.24%, #000000 75.69%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-stroke: 2px transparent;
  color: ${Colors.richBlack100};
  ${Media.tablet} {
    font: 800 29.3vw/100% 'Proxima Nova', sans-serif;
  }
  ${Media.mobile} {
    font: 800 53.3vw/100% 'Proxima Nova', sans-serif
  }
`

const Subheading = styled.h1`
  ${text.desktop.headerH2}
  color: white;
  position: relative;
  text-transform: none;
  margin-bottom: 2vw;
  opacity: 0;
  transform: translateY(100%);
  transition: all 0.8s ease;
  ${Media.tablet} {
    ${text.tablet.headerH2}
  }
  ${Media.mobile} {
    ${text.mobile.headerH3}
  }
`

const Text = styled.p`
  ${text.desktop.headerBodyCopy}
  color: white;
  position: relative;
  width: 28.1vw;
  opacity: 0;
  transform: translateY(100%);
  transition: all 0.8s ease;
  transition-delay: 0.1s;
  ${Media.tablet} {
    ${text.tablet.headerBodyCopy}
  }
  ${Media.mobile} {
    ${text.mobile.headerBodyCopy}
    margin-left: 12vw;
  }
`

const List = styled.ul`
  padding-left: 2vw;
  width: 42vw;
  margin: 1.5vw 0;
  ${Media.mobile} {
    padding-left: 18.7vw;
    width: 100%;
  }
`

const ListItem = styled.li`
  ${text.desktop.headerBodyCopy}
  color: white;
  font-weight: 600;
  opacity: 0;
  transform: translateY(100%);
  transition: all 0.8s ease;
  transition-delay: 0.1s;
  &:first-of-type {
    margin-bottom: 1.5vw;
  }
  ${Media.tablet} {
    ${text.tablet.headerBodyCopy}
  }
  ${Media.mobile} {
    ${text.mobile.headerBodyCopy}
  }
`

const Section = styled.div`
  position: absolute;
  &.in-view {
    ${Subheading}, ${Text}, ${ListItem} {
      opacity: 1;
      transform: none;
    }
  }
  ${Media.mobile} {
    position: relative;
  }
`

const SectionOne = styled(Section)`
  width: 41.9vw;
  top: 1.5vw;
  right: 7.5vw;
  padding-top: 11vw;
  padding-left: 8vw;
  ${Text} {
    width: 27.5vw;
  }
  ${Media.tablet} {
    width: 59vw;
    padding-top: 15.6vw;
    padding-left: 11.4vw;
    top: 6.3vw;
    right: 5vw;
    ${Text} {
      width: 39.6vw;
    }
  }
  ${Media.mobile} {
    top: 0;
    right: 0;
    width: 100%;
    margin-bottom: 70vw;
    padding: 27.7vw 0 0;
    ${Text} {
      width: auto;
    }
  }
`

const SectionTwo = styled(Section)`
  width: 47vw;
  top: 23.3vw;
  left: 0;
  padding-top: 12vw;
  padding-left: 8vw;
  ${Media.tablet} {
    width: 66.7vw;
    padding-top: 16.7vw;
    padding-left: 11.4vw;
    top: 73.2vw;
    left: 5vw;
    ${Text} {
      width: 41vw;
    }
  }
  ${Media.mobile} {
    top: 0;
    right: 0;
    width: 100%;
    margin-bottom: 70vw;
    padding: 27.7vw 0 0;
    ${Text} {
      width: auto;
    }
  }
`

const SectionThree = styled(Section)`
  width: 44vw;
  top: 48vw;
  right: 5vw;
  padding-top: 12vw;
  ${Text} {
    width: 42vw;
  }
  ${Number} {
    left: 12.5vw;
  }
  ${Media.tablet} {
    width: 62.2vw;
    padding-top: 16.9vw;
    top: 136.1vw;
    ${Text}, ${ListItem} {
      width: 61vw;
    }
  }
  ${Media.mobile} {
    top: 0;
    right: 0;
    width: 100%;
    padding: 27.7vw 0 0;
    ${Text}, ${ListItem} {
      width: auto;
    }
    ${Number} {
      left: 0;
    }
  }
`

export default PhoneZoomContent
