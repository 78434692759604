import React, { useEffect } from "react";
import styled from "styled-components";
import gsap from "gsap";

type Props = {
  textArray: any;
  trigger: boolean;
  height: string;
  className: string;
  centered?: boolean;
  justifyContent?: string;
  textAlign?: string;
};

const TextAnimation: React.FC<Props> = ({
  textArray,
  trigger,
  height,
  className,
  justifyContent,
  textAlign,
}) => {
  let lines = textArray.map((Item: any, index: number) => {
    return (
      <Span className={className + "-parent"} key={index} $height={height}>
        <InnerSpan
          $justifyContent={justifyContent}
          $textAlign={textAlign}
          className={className}
        >
          <Item />
        </InnerSpan>
      </Span>
    );
  });

  useEffect(() => {
    if (trigger) {
      let els = Array.from(document.getElementsByClassName(className));

      gsap.to(els, {
        duration: 0.7,
        ease: "circ.inOut",
        top: 0,
        onComplete: () => {
          gsap.set(`.${className}-parent`, {
            overflow: "unset",
          });
        },
      });
    }
  }, [trigger, className]);

  return <>{lines}</>;
};

export default TextAnimation;

const Span = styled.span<{ $height: string }>`
  width: 100%;
  position: relative;
  overflow: hidden;
  height: ${(props) => props.$height};
`;

const InnerSpan = styled.span<{
  $justifyContent?: string;
  $textAlign?: string;
}>`
  width: 100%;
  position: absolute;
  top: 100%;

  display: flex;
  flex-direction: column;
  text-align: ${(props) => props.$textAlign || "unset"};
  justify-content: ${(props) => props.$justifyContent || "flex-start"};
`;
