import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import gsap from 'gsap'

import media from 'styles/media'

import {ReactComponent as DropIcon} from 'assets/svg/reformIcon.svg'
import {ReactComponent as OuterSVG} from 'assets/svg/reformOuter.svg'

const Cursor: React.FC<{id: string}> = ({id}) => {

  const textRef = useRef(null)

  useEffect(() => {
    const tl = gsap.timeline({
      repeat: -1
    })

    tl.fromTo(textRef.current, {
      rotate: 0
    }, {
      duration: 10,
      rotate: 360,
      ease: "none"
    })
  }, [])

  return (
    <Div id={id}>
      <Outer ref={textRef}/>
      <Inner/>
    </Div>
  )
}

export default Cursor

const Div = styled.div`
  position: absolute;
  z-index: 9;
  pointer-events: none;
  cursor: none;
  opacity: 0;

  ${media.desktop} {
    padding: 1vw;
  }
`

const Inner = styled(DropIcon)`
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  height: auto;

  ${media.desktop} {
    width: 3vw;
  }
`

const Outer = styled(OuterSVG)`
  transform-origin: center center;
  transform-box: fill-box;


  ${media.desktop} {
    width: 8.333vw;
    height: 8.333vw;
  }
`
