import React from 'react'
import styled from 'styled-components'

import Banner from 'components/Banner'

type props = {
  left: boolean,
  text: string
}

const BannerSection: React.FC<props> = ({left, text}) => {
  return (
    <Wrapper data-scroll-section data-scroll>
      <Banner left={left}>
        {text}&nbsp;{'//'}&nbsp;{text}&nbsp;{'//'}&nbsp;
      </Banner>
    </Wrapper>
  )
}

export default BannerSection

const Wrapper = styled.section`

`
